import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Club } from './clubs.repository';
import { Contact } from './contacts.repository';
import { ContractStatus } from './contractstatuses.repository';
import { Country } from './countries.repository';
import { Match } from './matches.repository';
import { PlayerLevel } from './playerlevels.repository';
import { Position } from './positions.repository';
import { Transfer } from './transfers.repository';
import { Valuta } from './valutas.repository';
import { Store, createState } from '@ngneat/elf';
import { createRequestsCacheOperator, createRequestsStatusOperator,  selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from '@ngneat/elf-requests';
import { selectAllEntities, upsertEntities, setEntities, withEntities } from '@ngneat/elf-entities';

export interface Requirement {
    id: string;
    createdAt: Date;
    updatedAt: Date;    
    clubId: string;
    club: Club;
    tenantId: string;
    transferId: string;
    transfer: Transfer;
    contactId: string;
    contact: Contact;
    maxNetto: number;
    maxBrutto: number;
    valutaId: string;
    valuta: Valuta;
    maxTransferSum: number;
    isAdvancedSearch: boolean;
    minAge: number;
    maxAge: number;
    maritalStatus: string;
    countryId: string;
    country: Country;
    rightyLefty: string;
    pinsPriority: string;
    contractType: string;
    minHeight: number;
    maxHeight: number;
    minWeight: number;
    maxWeight: number;
    matches: Match[];
    positionRequirements: Position[];
    positionRequirementIds: string[];
    playerLevelRequirements: PlayerLevel[];
    playerLevelRequirementIds: string[];
    contractStatusRequirements: ContractStatus[];
    contractStatusRequirementIds: string[];
    description: string;
}

export interface RequirementsFilter {
  transferId: string | null;
  clubIds: string[] | null;
  positionIds: string[] | null;
  playerLevelIds: string[] | null;
  contractStatusIds: string[] | null;
  countryIds: string[] | null;
  tenantId: string | null;
  sort: string | null;
  direction: string | null;
  page: number | null;
  take: number | null;
}

export const requirementsSortOptions: SortOption[] = [  
  { label: $localize `:Sort label Name:Created At`, property: 'createdAt' },  
  { label: $localize `:Sort label Name:Club`, property: 'club' }
];

const { state, config } = createState(
  withEntities<Requirement>(),
  withRequestsStatus(),
  withRequestsCache()
);
const store = new Store({ name: 'requirements', state, config });
export const trackReqRequestsStatus = createRequestsStatusOperator(store);
export const skipWhileReqsCached = createRequestsCacheOperator(store);

@Injectable({ providedIn: 'root' })
export class RequirementsRepository extends BaseRepository<Requirement> {

  constructor() {
    super('requirements', requirementsSortOptions);
  }

  setReqs(posts: Requirement[]) {
    store.update(
      updateRequestCache(store.name),
      setEntities(posts),
      updateRequestsStatus([store.name], 'success')
    );
  }
  reqs$ = store.pipe(selectAllEntities());

  

}
