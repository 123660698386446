<!--<h2 class="mb-4 text-primary" *ngIf="id === 'new'" i18n>Add sport</h2>-->
<h2 class="mb-4 text-primary" *ngIf="id !== 'new'" i18n>Edit sport</h2>
<div *ngIf="id === 'new' || (tenant$ | async)" class="row mb-4">
    <div class="col-12">
        <app-tenant-form *ngIf="!(featureRepo.isLoading$ | async)"
         [editedTenant]="tenant$ | async" 
         [features]="featureRepo.all$ | async"
         (tenantSubmit)="createTenant($event)"></app-tenant-form>
        <h2 *ngIf="featureRepo.isLoading$ | async"><app-spinner></app-spinner></h2>
        <app-error-alert title="Could not submit the sport profile" i18n-title
         [errors]="submitErrors"></app-error-alert>       
    </div>
</div>
