import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Post } from 'src/app/state/posts.repository';

declare var ClassicEditor: any;

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit, AfterViewInit {
  editForm: FormBuilder | any;
  editor: any;

  @Input() editedPost: Partial<Post> | null = null;
  @Output() newsSubmit = new EventEmitter<Partial<Post>>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.editForm = this.formBuilder.group({
      title: [this.editedPost?.title, Validators.required],
      editor: this.editedPost?.content
    });
  }

  ngAfterViewInit() {
    if (typeof(ClassicEditor) !== 'function') {
      var script = this.document.createElement("script");
      script.type = "text/javascript";
      script.src = "/assets/js/ckeditor.js";
      this.document.body.appendChild(script);
      script.onload = () => this.initEditor();
    } else {
      this.initEditor();
    }
  }

  private initEditor() {
    ClassicEditor
      .create(document.querySelector('#editor'), {
        toolbar: ["heading", "bold", "italic", '|', "undo", "redo", '|', 'numberedList', 'bulletedList']
      })
      .then((newEditor: any) => {
        if(this.editedPost?.content){
          newEditor.setData(this.editedPost?.content);
        }
        this.editor = newEditor;
      });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const title = this.editForm.value?.title.toString();
    const content = this.editor.getData();
    this.newsSubmit.emit( { title, content } );
  }
}
