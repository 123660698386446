import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { Tenant, TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { transferSortOptions, TransfersRepository } from 'src/app/state/transfers.repository';
import { TransfersService } from 'src/app/state/transfers.service';

@Component({
  selector: 'app-transfers-page',
  templateUrl: './transfers-page.component.html',
  styleUrls: ['./transfers-page.component.scss']
})
export class TransfersPageComponent implements OnInit {
  tenants = new Array<Tenant>();
  tenantIds: string[] | null = null;
  sortOptions = transferSortOptions;
  exportFactory = () => this.service.export();
  //importFactory = (file: File) => this.service.import(file);
  id: string | null = null;

  constructor(
    public service: TransfersService,
    public repo: TransfersRepository,    
    public authrepo: AuthRepository,
    public tenantRepo: TenantsRepository,
    private tenantService: TenantsService,
    private activatedRoute: ActivatedRoute
  ) { 
    this.getTenants();
  }

  ngOnInit(): void {
    this.getTenants();
    this.activatedRoute.paramMap.subscribe({ 
      next: (value) => {        
        this.id = value.get('tenant?');
        this.service.reloadPage(this.id!).subscribe();
      }    
    });    
  }

  getTenants() {
    this.tenantIds = this.authrepo.getTenantIds();
    if(this.tenantIds && this.tenantIds?.length > 0) {      
      this.tenantIds.forEach(x => {
        this.tenantService.loadOne((x)).subscribe(y => this.tenants.push(y));
      });      
    }
  }

}
