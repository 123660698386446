import { Component, OnInit } from '@angular/core';
import {
  Click,
  PublicPlayer,
  UnauthorisedRepository,
} from 'src/app/state/unauthorised.repository';
import { UnauthorisedService } from 'src/app/state/unauthorised.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvState } from '../../../shared/helpers/env-state';
import { VideoInfo } from '../../../../state/contacts.repository';

@Component({
  selector: 'app-handballman-player-detail',
  templateUrl: './handballman-player-detail.component.html',
  styleUrls: ['./handballman-player-detail.component.scss'],
})
export class HandballmanPlayerDetailComponent implements OnInit {
  contact_id: string | null = null;
  player_id: string | null = null;
  player: PublicPlayer | null = null;

  defensive: string[] | undefined;
  offensive: string[] | undefined;
  icon: string = 'bi bi-person-workspace';
  teaserUrls: VideoInfo[] = [];
  fullMatchUrls: VideoInfo[] = [];
  transferurls: string | undefined;
  tenant_id: string | null = null;

  EntityClick: Click | null = null;
  constructor(
    public service: UnauthorisedService,
    private router: Router,
    public repo: UnauthorisedRepository,
    private activatedRoute: ActivatedRoute,
    public env: EnvState
  ) {}

  checkPipesInStrings(attr: string | undefined | null) {
    if (attr) {
      return attr.split('||').join('');
    }
    return '-';
  }

  filter(pos: string[] | undefined) {
    var offensivefilter: string[] = [
      'Right wing',
      'Left wing',
      'Right back',
      'Left back',
      'Central back',
      'Line player',
      'Goalkeeper',
      'Coach',
      'No need',
    ];
    // [
    //   'Offensive midfield',
    //   'Centre-Forward',
    //   'Goalkeeper',
    //   'Winger',
    //   'Position 6/8',
    //   'Position 8/10',
    //   'Line player',
    // ];
    var defensivefilter: string[] = [
      'Defense wing',
      'Defense back',
      'Defense central',
      '5-1 defender',
      'Defense specialist',
    ];
    // [
    //   'Fullback, wingback Rigth',
    //   'Central defender',
    //   'Left back',
    //   'Defensive midfield',
    //   'Fullback, wingback Left',
    //   'Right back',
    //   'Defense wing',
    // ];
    this.offensive = pos?.filter((x) => offensivefilter.includes(x));
    this.defensive = pos?.filter((x) => defensivefilter.includes(x));
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.contact_id = value.get('contact_id');
        this.player_id = value.get('player_id');

        var ClientId = `${this.contact_id}`;
        var PlayerID = `${this.player_id}`;

        this.service.new_click({ ClientId, PlayerID }).subscribe();
        this.service.loadPlayer(`${this.player_id}`).subscribe((x) => {
          this.player = x;
          this.filter(this.player?.positions);
          this.player?.videoInfos.forEach((x) => {
            x.isFullMatch
              ? this.fullMatchUrls.push(this.videofilter(x))
              : this.teaserUrls.push(this.videofilter(x));
          });
          this.transferurls = this.transferfilter(this.player?.transferMarket);
          this.tenant_id = this.player?.tenantId;
        });
      },
    });
  }
  videofilter(router: VideoInfo) {
    if (
      router.videoUrl.replace(/\s/g, '').slice(0, 4) != 'http' &&
      router.videoUrl.replace(/\s/g, '').slice(0, 2) != '//' &&
      router.videoUrl.match(/^ *$/) == null
    ) {
      router.videoUrl = '//' + router.videoUrl;
    }
    return router;
  }
  transferfilter(router: string) {
    if (router != null && router.match(/^ *$/) == null) {
      if (router.slice(0, 4) != 'http' && router.slice(0, 2) != '//') {
        router = '//' + router;
      }
    }
    return router;
  }
  preview = () => this.service.preview(this.tenant_id!, this.player_id!);
}
