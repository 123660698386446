import { Component, Input, OnInit } from '@angular/core';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { Contact } from 'src/app/state/contacts.repository';
import { ContactsService } from 'src/app/state/contacts.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss']
})
export class ContactsListComponent implements OnInit {  
  @Input() entities: Contact[] | null = null;
  @Input() tenantId: string | null= null;
  deleteConfirmation: string | null = null;
  constructor(
    public env: EnvState,
    private service: ContactsService
  ) { }

  ngOnInit(): void {
  }
  handleDeleteClick(id: string) {
    this.service.delete(id, this.tenantId!).subscribe();
  }

}
