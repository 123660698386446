import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Tenant } from 'src/app/state/tenants.repository';
import { Transfer } from 'src/app/state/transfers.repository';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-transfer-create-form',
  templateUrl: './transfer-create-form.component.html',
  styleUrls: ['./transfer-create-form.component.scss'],
})
export class TransferCreateFormComponent implements OnInit {
  @Input() editedTransfer: Transfer | null = null;
  @Input() currentTenant?: string | null;
  currentTennantName?: string;
  form?: FormGroup;
  isDatesError: boolean = false;
  iYearsError: boolean = false;

  tenantOptions: DatalistOption[] | null = null;
  @Input() set tenants(value: Tenant[] | null) {
    if (!value) {
      this.tenantOptions = null;
    } else {
      this.tenantOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Output() transferSubmit = new EventEmitter<Partial<Transfer>>();

  constructor(private formBuilder: FormBuilder, public auth: AuthRepository) {}

  ngOnInit() {
    this.currentTennantName = this.tenantOptions?.filter(
      (x) => x.value === this.currentTenant
    )[0].label;

    const fromDate =
      this.editedTransfer && this.editedTransfer.fromDate
        ? formatDate(this.editedTransfer.fromDate, 'yyyy-MM-dd', 'en')
        : '';
    const toDate =
      this.editedTransfer && this.editedTransfer.toDate
        ? formatDate(this.editedTransfer.toDate, 'yyyy-MM-dd', 'en')
        : '';
    this.form = this.formBuilder.group({
      name: [this.editedTransfer?.name, Validators.required],
      sport: [this.editedTransfer?.tenantId],
      years: [
        this.editedTransfer?.years,
        [Validators.pattern(/(\d{4}-\d{4})/g), Validators.required],
      ],
      fromDate: [fromDate, Validators.required],
      toDate: [toDate, Validators.required],
    });

    this.checkIfSuperAdmin();

    if (!this.auth.isInRole(UserRoles.Superadmin)) {
      this.removeRequired(this.form?.controls['sport']);
    }
  }

  checkIfSuperAdmin() {
    if (!this.auth.isInRole(UserRoles.Superadmin)) {
      this.removeRequired(this.form?.controls['sport']);
    }
  }

  addRequired(control: AbstractControl | undefined) {
    control?.addValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  removeRequired(control: AbstractControl | undefined) {
    control?.removeValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  resetValidator() {
    this.isDatesError = false;
    this.iYearsError = false;
  }

  onSubmit() {
    this.isDatesError = false;
    this.iYearsError = false;
    if (!this.auth.isInRole(UserRoles.Superadmin)) {
      this.removeRequired(this.form?.controls['sport']);
    }
    if (
      this.form &&
      this.form.get('fromDate')?.value > this.form.get('toDate')?.value
    ) {
      this.isDatesError = true;
      return;
    }

    let yearsTest = this.form?.value?.years.toString();
    let splitedYears = yearsTest.split('-');
    if (Number(splitedYears[0]) > Number(splitedYears[1])) {
      this.iYearsError = true;
      return;
    }

    if (this.form && !this.form?.valid) {
      this.form.markAllAsTouched();
      return;
    }
    let name = this.form?.value?.name.toString();
    let tenantId = this.currentTenant!;
    let years = this.form?.value?.years.toString();
    let fromDate = this.form?.value?.fromDate;
    let toDate = this.form?.value?.toDate;

    this.transferSubmit.emit({ name, years, fromDate, toDate, tenantId });
    this.form?.markAsUntouched();
  }
}
