import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-avatar',
  templateUrl: './entity-avatar.component.html',
  styleUrls: ['./entity-avatar.component.scss']
})
export class EntityAvatarComponent implements OnInit {
  @Input() roles: string[] | undefined;
  @Input() alt: string | undefined;
  @Input() img: string | undefined;
  @Input() icon: string = 'bi-person';
  @Input() avatarSize: number = 50;

  constructor() { }

  ngOnInit(): void {
  }

}
