import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./abstract/base.service";
import { Team, TeamsRepository } from "./teams.repository";

const API = '/api/teams';

@Injectable({ providedIn: 'root' })
export class TeamsService extends BaseService<Team> {

  constructor(
    http: HttpClient,
    repo: TeamsRepository
  ) {
    super(API, http, repo);
  }
  
  search(name: string | undefined, id: string) {
    return this.http.get(API + `/search/${name}/${id}`)
  }
}
