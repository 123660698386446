import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { ConnectionService } from '../../shared/services/connection.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Request, RequestsRepository } from 'src/app/state/requests.repository';
import { EnvState } from '../../shared/helpers/env-state';

const RETRY_METHODS = [ 'POST', 'PATCH' ];

export class ScheduledForRetryError implements Error {
  name: string;
  message: string;
  stack?: string | undefined;

  constructor(public request: Request, public inner: Error) {
    this.name = 'ScheduledForRetryError';
    this.message = 'A request has failed in offline mode and will be syncronized once online.';
  }
}

export const RETRY_HEADER = "IsRetriedRequest";

@Injectable()
export class RetryInterceptor implements HttpInterceptor {

  constructor(
    private connection: ConnectionService,
    private auth: AuthRepository,
    private repo: RequestsRepository,
    private env: EnvState
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: Error) => {
        if (this.shouldRetry(request, error)) {
          // Handle offline request
          let url = request.urlWithParams;
          if (url.startsWith(this.env.apiUrl)) {
            url = url.substring(this.env.apiUrl.length);
          }
          const entity = this.repo.add({
            body: request.body,
            url,
            method: request.method.toUpperCase(),
            userId: this.auth.getId(),
            createdAt: new Date()
          });
          throw new ScheduledForRetryError(entity, error);
        }
        // return the error back to the caller
        throw error;
      })
    );
  }

  private shouldRetry(request: HttpRequest<any>, error: any) {
    return !this.connection.isOnline()
      && !request.headers.has(RETRY_HEADER)
      && error instanceof HttpErrorResponse
      && RETRY_METHODS.includes(request.method.toUpperCase());
  }
}
