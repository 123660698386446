export const environment = {
  production: true,
  name: "People in Sport",
  apiUrl: "/core",
  features: {
    news: false,
    sport: true,
    offlineChanges: false
  },
  locale: 'en-gb'
};
