import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Club } from './clubs.repository';
import { Country } from './countries.repository';
import { League } from './leagues.repository';
import { PlayerLevel } from './playerlevels.repository';
import { Position } from './positions.repository';
import { Team } from './teams.repository';
import { Tenant } from './tenants.repository';
import { Valuta } from './valutas.repository';

export interface Contact {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  tenantId: string;
  tenant: Tenant;
  createdAt: Date;
  updatedAt: Date;
  clubId: string;
  club: Club;
  accountName: string;
  jobTitle: string;
  whatsapp: string;
  dateOfBirth: Date;
  agent1: string;
  agent2: string;
  pinsPriority: string;
  nationality: string;
  maritalStatus: string;
  numberOfKids: string;
  includeInApp: string;
  contractType: string;
  role: string;
  twitter: string;
  instagram: string;
  facebook: string;
  tikTok: string;
  transferMarket: string;
  wyscout: string;
  eliteProspects: string;
  repContract: string;
  repContractStartDate: Date;
  repContractEndDate: Date;
  repContractNoticeOfTermination: string;
  repContractRemuneration: string;
  playerContract: string;
  playerContractStartDate: Date;
  playerContractEndDate: Date;
  playerContractRentalClause: boolean;
  playerContractRentalDescription: string;
  playerContractBuyOutActive: boolean;
  playerContractBuyOutActiveFromDate: Date;
  playerContractBuyOutAmount: string;
  playerContractBuyOutDescription: string;
  playerContractRelegationClause: boolean;
  playerContractRelegationDescription: string;
  playerContractOptionClause: boolean;
  playerContractDescription: string;
  otherContact: string;
  otherContractStartDate: Date;
  otherContractEndDate: Date;
  otherContractDescription: string;
  playerCVLink: string;
  playerLevelId: string;
  playerLevel: PlayerLevel;
  height: string;
  weight: string;
  rightyLefty: string;
  mandate: boolean;
  description: string;
  minSalaryNetto: string;
  minSalaryBrutto: string;
  valutaId: string;
  valuta: Valuta;
  estTransfer: number;
  positionContacts: Position[];
  positionContactIds: string[];
  countryPriorityContacts: Country[];
  countryPriorityContactIds: string[];
  countryExclusionContacts: Country[];
  countryExclusionContactIds: string[];
  leaguePriorityContacts: League[];
  leaguePriorityContactIds: string[];
  leagueExclusionContacts: League[];
  leagueExclusionContactIds: string[];
  teamPriorityContacts: Team[];
  teamPriorityContactIds: string[];
  teamExclusionContacts: Team[];
  teamExclusionContactIds: string[];
  picture: File | null;
  pictureUrl: string;
  videoInfos: VideoInfo[];
  contracts: Contract[];

  formerClub: string;
  internationalLevel: string;
  statistics: string;
  playerReferences: string;
}

export interface Contract {
  id: string;
  createdAt: Date;
  startDate: Date;
  endDate: Date;
  link: string;
  contractType: string;
  clubName: string;
  buyOut: string;
  buyOutDate: Date;
  buyOutComment: string;
  option: string;
  optionDate: Date;
  optionComment: string;
  relegationOrPromotion: string;
  relegationComment: string;
  termination: number;
  comment: string;
  contractPartner: string;
  type: string;
  contractName: string;
}

export interface VideoInfo {
  id: string;
  tenantId: string;
  contactId: string;
  createdAt: Date;
  updatedAt: Date;
  title: string;
  videoUrl: string;
  isPrimary: boolean;
  isFullMatch: boolean;
}

export const contactsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'firstName' },
  { label: $localize`:Sort label Name:Height`, property: 'height' },
  { label: $localize`:Sort label Name:Weight`, property: 'weight' },
  { label: $localize`:Sort label Name:DateOfBirth`, property: 'dateOfBirth' },
];

@Injectable({ providedIn: 'root' })
export class ContactsRepository extends BaseRepository<Contact> {
  constructor() {
    super('contacts', contactsSortOptions);
  }
}
