<app-spinner *ngIf="teamsRepo.isLoading$ | async"></app-spinner>
<app-club-edit-form *ngIf="!(teamsRepo.isLoading$ | async)"
                    [club$]="club$"
                    [teams]="teams" 
                    (clubSubmit)="updateClub($event)"></app-club-edit-form>
<app-error-alert title="Could not submit the club" i18n-title="Club submit error alert"
         [errors]="submitErrors"></app-error-alert>
<div  *ngIf="!(teamsRepo.isLoading$ | async) || !(repo.isLoading$ | async)"
    class="row text-white mb-1" style="background-color: #2F4A5D;" [style.background-image]="'url(' + (background || DEFAULT_BACKGROUND) + ')'">    
    <div *ngIf="club" class="row mt-4 ml-2">  
        <div class="col-12 col-md-6 col-lg-4">
            <label class="text-muted">Club</label>
            <h3 class="ml-3" name="club">{{club?.name}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <label class="text-muted">Logo</label>
            <h1 class="h3 m-2 p-0 text-center">
                <figure class="avatar avatar-100 rounded-circle shadow">
                    <img *ngIf="club?.clubLogoUrl" src="{{env.apiUrl + '/' + club?.clubLogoUrl}}">
                    <i *ngIf="!club?.clubLogoUrl" [ngClass]="['bi', icon]"></i>
                </figure>                                             
              </h1>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <label class="text-muted">Country</label>
            <h3 class="ml-3" name="club">{{club?.team?.league?.countryName}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Descriprion</label>
            <h3 class="ml-3" name="club">{{club?.description}}</h3>
        </div>        
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Active Campain</label>
            <h3 class="ml-3" name="club">{{club?.activeCampaignId}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Owner</label>
            <h3 class="ml-3" name="club">{{club?.owner}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">City</label>
            <h3 class="ml-3" name="club">{{club?.city}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">State</label>
            <h3 class="ml-3" name="club">{{club?.state}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Address1</label>
            <h3 class="ml-3" name="club">{{club?.address1}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Address2</label>
            <h3 class="ml-3" name="club">{{club?.address2}}</h3>
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Annual Revenue</label>
            <h3 class="ml-3" name="club">{{club?.annualRevenue}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Industry</label>
            <h3 class="ml-3" name="club">{{club?.industry}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">E-mail</label>
            <h3 class="ml-3" name="club">{{club?.email}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Fax</label>
            <h3 class="ml-3" name="club">{{club?.fax}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Twitter</label>
            <h3 class="ml-3" name="club">{{club?.twitter}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Instagram</label>
            <h3 class="ml-3" name="club">{{club?.instagram}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4 mb-2 mt-5">
            <label class="text-muted">Club url</label>
            <a routerLink="{{club?.url}}" class="text-normal"><h3 class="ml-3" name="club">{{club?.url}}</h3> </a>           
        </div>
    </div>
    <div class="row mb-2 mt-5 ml-2">
        <div class="col-12 col-md-6 col-lg-4">
            <label class="text-muted">Created At</label>
            <h3 class="ml-3" name="club">{{club?.createdAt | date:'longDate'}}</h3>            
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <label class="text-muted">Updated At</label>
            <h3 class="ml-3" name="club">{{club?.updatedAt | date:'longDate'}}</h3>            
        </div>
    </div>
    <div class="row mt-5 ml-2">
        <div class="col-12">
            <h3 i18n="Contacts">Contacts:</h3>
        </div>
    </div>
    <div class="row mb-4 mt-2 ml-2">
        <div class="col-12 col-md-6 col-lg-4" *ngFor="let contact of club?.contacts">                           
            <a routerLink="/contacts/{{contact?.tenantId}}/{{contact.id}}" class="text-normal"><h5>{{contact.firstName + " " + contact.lastName}}</h5><i class="bi bi-arrow-right-short text-primary"></i></a>
            <p><span class="text-muted">E-mail:</span> {{contact.email}}</p>
            <p><span class="text-muted">PhoneNumber:</span> {{contact.phoneNumber}}</p>
        </div>
    </div>
</div>
