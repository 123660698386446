<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <div class="row">
    <!--<div class="col-12 col-md-6 col-lg-4">
      <div [appValidateState]="form.get('name')" class="form-group form-floating mb-3">
        <input formControlName="name" type="text" class="form-control"
               placeholder="Name" i18n-placeholder="@@name"/>
        <label for="name" i18n="@@name">Name</label>
      </div>      
    </div>-->
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="image" type="file" class="form-control"
               placeholder="Picture" i18n-placeholder="@@picture"/>
        <label for="image" i18n="@@picture">Picture</label>
      </div>
    </div>
    <!--<div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select class="app-datalist-select-double-height"
                             [options]="featureOptions"
                             [multiple]="true"
                             [activeValue]="form?.value.featureIds"
                             (activeValueChange)="updateValue('featureIds', $event)"
                             label="Features" i18n-label="Features label"></app-datalist-select>
      </div>
    </div>-->
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="Save button @@save">Save</button>
      </div>
    </div>
  </div>
</form>
