<div>
  <label *ngIf="exportFactory"
          (click)="export()"
    style="cursor:pointer">
    {{titleOflabel}}
  </label>
  <a #importExportLink class="d-none"></a>
  <input #fileImport type="file" class="d-none"
         [attr.accept]="'.doc, .docx, .pdf'">
</div>
<div *ngIf="Errors">
  <div class="mt-3 alert alert-warning alert-dismissible fade show" role="alert">
    <strong>Error!</strong> File not exists
  </div>
</div>
