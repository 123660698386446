
<div class="card shadow-sm light-grey text-white w-100 h-75 me-2" role="button" (click)="getMail()">
    <div class="card-body">
      <h2 class="mb-3">
        <i  class="bi bi-box-arrow-up"></i>        
      </h2>
      <h4 class="mb-2 " i18n="SendMail label @@SendMail">Send mail</h4>
    </div>
</div>
<div *ngIf="errors && errors.length > 0">
    <app-error-alert title="Something gone wrong"
    [errors]="errors"></app-error-alert>
</div>