import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

var _appDatalistSelectNextId = 0;

export type DatalistOption = { label: string, sublabel?: string, value: any };

const DELETED_RECORD = $localize`:Dropdown label for a deleted options:[Deleted record]`;
const DELETED_RECORD_SUBTITLE = $localize`:Dropdown description for a deleted options:This option has been removed from the database`;

@Component({
  selector: 'app-datalist-select-tenants',
  templateUrl: './datalist-select-tenants.component.html',
  styleUrls: ['./datalist-select-tenants.component.scss']
})
export class DatalistSelectTenantsComponent implements OnInit {
  @Input() multiple = false;
  @Input() activeValue: any | undefined;
  @Output() activeValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() label: string | undefined;
  @Input() options: DatalistOption[] | null = [];
  optionsFiltered: DatalistOption[] | null = [];

  id = `appDatalistSelect_${_appDatalistSelectNextId++}`;

  itemsToRemove?: any[] = [];

  constructor() {
    this.setOptions();
  }

  setOptions() {
    this.optionsFiltered = this.options;
  }

  ngOnInit(): void {
    this.setOptions();
    if (this.optionsFiltered) {
      this.optionsFiltered = this.optionsFiltered?.filter(x => x.label && x.value);
    }
    if (!this.activeValue) {
      this.activeValue = undefined;
    } else {
      const values: any[] = this.multiple ? this.activeValue : [this.activeValue];
      // If any deleted/unknown value are pre-selected,
      // we display them in the list as such.
      values.forEach(value => {
        if (!this.optionsFiltered?.find(x => x.value === value)) {
          this.itemsToRemove!.push(value);
          this.optionsFiltered?.unshift({
            label: DELETED_RECORD,
            sublabel: DELETED_RECORD_SUBTITLE,
            value
          });
        }
      });

      if (this.optionsFiltered) {
        this.optionsFiltered = this.optionsFiltered?.filter(x => x.label != "[Deleted record]");
      }
      if (this.itemsToRemove) {
        this.activeValue = (this.activeValue as any[]).filter(x => !this.itemsToRemove!.includes(x));
      }   
    }
  }
}
