<div class="row text-white mb-1" style="background-color: #2F4A5D;" [style.background-image]="'url(' + (background || DEFAULT_BACKGROUND) + ')'">
  <div *ngIf="contact" class="mt-4 ml-3">
    <div class="col">
      <h5 class="px-2" name="contact">
        {{contact?.firstName + " " + contact?.lastName}}
      </h5>
      <h1 class="h5 m-2 p-0 text-start">
        <figure class="avatar avatar-200 rounded shadow">
          <img *ngIf="contact?.pictureUrl" src="{{env.apiUrl + '/' + contact?.pictureUrl}}">
          <i *ngIf="!contact?.pictureUrl" [ngClass]="['bi', icon]"></i>
        </figure>
      </h1>
    </div>
  </div>
  <h2 class="mb-4 mt-2 text-muted">Contact Information</h2>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Sport</label>
    <h5 class="ml-3" name="sport">{{contact?.tenant?.name ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Club</label>
    <h5 class="ml-3" name="club">{{contact?.club?.name ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">E-mail</label>
    <h5 class="ml-3" name="email">{{contact?.email ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Phone Number</label>
    <h5 class="ml-3" name="phoneNumber">{{contact?.phoneNumber ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Account Name</label>
    <h5 class="ml-3" name="accountName">{{contact?.accountName ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Job Title</label>
    <h5 class="ml-3" name="jobTitle">{{contact?.jobTitle ?? '-'}}</h5>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Whatsapp</label>
    <h5 class="ml-3" name="whatsapp">{{contact?.whatsapp ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Twitter</label>
    <h5 class="ml-3" name="twitter">{{contact?.twitter ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Instagram</label>
    <h5 class="ml-3" name="instagram">{{contact?.instagram ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Facebook</label>
    <h5 class="ml-3" name="facebook">{{contact?.facebook ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">TikTok</label>
    <h5 class="ml-3" name="tikTok">{{contact?.tikTok ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Agent1</label>
    <h5 class="ml-3" name="agent1">{{contact?.agent1 ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Agent2</label>
    <h5 class="ml-3" name="agent2">{{contact?.agent2 ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Player CV url</label>
    <h5 class="ml-3" name="link">{{contact?.playerCVLink ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Description</label>
    <h5 class="ml-3" name="description">{{contact?.description ?? '-'}}</h5>
  </div>
  <div class="row mb-2 mt-5 ml-2">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <label class="text-muted">Created At</label>
      <h5 class="ml-3" name="createdAt">{{(contact?.createdAt | date:'longDate') ?? '-'}}</h5>
    </div>
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <label class="text-muted">Updated At</label>
      <h5 class="ml-3" name="updatedAt">{{(contact?.updatedAt | date:'longDate') ?? '-'}}</h5>
    </div>
  </div>
  <div *ngIf="onWhomClicked[0] != null; else NoClicks;">
  <h2 class="mb-4 mt-2 text-muted">On Whom You Have Clicked</h2>
  <div class="d-flex flex-column ">
    <table class=" text-center table table-bordered table-hover w-75 ">
      <thead class="text-light">
        <tr>
          <th>Name</th>
          <th>Club</th>
          <th>Clicks</th>
        </tr>
      </thead>

      <tbody *ngFor="let item of onWhomClicked">
        <tr class="text-light">
          <td>
            <span>{{item.name}}</span>
          </td>
          <td>
            <span>{{item.club}}</span>

          </td>
          <td>
            <span>{{item.clicks}}</span>
          </td>

        </tr>
      </tbody>
    </table>

  </div>
</div>
  <ng-template #NoClicks>
    <h2 class="mb-4 mt-2 text-muted">You Have no Clicks</h2>
  </ng-template>
