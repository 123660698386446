import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Contact, ContactsRepository } from 'src/app/state/contacts.repository';
import { ContactsService } from 'src/app/state/contacts.service';
import { UnauthorisedService } from 'src/app/state/unauthorised.service';
import { UnauthorisedRepository,OnWhomClicked} from 'src/app/state/unauthorised.repository';



@Component({
  selector: 'app-contact-info-page',
  templateUrl: './contact-info-page.component.html',
  styleUrls: ['./contact-info-page.component.scss']
})
export class ContactInfoPageComponent implements OnInit {
  contact$: Observable<Contact> | null = null;
  contact: Contact | null = null;
  id?: string;
  submitErrors: string[] | null = null;
  icon: string = 'bi bi-person-workspace';
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';
  onWhomClickedId: string[] = [];
  onWhomClicked: OnWhomClicked[] = [];

  background: string | null = null;

  constructor(
    public repo: ContactsRepository,   
    private activatedRoute: ActivatedRoute,
    private service: ContactsService,
    private Unauthorisedservice: UnauthorisedService,
    private UnauthorisedReposiory: UnauthorisedRepository,

    private urlService: UrlIdResolverService,
    public env: EnvState
  ) { }

  ngOnInit(): void {
    this.contact$ = this.urlService.resolve(
      this.activatedRoute.paramMap,
      id => this.service.loadOne(id),
      id => this.repo.one(id),
      id => this.repo.statusOne(id),
      id => this.id = id
    );
    this.contact$.subscribe(x => this.contact = x);
    this.Unauthorisedservice.OnWhomClicked(`${this.id}`).subscribe(x => {
      this.onWhomClickedId = x;
      
      this.onWhomClickedId?.forEach(y => {
        this.Unauthorisedservice.loadOnWhomClicked(y,`${this.id}`).subscribe(z => {
          if (z != null) {
            this.onWhomClicked.push(z);

          }
        })

      })
    })
}  
}

