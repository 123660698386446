import { Component, Input, OnInit } from '@angular/core';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { Club } from 'src/app/state/clubs.repository';
import { ClubsService } from 'src/app/state/clubs.service';

@Component({
  selector: 'app-clubs-list',
  templateUrl: './clubs-list.component.html',
  styleUrls: ['./clubs-list.component.scss'],
})
export class ClubsListComponent implements OnInit {
  readonly CONTACTS = $localize`:Label for the number of contacts:Contacts: `;
  deleteConfirmation: string | null = null;
  @Input() entities: Club[] | null = null;
  @Input() tenantId: string | null = null;
  @Input() notForNoNeed: boolean = true;

  constructor(public env: EnvState, private service: ClubsService) {}

  ngOnInit(): void {}
  handleDeleteClick(id: string) {
    this.service.delete(id, this.tenantId!).subscribe();
  }
}
