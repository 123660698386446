import { Component, Input, OnInit } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { tenantSortOptions, TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';

@Component({
  selector: 'app-tenants-page',
  templateUrl: './tenants-page.component.html',
  styleUrls: ['./tenants-page.component.scss']
})
export class TenantsPageComponent implements OnInit {
  sortOptions = tenantSortOptions;
  
  constructor(
    public repo: TenantsRepository,
    private service: TenantsService
  ) { }

  ngOnInit(): void {
    this.service.load().subscribe();
  }
}
