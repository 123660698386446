import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseReadService } from "./abstract/base-read.service";
import { Contact, ContactsRepository } from "./contacts.repository";
import { of, switchMap, tap } from 'rxjs';
import { BaseService } from "./abstract/base.service";

const API = '/api/contacts';

@Injectable({ providedIn: 'root' })
export class ContactsService extends BaseService<Contact> {

  constructor(
    http: HttpClient,
    repo: ContactsRepository
  ) {
    super(API, http, repo);
  }
  updateContact(id: string, model: Partial<Contact>) {
    return this.http
      .patch<Contact>(`${API}/${id}`, model)
      .pipe(
        tap((contact) => this.repo.upsert(contact)),
        switchMap((contact) => {
          if (model.picture) {
            const formData = new FormData();
            formData.append("file", model.picture);
            return this.http.post<Contact>(`${API}/${id}/file`, formData);
          }
          return of(contact);
        }),
        this.repo.trackOne(id)
    );
  }
  search(name: string | undefined, id: string) {
    return this.http.get(API + `/search/${name}/${id}`)
  }
}
