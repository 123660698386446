import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/state/users.service';

@Component({
  selector: 'app-mailtester',
  templateUrl: './mailtester.component.html',
  styleUrls: ['./mailtester.component.scss']
})
export class MailtesterComponent implements OnInit {
  errors: string[] | null = null;
  success: string | null = null;
  constructor(private service: UsersService) { }

  ngOnInit(): void {
  }

  getMail() {
    this.service.testMail().subscribe({next: (value) => {
      this.success = value.data
    },
    error: data => this.errors = data
  })
  }

}
