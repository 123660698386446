<div class="d-flex flex-column align-items-center">
  <div class="wd-50">
    <div class="row headcolor text-light">
      <div class="pt pb col-6">
        <div name="playerName">
          <h1 class="margin">
            {{ player?.firstName ?? "-" }} {{ player?.lastName ?? "-" }}
          </h1>
        </div>
        <div class="margin ps-5">
          <h2
            class="d-inline"
            name="playerPosition"
            *ngFor="let item of player?.positions; let i = index"
          >
            {{ item }}
            <span *ngIf="i + 1 < (player?.positions)!.length">/</span>
          </h2>
        </div>
      </div>
      <div name="logo" class="pt col-6 pos-end">
        <img
          class="logoimg"
          src="/assets/img/white_logo1.png"
          alt="People in Sport"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-4 bg-lightgrey">
        <div class="mrg">
          <div
            name="playerPhoto"
            class="mrg align-items-center d-flex flex-column"
          >
            <br />
            <img
              class="playerimg align-items-center"
              *ngIf="player?.pictureUrl"
              src="{{ env.apiUrl + '/' + player?.pictureUrl }}"
              alt="People in Sport Localhost"
            />
            <img
              class="mt-2 iconsizing"
              *ngIf="!player?.pictureUrl"
              src="assets/img/user_default.svg"
            />
          </div>

          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <hr />
              <h5 class="font-avenir">PERSONAL</h5>
              <br />
              <div name="playerDateOfBirth" class=" ">
                <small class="d-inline font-headers-italic">Birthday: </small>
                <small class="d-inline font-personal-info">{{
                  player?.dateOfBirth | date : " MM.d.y " ?? "-"
                }}</small>
              </div>
              <br />

              <div name="playerWeight" class="">
                <small class="d-inline font-headers-italic">Height: </small>
                <small class="d-inline font-personal-info"
                  >{{ player?.height ?? "-" }}cm</small
                >
              </div>
              <br />
              <div name="playerWeight" class="">
                <small class="d-inline font-headers-italic">Weight: </small
                ><small class="d-inline font-personal-info"
                  >{{ player?.weight ?? "-" }}kg</small
                >
              </div>
              <br />
              <div name="playerNationality" class="">
                <small class="d-inline font-headers-italic"
                  >Nationality:
                </small>
                <small class="d-inline font-personal-info">{{
                  checkPipesInStrings(player?.nationality)
                }}</small>
              </div>
              <br />
              <div name="playerCivilStatus" *ngIf="player?.maritalStatus">
                <small class="d-inline font-headers-italic"
                  >Civil status:
                </small>
                <small class="d-inline font-personal-info">{{
                  checkPipesInStrings(player?.maritalStatus)
                }}</small>
              </div>
              <br />
              <hr />

              <div name="agent">
                <br />
                <br />
                <br />
                <h5 class="font-avenir text-end">AGENT</h5>
                <br />
                <div *ngIf="player?.agent1" class="text-end">
                  <div name="agentname" class="fw-bold">
                    <small>
                      {{ checkPipesInStrings(player?.agent1?.name) }}
                      {{ checkPipesInStrings(player?.agent1?.surname) }}
                    </small>
                  </div>
                  <div name="agentphone" class="text-c-grey">
                    <small>
                      Tel. : {{ player?.agent1?.phoneNumber ?? "-" }}
                    </small>
                  </div>

                  <div class="text-c-grey">
                    <small>{{ player?.agent1?.email ?? "-" }}</small>
                  </div>
                  <div class="text-c-grey">
                    <small> www.peopleinsport.eu</small>
                  </div>
                </div>
              </div>

              <div name="siteinfo" class="text-c-grey text-end">
                <br />

                <div><small>People In Sport</small></div>
                <div><small> Ørstedsvej 7</small></div>
                <div><small>DK-8600 Silkeborg</small></div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>

      <div class="col-8">
        <div class="row">
          <div class="margin col-11">
            <!-- <br />
            <h5 class="font-avenir">PROFILE</h5>
            <br />
            <div [innerHTML]="player?.description"></div>
            <br />
            <hr /> -->
            <br />
            <h5 class="font-avenir">HANDBALL INFORMATION</h5>
            <div
              *ngIf="offensive && offensive.length !== 0"
              name="offposition"
              class="row"
            >
              <br />
              <div class="col-5 fw-bold">
                <small class="font-headers"> Position:</small>
              </div>
              <div class="col-7">
                <small
                  *ngFor="let pos of offensive; let i = index"
                  class="d-inline"
                >
                  {{ pos }} <span *ngIf="i + 1 < offensive!.length">/</span>
                </small>
                <br />
              </div>
            </div>
            <div
              *ngIf="defensive && defensive.length !== 0"
              name="defposition"
              class="row"
            >
              <br />
              <div class="col-5 fw-bold">
                <br />
                <small class="font-headers">Defensive position:</small>
                <br />
              </div>

              <div class="col-7">
                <br />
                <small
                  *ngFor="let pos of defensive; let i = index"
                  class="d-inline"
                >
                  {{ pos }} <span *ngIf="i + 1 < defensive!.length">/</span>
                </small>
              </div>
            </div>
            <br />
            <div name="clubname" class="row" *ngIf="player?.clubName">
              <br />
              <div class="col-5 fw-bold">
                <small class="font-headers">Current club:</small>
              </div>
              <br />
              <div class="col-7">
                <small> {{ player?.clubName ?? "-" }}</small>
              </div>
            </div>
            <br />
            <div name="formerCLub" class="row" *ngIf="player?.formerClub">
              <br />
              <div class="col-5 fw-bold">
                <small class="font-headers">Former club:</small>
              </div>
              <br />
              <div class="col-7">
                <small> {{ player?.formerClub ?? "-" }}</small>
              </div>
            </div>
            <br />
            <div
              name="internationalLevel"
              class="row"
              *ngIf="player?.internationalLevel | removeParagraphTags"
            >
              <br />
              <div class="col-12">
                <small class="font-headers fw-bold">International Level:</small>
                <br />
                <small [innerHTML]="player?.internationalLevel"></small>
              </div>
            </div>
            <br />
            <div
              name="statistics"
              class="row"
              *ngIf="player?.statistics | removeParagraphTags"
            >
              <br />
              <div class="col-12">
                <small class="font-headers fw-bold">Statistics:</small>
                <br />
                <small [innerHTML]="player?.statistics"></small>
              </div>
            </div>
            <br />
            <div
              name="playerInfo"
              class="row"
              *ngIf="player?.description | removeParagraphTags"
            >
              <br />
              <div class="col-12">
                <small class="font-headers fw-bold">Info:</small>
                <br />
                <small [innerHTML]="player?.description"></small>
              </div>
            </div>
            <br />
            <div
              name="references"
              class="row"
              *ngIf="player?.playerReferences | removeParagraphTags"
            >
              <br />
              <div class="col-12">
                <small class="font-headers fw-bold">References:</small>
                <br />
                <small [innerHTML]="player?.playerReferences"></small>
              </div>
            </div>
            <br />
            <small>
              <app-download-pdf
                [exportFactory]="preview"
                [titleOflabel]="'Download Player CV'"
                [filenameExtension]="'.pdf'"
                [filename]="player?.firstName + '_' + player?.lastName + '_CV'"
              >
              </app-download-pdf>
            </small>
            <br />
            <hr />
            <div>
              <p class="font-video">VIDEO</p>
              <div class="row">
                <div class="col-11">
                  <b style="line-height: 3" *ngIf="teaserUrls.length"
                    >Highlights – To see his playing style:</b
                  >
                  <div *ngFor="let video of teaserUrls">
                    <div *ngIf="video.isPrimary" class="row">
                      <div>
                        {{ video.title }}:
                        <a href="{{ video.videoUrl }}" style="line-height: 3">{{
                          video.videoUrl
                        }}</a>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div *ngFor="let video of teaserUrls">
                    <div *ngIf="!video.isPrimary" class="row">
                      <div>
                        {{ video.title }}:
                        <a href="{{ video.videoUrl }}" style="line-height: 3">{{
                          video.videoUrl
                        }}</a>
                      </div>
                      <br />
                    </div>
                  </div>
                  <b style="line-height: 3" *ngIf="fullMatchUrls.length"
                    >Full matches:</b
                  >
                  <div *ngFor="let video of fullMatchUrls">
                    <div class="row">
                      <div>
                        {{ video.title }}:
                        <a href="{{ video.videoUrl }}" style="line-height: 3">{{
                          video.videoUrl
                        }}</a>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
