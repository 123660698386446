<div class="row align-items-center px-3">
  <div class="row">
    <h3 class="col-md-3 col-xl-2 me-3">Current Match</h3>
    <div class="col-md-3 col-xl-2 ms-1 mb-3">
      <app-datalist-select
        id="createdAtSelect"
        [options]="createdAtOptions"
        [(activeValue)]="createdAtMatchId"
        (activeValueChange)="updateValue($event)"
        label="CreatedAt"
        i18n-label="Label createdAt"
      >
      </app-datalist-select>
    </div>
  </div>
  <div class="card shadow-sm mb-3 col-md-6 col-xl-4">
    <div class="mt-2">
      <div class="row mb-3 mb-md-0">
        <div class="col-auto position-relative">
          <h6>For Club: {{ match?.requirement?.club?.name }}</h6>
        </div>
        <div class="col">
          <h6></h6>
        </div>
        <br />
        <div class="col-auto position-relative">
          <h6>
            Contact with:
            <a
              class="text-right"
              style="cursor: pointer"
              class="btn-sm btn-link btn-light me-2 mb-3"
              (click)="
                goTo(
                  '/contacts' +
                    '/' +
                    match?.requirement?.contact?.tenantId +
                    '/' +
                    match?.requirement?.contact?.id
                )
              "
            >
              <span>{{
                match?.requirement?.contact?.firstName +
                  " " +
                  match?.requirement?.contact?.lastName
              }}</span>
            </a>
          </h6>
        </div>
      </div>
    </div>
    <div class="col-12 col-auto mb-2">
      <div class="row">
        <div class="col-6">
          <div class="text-md-start">
            <span class="me-2 badge bg-primary">Searched positions:</span>
          </div>
          <small *ngFor="let item of match?.requirement?.positionRequirements">
            <strong class="text-muted me-3">{{ item?.name }}</strong>
          </small>
        </div>
        <div class="col-6 text-md-end">
          <div class="row row-cols-auto justify-content-end">
            <div class="col ps-0 p-md-0">
              <h6>Transfer period:</h6>
            </div>
            <div class="col">
              <h6>{{ match?.requirement?.transfer?.years }}</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center text-md-end">
        <small class="text-muted mr-2 w-100" i18n="Added date label"
          >Added {{ match?.createdAt | date : "short" }}</small
        >
      </div>
      <div class="text-break">
        <h6 i18-n="description">Description:</h6>
        <small
          ><strong class="text-muted">{{
            match?.requirement?.description
          }}</strong></small
        >
      </div>
    </div>
  </div>
</div>
<div class="col-md-2 col-sm-4 col-xl-4 px-1" *ngIf="hasBeenSent && infoToCopy">
  <div>
    <div class="form-group form-floating mb-3">
      <div class="row"></div>
      <div
        style="height: 100%"
        type="text"
        class="form-control"
        placeholder="Copy Link"
        i18n-placeholder="copyLink placeholder @@copyLink"
      >
        <div class="row">
          <textarea
            class="col line-break ms-3 mb-1 form-floating"
            rows="4"
            [innerHtml]="infoToCopy"
          ></textarea>
          <div class="col text-end">
            <button
              (click)="onClick()"
              class="btn btn-sm btn-success ms-2 me-1"
            >
              <ng-container *ngIf="!copied" i18n="@@send"
                >Copy to clipboard</ng-container
              >
              <ng-container *ngIf="copied" i18n="@@send">Copied</ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row d-flex justify-content-start space-between">
  <h4 class="col-md-3 col-xl-2 ms-2">Results:</h4>
  <div class="col-md-3 col-xl-2 text-end mb-3">
    <button
      class="btn btn-sm btn-info"
      (click)="sendToAcInfo(match); isClicked = true"
      *ngIf="!hasBeenSent"
    >
      <ng-container i18n="@@copylink">Copy Link</ng-container>
    </button>
    <button
      class="btn btn-sm btn-success ms-2 me-1"
      (click)="sendToAc(match); isClicked = true"
      *ngIf="!hasBeenSent"
    >
      <ng-container i18n="@@send">Send matches</ng-container>
    </button>
    <app-spinner
      *ngIf="!hasBeenSent && isClicked && !submitErrors"
    ></app-spinner>
  </div>

  <div class="row">
    <app-error-alert
      class="col-md-6 col-xl-4 ms-1"
      title="Could not send the match because the contacts are not chosen"
      i18n-title="Could not send the match because the contacts are not chosen"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>

<div *ngFor="let contact of match?.contacts; let i = index">
  <app-contact-preview-card
    [contact]="contact"
    (contactEmitter)="addContactIdToMatch($event)"
    [contactMatch]="match?.contactMatches && match!.contactMatches[i]"
  >
  </app-contact-preview-card>
</div>
