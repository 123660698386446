import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Contact } from 'src/app/state/contacts.repository';
import { Country } from 'src/app/state/countries.repository';
import { League } from 'src/app/state/leagues.repository';
import { PlayerLevel } from 'src/app/state/playerlevels.repository';
import { Position } from 'src/app/state/positions.repository';
import { Team } from 'src/app/state/teams.repository';
import { Valuta } from 'src/app/state/valutas.repository';

@Component({
  selector: 'app-player-edit-form',
  templateUrl: './player-edit-form.component.html',
  styleUrls: ['./player-edit-form.component.scss'],
})
export class PlayerEditFormComponent implements OnInit {
  @Input() contact$?: Observable<Contact> | null;
  editedContact?: Contact | null;
  form: FormGroup | null = null;
  file: File | null = null;
  pristineLeaguePriorityOptions: DatalistOption[] | null = null;
  pristineLeagueExclusionOptions: DatalistOption[] | null = null;
  pristineCountryPriorityOptions: DatalistOption[] | null = null;
  pristineCountryExclusionOptions: DatalistOption[] | null = null;
  pristineTeamPriorityOptions: DatalistOption[] | null = null;
  pristineTeamExclusionOptions: DatalistOption[] | null = null;
  leaguePriorityOptions: DatalistOption[] | null = null;
  leagueExclusionOptions: DatalistOption[] | null = null;
  countryPriorityOptions: DatalistOption[] | null = null;
  countryExclusionOptions: DatalistOption[] | null = null;
  teamPriorityOptions: DatalistOption[] | null = null;
  teamExclusionOptions: DatalistOption[] | null = null;
  valutaOptions: DatalistOption[] | null = null;
  playerLevelOptions: DatalistOption[] | null = null;
  positionOptions: DatalistOption[] | null = null;
  rightyLefty = ['Right', 'Left', 'Both'];
  activeCurrency?: Valuta | null = null;
  rightyLeftyOptions: DatalistOption[] = this.rightyLefty.map((x) => ({
    value: x,
    label: x,
  }));

  @Input() set positions(value: Position[] | null) {
    if (!value) {
      this.positionOptions = null;
    } else {
      this.positionOptions = value
        .filter((x) => x.name != 'No need')
        .map((x) => ({
          value: x.id,
          label: x.name,
        }));
    }
  }

  @Input() set playerLevels(value: PlayerLevel[] | null) {
    if (!value) {
      this.playerLevelOptions = null;
    } else {
      this.playerLevelOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set valutas(value: Valuta[] | null) {
    if (!value) {
      this.valutaOptions = null;
    } else {
      this.activeCurrency = value.find((x) => x.name === 'EUR');
      this.valutaOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set leaguePriorities(value: League[] | null) {
    if (!value) {
      this.leaguePriorityOptions = null;
    } else {
      this.leaguePriorityOptions = value.map((x) => ({
        value: x.id,
        label: `${x.name} - ${x.countryName}`,
      }));
      this.pristineLeaguePriorityOptions = this.leaguePriorityOptions;
      if (this.editedContact) {
        this.leaguePriorityOptions = this.leaguePriorityOptions.filter(
          (x) =>
            !this.editedContact?.leagueExclusionContacts
              ?.map((x) => x.id)
              ?.includes(x.value)
        );
      }
    }
  }

  @Input() set leagueExclusions(value: League[] | null) {
    if (!value) {
      this.leagueExclusionOptions = null;
    } else {
      this.leagueExclusionOptions = value.map((x) => ({
        value: x.id,
        label: `${x.name} - ${x.countryName}`,
      }));
      this.pristineLeagueExclusionOptions = this.leagueExclusionOptions;
      if (this.editedContact) {
        this.leagueExclusionOptions = this.leagueExclusionOptions.filter(
          (x) =>
            !this.editedContact?.leaguePriorityContacts
              ?.map((x) => x.id)
              ?.includes(x.value)
        );
      }
    }
  }

  @Input() set countryPriorities(value: Country[] | null) {
    if (!value) {
      this.countryPriorityOptions = null;
    } else {
      this.countryPriorityOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
      this.pristineCountryPriorityOptions = this.countryPriorityOptions;
      if (this.editedContact) {
        this.countryPriorityOptions = this.countryPriorityOptions.filter(
          (x) =>
            !this.editedContact?.countryExclusionContacts
              ?.map((x) => x.id)
              ?.includes(x.value)
        );
      }
    }
  }

  @Input() set countryExclusions(value: Country[] | null) {
    if (!value) {
      this.countryExclusionOptions = null;
    } else {
      this.countryExclusionOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
      this.pristineCountryExclusionOptions = this.countryExclusionOptions;
      if (this.editedContact) {
        this.countryExclusionOptions = this.countryExclusionOptions.filter(
          (x) =>
            !this.editedContact?.countryPriorityContacts
              ?.map((x) => x.id)
              ?.includes(x.value)
        );
      }
    }
  }

  @Input() set teamPriorities(value: Team[] | null) {
    if (!value) {
      this.teamPriorityOptions = null;
    } else {
      this.teamPriorityOptions = value.map((x) => ({
        value: x.id,
        label: `${x.name} - ${x?.league?.name}`,
      }));
      this.pristineTeamPriorityOptions = this.teamPriorityOptions;
      if (this.editedContact) {
        this.teamPriorityOptions = this.teamPriorityOptions.filter(
          (x) =>
            !this.editedContact?.teamExclusionContacts
              ?.map((x) => x.id)
              ?.includes(x.value)
        );
      }
    }
  }

  @Input() set teamExclusions(value: Team[] | null) {
    if (!value) {
      this.teamExclusionOptions = null;
    } else {
      this.teamExclusionOptions = value.map((x) => ({
        value: x.id,
        label: `${x.name} - ${x?.league?.name}`,
      }));
      this.pristineTeamExclusionOptions = this.teamExclusionOptions;
      if (this.editedContact) {
        this.teamExclusionOptions = this.teamExclusionOptions.filter(
          (x) =>
            !this.editedContact?.teamPriorityContacts
              ?.map((x) => x.id)
              ?.includes(x.value)
        );
      }
    }
  }

  ngDoCheck() {
    if (this.editedContact) {
      if (this.pristineLeaguePriorityOptions) {
        this.leaguePriorityOptions = this.pristineLeaguePriorityOptions.filter(
          (x) => !this.form?.value.leagueExclusionsIds?.includes(x.value)
        );
      }
      if (this.pristineLeagueExclusionOptions) {
        this.leagueExclusionOptions =
          this.pristineLeagueExclusionOptions.filter(
            (x) => !this.form?.value.leaguePrioritiesIds?.includes(x.value)
          );
      }
      if (this.pristineCountryPriorityOptions) {
        this.countryPriorityOptions =
          this.pristineCountryPriorityOptions.filter(
            (x) => !this.form?.value.countryExclusionsIds?.includes(x.value)
          );
      }
      if (this.pristineCountryExclusionOptions) {
        this.countryExclusionOptions =
          this.pristineCountryExclusionOptions.filter(
            (x) => !this.form?.value.countryPrioritiesIds?.includes(x.value)
          );
      }
      if (this.pristineTeamPriorityOptions) {
        this.teamPriorityOptions = this.pristineTeamPriorityOptions.filter(
          (x) => !this.form?.value.teamExclusionsIds?.includes(x.value)
        );
      }
      if (this.pristineTeamExclusionOptions) {
        this.teamExclusionOptions = this.pristineTeamExclusionOptions.filter(
          (x) => !this.form?.value.teamPrioritiesIds?.includes(x.value)
        );
      }
    }
  }

  @Output() contactSubmit = new EventEmitter<Partial<Contact>>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if (this.form) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.picture = this.file;
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if (this.form && this.file) {
      this.form.value.picture = this.file;
    }
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    if (this.contact$) {
      this.contact$.subscribe((x) => {
        if (x) {
          this.editedContact = x;
          this.form = this.formBuilder.group({
            countryPrioritiesIds: [
              this.editedContact?.countryPriorityContacts?.map((x) => x.id),
            ],
            countryExclusionsIds: [
              this.editedContact?.countryExclusionContacts?.map((x) => x.id),
            ],
            leaguePrioritiesIds: [
              this.editedContact?.leaguePriorityContacts?.map((x) => x.id),
            ],
            leagueExclusionsIds: [
              this.editedContact?.leagueExclusionContacts?.map((x) => x.id),
            ],
            teamPrioritiesIds: [
              this.editedContact?.teamPriorityContacts?.map((x) => x.id),
            ],
            teamExclusionsIds: [
              this.editedContact?.teamExclusionContacts?.map((x) => x.id),
            ],
            positionsIds: [
              this.editedContact?.positionContacts?.map((x) => x.id),
              Validators.required,
            ],
            valutaId: [this.editedContact?.valutaId || '', Validators.required],
            playerLevelId: [
              this.editedContact?.playerLevelId || null,
              Validators.required,
            ],
            picture: [this.editedContact?.picture],
            linkToCV: [this.editedContact?.playerCVLink || ''],
            height: [this.editedContact?.height || 0],
            weight: [this.editedContact?.weight || 0],
            rightyLefty: [this.editedContact?.rightyLefty || ''],
            mandate: [this.editedContact?.mandate],
            description: [this.editedContact?.description || ''],
            minSalaryNetto: [this.editedContact?.minSalaryNetto || 0],
            minSalaryBrutto: [this.editedContact?.minSalaryBrutto || 0],
            estTransfer: [this.editedContact?.estTransfer || 0],
            videoInfos: this.formBuilder.array([]),
            formerClub: this.editedContact?.formerClub,
            statistics: this.editedContact?.statistics,
            internationalLevel: this.editedContact?.internationalLevel,
            playerReferences: this.editedContact?.playerReferences,
          });
          this.setVideoInfos();
        }
      });
    }
  }

  ngAfterContentChecked(): void {
    if (!this.editedContact?.valutaId) {
      this.updateValue('valutaId', this.activeCurrency?.id);
    }
  }

  getControls() {
    if (this.form) {
      return (this.form.get('videoInfos') as FormArray).controls;
    }
    return [];
  }

  deleteVideo(index: number) {
    let control = <FormArray>this.form?.controls.videoInfos;
    control.removeAt(index);
  }

  setVideoInfos() {
    let control = <FormArray>this.form?.controls.videoInfos;
    this.editedContact?.videoInfos.forEach((x) => {
      control.push(
        this.formBuilder.group({
          tenantId: this.editedContact?.tenantId,
          contactId: this.editedContact?.id,
          title: [x.title, Validators.required],
          videoUrl: [x.videoUrl, Validators.required],
          isPrimary: x.isPrimary,
          isFullMatch: x.isFullMatch,
        })
      );
    });
  }

  setPrimary(index: number) {
    let control = <FormArray>this.form?.controls.videoInfos;
    control.controls[index].get('isFullMatch')?.setValue(false);
    control.controls.forEach((element) => {
      let innerIndex = control.controls.indexOf(element);
      if (innerIndex !== index) {
        let notPrimary = control.at(innerIndex).get('isPrimary');
        notPrimary?.setValue(false);
        notPrimary?.markAsTouched();
      }
    });
  }

  setFullMatch(index: number) {
    let control = <FormArray>this.form?.controls.videoInfos;
    control.controls[index].get('isPrimary')?.setValue(false);
  }

  addNewVideoInfo() {
    let control = <FormArray>this.form?.controls.videoInfos;
    control.push(
      this.formBuilder.group({
        tenantId: this.editedContact?.tenantId,
        contactId: this.editedContact?.id,
        title: ['', Validators.required],
        videoUrl: ['', Validators.required],
        isPrimary: false,
      })
    );
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.form && this.file) {
      this.form.value.picture = this.file;
    }

    this.contactSubmit.emit({
      countryPriorityContactIds: this.form?.value?.countryPrioritiesIds,
      countryExclusionContactIds: this.form?.value?.countryExclusionsIds,
      leaguePriorityContactIds: this.form?.value?.leaguePrioritiesIds,
      leagueExclusionContactIds: this.form?.value?.leagueExclusionsIds,
      teamPriorityContactIds: this.form?.value?.teamPrioritiesIds,
      teamExclusionContactIds: this.form?.value?.teamExclusionsIds,
      positionContactIds: this.form?.value?.positionsIds,
      valutaId: this.form?.value?.valutaId,
      playerLevelId: this.form?.value?.playerLevelId,
      picture: this.form?.value?.picture,
      playerCVLink: this.form?.value?.linkToCV,
      height: this.form?.value?.height,
      weight: this.form?.value?.weight,
      rightyLefty: this.form?.value?.rightyLefty,
      mandate: this.form?.value?.mandate,
      description: this.form?.value?.description,
      minSalaryNetto: this.form?.value?.minSalaryNetto,
      minSalaryBrutto: this.form?.value?.minSalaryBrutto,
      estTransfer: this.form?.value?.estTransfer,
      videoInfos: this.form?.value?.videoInfos,
      formerClub: this.form?.value?.formerClub,
      statistics: this.form?.value?.statistics,
      internationalLevel: this.form?.value?.internationalLevel,
      playerReferences: this.form?.value?.playerReferences,
    });

    this.form?.markAsUntouched();
  }
}
