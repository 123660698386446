import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import { ValutasRepository, valutasSortOptions } from 'src/app/state/valutas.repository';
import { ValutasService } from 'src/app/state/valutas.service';

@Component({
  selector: 'app-valutas-page',
  templateUrl: './valutas-page.component.html',
  styleUrls: ['./valutas-page.component.scss']
})
export class ValutasPageComponent implements OnInit {
  sortOptions = valutasSortOptions;
  exportFactory = () => this.service.export();
  id: string | null = null;

  constructor(
    public service: ValutasService,
    public repo: ValutasRepository,
    public auth: AuthRepository,
    private activatedRoute: ActivatedRoute,
    public tenantRepo: TenantsRepository
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({ 
      next: (value) => {        
        this.id = value.get('tenant?');
        this.service.reloadPage(this.id!).subscribe();
      }    
    });
  }
}
