<div class="row align-items-center">
  <div class="col-12 col-md-auto">
    <div class="row mb-3 mb-md-0">
      <div class="col-auto position-relative" *ngIf="icon && !image">
        <app-entity-avatar [icon]="icon" [roles]="roles"></app-entity-avatar>
      </div>

      <div class="col-auto position-relative" *ngIf="image">
        <app-entity-avatar [img]="image" [roles]="roles"></app-entity-avatar>
      </div>

      <div class="col">
        <p>
          <span>{{ title }}</span>
          <br />
          <small>
            <span *ngIf="badge" class="me-2 badge bg-primary">{{ badge }}</span>
            <span *ngIf="subtitle" class="text-muted me-2">{{ subtitle }}</span>
          </small>
        </p>
      </div>
    </div>
  </div>
  <div class="col-12 col-md">
    <div class="row">
      <div class="col d-flex align-items-center text-md-end">
        <small class="text-muted mr-2 w-100" i18n="Added date label"
          >Added {{ date | date : "longDate" }}</small
        >
      </div>
      <div class="col-auto">
        <div class="vr h-100"></div>
      </div>
      <div
        *ngIf="!isDeleteDisabled && notForNoNeed"
        class="col-auto pl-0 d-flex align-items-center"
      >
        <button
          type="button"
          class="btn btn-md btn-link w-100 text-danger"
          (click)="handleDeleteClick($event)"
          title="Delete"
          i18n-title="Delete button"
        >
          <i class="bi bi-trash"></i>
        </button>
      </div>
      <div
        *ngIf="!isEditDisabled && notForNoNeed"
        class="col-auto pl-0 d-flex align-items-center"
      >
        <a
          class="btn btn-md btn-link w-100"
          [routerLink]="[routerLinkBase, id, 'edit']"
        >
          <i class="bi bi-pencil me-1"></i>
          <ng-container i18n="@@edit">Edit</ng-container>
        </a>
      </div>
      <div *ngIf="notForNoNeed" class="col-auto pl-0 d-flex align-items-center">
        <a
          class="btn btn-md btn-link w-100"
          [routerLink]="[routerLinkBase, id]"
        >
          <ng-container i18n>View</ng-container>
        </a>
      </div>
      <div
        *ngIf="isPlayerViewEnabled"
        class="col-auto pl-0 d-flex align-items-center"
      >
        <a
          class="btn btn-md btn-link w-100"
          href="pins/{{ tenantName }}/{{ title }}/cv/{{ id }}"
          target="_blank"
        >
          <ng-container i18n="@@viewcv"
            ><i title="View Player CV" class="bi bi-file-earmark-person"></i
          ></ng-container>
        </a>
      </div>
      <div
        *ngIf="!notForNoNeed"
        class="col-auto pl-0 d-flex align-items-center"
      >
        <a class="btn btn-md btn-link w-100" (click)="handleRequirement()">
          <ng-container>New Requirement</ng-container>
        </a>
      </div>
    </div>
  </div>
</div>
