import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DayjsService } from '../../services/dayjs.service';

@Component({
  selector: 'app-download-pdf',
  templateUrl: './download-pdf.component.html',
  styleUrls: ['./download-pdf.component.scss']
})
export class DownloadPdfComponent implements OnInit {
  @Input() exportFactory?: () => Observable<string>;
  @Input() importFactory?: (file: File) => Observable<void>;
  @Input() filename: string = '';
  @Input() filenameExtension: string = '.csv';
  @Input() titleOflabel: string = 'Preview';
  Errors: string[]|null=null;

  isExporting = false;
  isImporting = false;
  isImportError = false;

  @ViewChild("importExportLink") importExportLink!: ElementRef;
  @ViewChild("fileImport") fileImport!: ElementRef;

  constructor(public ngDay: DayjsService) { }

  ngOnInit(): void {
  }

  export() {
    if (!this.exportFactory) {
      return;
    }
    this.isExporting = true;
    this.exportFactory().subscribe({
      next: url => {      
          this.importExportLink.nativeElement.href = url;
          const filename = `${this.filename}${this.filenameExtension}`;
          this.importExportLink.nativeElement.setAttribute('download', filename);
          this.importExportLink.nativeElement.click();    
      },
      error: error => {
        this.Errors = error;
        return error;
      },
    });
  }

  

}
