<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="row mb-2">
    <div class="col">
      <h6 class="my-1" i18n="Basic info label">Basic Search</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('transferId')?.hasError('required') &&
          form.get('transferId')?.touched
        "
      >
        <app-datalist-select
          id="transfer"
          [options]="transferOptions"
          [(activeValue)]="form.value.transferId"
          (activeValueChange)="updateValue('transferId', $event)"
          label="Transfer"
          i18n-label="Label transfer"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('clubId')?.hasError('required') &&
          form.get('clubId')?.touched
        "
      >
        <app-datalist-select
          id="club"
          [options]="clubOptions"
          [(activeValue)]="form.value.clubId"
          (activeValueChange)="updateValue('clubId', $event); updateContacts()"
          label="Club"
          i18n-label="Label club"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('contactId')?.hasError('required') &&
          form.get('contactId')?.touched
        "
      >
        <app-datalist-select
          id="contact"
          [options]="contactOptions"
          [(activeValue)]="form.value.contactId"
          (activeValueChange)="updateValue('contactId', $event)"
          label="Contact"
          i18n-label="Label contact"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('positionRequirementIds')?.hasError('required') &&
          form.get('positionRequirementIds')?.touched
        "
      >
        <app-datalist-select
          id="positions"
          [options]="positionOptions"
          [multiple]="true"
          [(activeValue)]="form.value.positionRequirementIds"
          (activeValueChange)="updateValue('positionRequirementIds', $event)"
          label="Positions"
          i18n-label="Label positions"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('playerLevelRequirementIds')?.hasError('required') &&
          form.get('playerLevelRequirementIds')?.touched
        "
      >
        <app-datalist-select
          id="playerLevels"
          [options]="playerLevelOptions"
          [multiple]="true"
          [(activeValue)]="form.value.playerLevelRequirementIds"
          (activeValueChange)="updateValue('playerLevelRequirementIds', $event)"
          label="Player Level"
          i18n-label="Label playerLevels"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('contractStatusRequirementIds')?.hasError('required') &&
          form.get('contractStatusRequirementIds')?.touched
        "
      >
        <app-datalist-select
          id="contractStatuses"
          [options]="contractStatusOptions"
          [multiple]="true"
          [(activeValue)]="form.value.contractStatusRequirementIds"
          (activeValueChange)="
            updateValue('contractStatusRequirementIds', $event)
          "
          label="Contract Status"
          i18n-label="Label contractStatuses"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="maxNetto"
          type="number"
          class="form-control"
          placeholder="Max Netto"
          i18n-placeholder="Max Netto placeholder @@maxNetto"
        />
        <label for="maxNetto" i18n="maxNetto">Max Netto</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="maxBrutto"
          type="number"
          class="form-control"
          placeholder="Max Brutto"
          i18n-placeholder="Max Brutto placeholder @@maxBrutto"
        />
        <label for="maxBrutto" i18n="maxBrutto">Max Brutto</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="maxTransferSum"
          type="number"
          class="form-control"
          placeholder="Max Transfer Sum"
          i18n-placeholder="Max Transfer Sum placeholder @@maxTransferSum"
        />
        <label for="maxTransferSum" i18n="maxTransferSum"
          >Max Transfer Sum</label
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="valuta"
          [options]="valutaOptions"
          [(activeValue)]="form.value.valutaId"
          (activeValueChange)="updateValue('valutaId', $event)"
          label="Currency"
          i18n-label="Label currency"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-lg-8">
      <div class="form-group form-floating mb-3">
        <textarea
          formControlName="description"
          type="text"
          class="form-control h-150"
          placeholder="Description"
          i18n-placeholder="description"
        ></textarea>
        <label for="description" i18n="description">Description</label>
      </div>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col">
      <div class="form-check form-switch">
        <label
          for="advancedSearch"
          i18n="advancedSearch"
          class="form-check-label"
          >Advanced Search</label
        >
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isAdvancedSearch"
          (change)="changeAdvancedSearch()"
        />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="advancedSearch">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="minAge"
          type="number"
          class="form-control"
          placeholder="Min Age"
          i18n-placeholder="Min Age placeholder @@minAge"
        />
        <label for="minAge" i18n="minAge">Min Age</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="maxAge"
          type="number"
          class="form-control"
          placeholder="Max Age"
          i18n-placeholder="Min Age placeholder @@maxAge"
        />
        <label for="maxAge" i18n="maxAge">Max Age</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="contractType"
          [options]="contractTypeOptions"
          [(activeValue)]="form.value.contractType"
          (activeValueChange)="updateValue('contractType', $event)"
          label="Contract Type"
          i18n-label="Label contractType"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="maritalStatus"
          [options]="maritalStatusOptions"
          [(activeValue)]="form.value.maritalStatus"
          (activeValueChange)="updateValue('maritalStatus', $event)"
          label="Marital Status"
          i18n-label="Label maritalStatus"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="nationality"
          [options]="countryOptions"
          [(activeValue)]="form.value.countryId"
          (activeValueChange)="updateValue('countryId', $event)"
          label="Nationality"
          i18n-label="Label nationality"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="pinsPriority"
          [options]="pinsPriorityOptions"
          [(activeValue)]="form.value.pinsPriority"
          (activeValueChange)="updateValue('pinsPriority', $event)"
          label="PINS Priority"
          i18n-label="Label pinsPriority"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="minHeight"
          type="number"
          class="form-control"
          placeholder="Min Height"
          i18n-placeholder="Min Height placeholder @@minHeight"
        />
        <label for="minHeight" i18n="minHeight">Min Height</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="maxHeight"
          type="number"
          class="form-control"
          placeholder="Max Height"
          i18n-placeholder="Min Height placeholder @@maxHeight"
        />
        <label for="maxHeight" i18n="maxHeight">Max Height</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="rightyLefty"
          [options]="rightyLeftyOptions"
          [(activeValue)]="form.value.rightyLefty"
          (activeValueChange)="updateValue('rightyLefty', $event)"
          label="Right Left"
          i18n-label="Label rightyLefty"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="minWeight"
          type="number"
          class="form-control"
          placeholder="Min Weight"
          i18n-placeholder="Min Weight placeholder @@minWeight"
        />
        <label for="minWeight" i18n="minWeight">Min Weight</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="maxWeight"
          type="number"
          class="form-control"
          placeholder="Max Weight"
          i18n-placeholder="Max Weight placeholder @@maxWeight"
        />
        <label for="maxWeight" i18n="maxWeight">Max Weight</label>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="form-group form-floating pb-3 d-grid mr-3">
      <button
        type="submit"
        class="btn btn-md-lg btn-primary shadow-sm"
        i18n="Save button @@save"
      >
        Save
      </button>
    </div>
  </div>
</form>
