<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <div class="row mb-2">
    <div class="col">
      <h6 class="my-1" i18n="Basic info label">Basic Information</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="file" type="file" class="form-control"
               placeholder="file" />
        <label for="file" i18n="Path">PDF Template</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input rows="4" style="height:100%;" formControlName="name" type="text" class="form-control "
                  placeholder="Description">
        <label for="name" i18n="@@description">Name</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating pb-3 d-grid mr-3">
        <button type="submit" class="btn btn-md-lg btn-primary shadow-sm" i18n="Save button @@save">Save</button>
      </div>
    </div>
  </div>
</form>
