<div class="row">
  <div class="col-12 mb-2">
    <h1
      [class.handballwomen]="
        (tenantRepo.tenant(id!) | async)?.name?.includes('Handball')
      "
    >
      Clubs [{{ (tenantRepo.tenant(id!) | async)?.name }}]
    </h1>
  </div>
  <div class="row mb-4">
    <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
      <app-pagination
        [page]="(repo.pageNumber$ | async) || 1"
        [total]="(repo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="
          service.loadNoneedPageWithFilter($event, filter).subscribe()
        "
      ></app-pagination>
    </div>
    <div class="col-12 col-md-auto">
      <app-sort-dropdown
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="
          service.sortNoneedWithFilter($event, filter).subscribe()
        "
      ></app-sort-dropdown>
    </div>
  </div>
</div>
<app-spinner *ngIf="!loadedCheck()"></app-spinner>

<div *ngIf="loadedCheck()">
  <form
    class="row"
    *ngIf="filterForm"
    [formGroup]="filterForm"
    enctype="multipart/form-data"
  >
    <div class="mt-2 col-12 col-md-6">
      <div
        class="form-group form-floating"
        [class.is-invalid]="
          filterForm.get('transferId')?.hasError('required') &&
          filterForm.get('transferId')?.touched
        "
      >
        <app-datalist-select
          id="TransferSelect"
          [options]="TransferOptions"
          [(activeValue)]="filterForm?.value.transferId"
          label="Transfer"
          i18n-label="Transfer"
          (activeValueChange)="updateValue('transferId', $event)"
        ></app-datalist-select>
      </div>
    </div>
    <div class="mt-2 col-12 col-md-6">
      <app-datalist-select
        id="CountryOptionsSelect"
        [options]="CountryOptions"
        [(activeValue)]="filterForm?.value.countryId"
        (activeValueChange)="updateValue('countryId', $event)"
        label="Country Options"
        i18n-label="CountryOptions"
      ></app-datalist-select>
    </div>
    <div class="col-12 d-flex justify-content-between mt-2 d-flex">
      <div class="col-8 col-sm-10 col-lg-11 me-2">
        <input
          formControlName="filter"
          type="text"
          class="form-control"
          placeholder="Search"
          i18n-placeholder="Search"
        />
      </div>
      <div class="col-auto">
        <button
          (click)="searchSubmit()"
          class="btn btn-md btn-primary"
          i18n="@@Search"
        >
          Search
        </button>
      </div>
    </div>
  </form>

  <div class="row mb-4">
    <div class="col">
      <app-clubs-list
        [entities]="repo.page$ | async"
        [tenantId]="id"
        [notForNoNeed]="false"
      ></app-clubs-list>
    </div>
  </div>
</div>
