import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, from, map, Observable, of } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Contact } from 'src/app/state/contacts.repository';

import {
  CountriesRepository,
  Country,
} from 'src/app/state/countries.repository';
import { CountriesService } from 'src/app/state/countries.service';
import { League, LeagueRepository } from 'src/app/state/leagues.repository';
import { LeaguesService } from 'src/app/state/leagues.service';
import {
  PlayerLevel,
  PlayerLevelsRepository,
} from 'src/app/state/playerlevels.repository';
import { PlayerLevelsService } from 'src/app/state/playerlevels.service';
import { PlayersRepository } from 'src/app/state/players.repository';
import { PlayersService } from 'src/app/state/players.service';
import {
  Position,
  PositionsRepository,
} from 'src/app/state/positions.repository';
import { PositionsService } from 'src/app/state/positions.service';
import { Team, TeamsRepository } from 'src/app/state/teams.repository';
import { TeamsService } from 'src/app/state/teams.service';
import { Valuta, ValutasRepository } from 'src/app/state/valutas.repository';
import { ValutasService } from 'src/app/state/valutas.service';

@Component({
  selector: 'app-player-page',
  templateUrl: './player-page.component.html',
  styleUrls: ['./player-page.component.scss'],
})
export class PlayerPageComponent implements OnInit {
  tenantId: string | null = null;
  contact$: Observable<Contact> | null = null;
  contact: Contact | null = null;
  leagues: League[] | null = null;
  teams: Team[] | null = null;
  countries: Country[] | null = null;
  positions: Position[] | null = null;
  playerLevels: PlayerLevel[] | null = null;
  valutas: Valuta[] | null = null;
  id?: string;
  submitErrors: string[] | null = null;
  icon: string = 'bi bi-person-workspace';
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';

  background: string | null = null;

  constructor(
    public repo: PlayersRepository,
    private leaguesService: LeaguesService,
    public leaguesRepo: LeagueRepository,
    private teamsService: TeamsService,
    public teamsRepository: TeamsRepository,
    private countriesService: CountriesService,
    public countriesRepository: CountriesRepository,
    private positionService: PositionsService,
    public positionRepository: PositionsRepository,
    private playerLevelService: PlayerLevelsService,
    public playerLevelRepository: PlayerLevelsRepository,
    private valutasService: ValutasService,
    public valutasRepository: ValutasRepository,
    private activatedRoute: ActivatedRoute,
    private service: PlayersService,
    private urlService: UrlIdResolverService,
    public env: EnvState,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.contact$ = this.urlService.resolve(
      this.activatedRoute.paramMap,
      (id) => this.service.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.tenantId = value.get('tenant?');
        this.loadData(value.get('tenant?')!);
      },
    });
    this.contact$.subscribe((x) => (this.contact = x));
  }

  loadData(id?: string) {
    let isNew = this.id === 'new' ? true : false;
    this.teamsService.load(id, isNew).subscribe({
      next: (value) => {
        this.teams = value?.data;
      },
    });
    this.countriesService.load(id, isNew).subscribe({
      next: (value) => {
        this.countries = value?.data;
      },
    });
    this.positionService.load(id, isNew).subscribe({
      next: (value) => {
        this.positions = value?.data;
      },
    });
    this.playerLevelService.load(id, isNew).subscribe({
      next: (value) => {
        this.playerLevels = value?.data;
      },
    });
    this.valutasService.load(id, isNew).subscribe({
      next: (value) => {
        this.valutas = value?.data;
      },
    });
    this.service.load(id, isNew).subscribe();
    this.leaguesService.load(id, isNew).subscribe({
      next: (value) => {
        this.leagues = value?.data;
      },
    });
  }

  refreshPage() {
    window.location.reload();
  }

  checkPipesInStrings(attr: string | undefined | null) {
    if (attr) {
      return attr.split('||').join('');
    }
    return '-';
  }

  updateContact(contact: Partial<Contact>) {
    this.submitErrors = null;
    if (this.id) {
      let id = this.id;
      this.service.update(this.id, contact).subscribe({
        complete: () => {
          this.service.loadOne(id).subscribe((x) => (this.contact = x));
        },
        error: (data) => (this.submitErrors = data),
      });
      this.router.navigate([`players/${this.tenantId}`]);
    }
  }
}
