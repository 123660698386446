import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface PlayerLevel {
    id: string;
    name: string;    
  }

export const playerLevelsSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' }
  
];

@Injectable({ providedIn: 'root' })
export class PlayerLevelsRepository extends BaseRepository<PlayerLevel> {

  constructor() {
    super('playerlevels', playerLevelsSortOptions);
  }

}