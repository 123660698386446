import { createStore, select, withProps } from "@ngneat/elf";
import { deleteAllEntities, deleteEntities, getEntity, selectAllEntities, selectEntity, upsertEntities, withEntities } from "@ngneat/elf-entities";
import { deleteAllPages, getPaginationData, hasPage, PaginationData, selectCurrentPage, selectCurrentPageEntities, selectPaginationData, setCurrentPage, setPage, skipWhilePageExists, updatePaginationData, withPagination } from "@ngneat/elf-pagination";
import { createRequestsCacheOperator, createRequestsStatusOperator, selectRequestStatus, updateRequestCache, updateRequestsStatus, withRequestsCache, withRequestsStatus } from "@ngneat/elf-requests";
import { combineLatest, map, MonoTypeOperatorFunction } from "rxjs";
import { sortBy, SortOption, SortState } from "src/app/modules/shared/pipes/sort.pipe";

export interface SortProps {
    sortBy: SortState;
}

function buildBasicStore<T extends { id: string }>(
    name: string,
    defaultSort: SortState
) {
    return createStore(
        { name },
        withEntities<T>(),
        withProps<SortProps>({
            sortBy: defaultSort
        }),
        withRequestsCache(),
        withRequestsStatus(),
        withPagination()
    );
}

export class BaseRepository<T extends { id: string }> {

    constructor(
        public name: string,
        public sortOptions: SortOption[],
        private defaultSort?: SortState
    ) { }

    protected store = buildBasicStore<T>(
        this.name,
        this.defaultSort || { parameter: this.sortOptions[0], direction: 'asc' }
    );
    private trackOperator = createRequestsStatusOperator(this.store);
    private skipOperator = createRequestsCacheOperator(this.store);

    sort$ = this.store.pipe(select(state => state.sortBy));
    isLoading$ = this.store.pipe(
        selectRequestStatus(this.store.name),
        map(x => x.value === 'pending')
    );
    isAdding$ = this.store.pipe(
        selectRequestStatus(`${this.store.name}_add`),
        map(x => x.value === 'pending')
    );
    statusOne = (id: string) => this.store.pipe(
        selectRequestStatus(id, { groupKey: this.store.name })
    );
    isLoadingOne = (id: string) => this.statusOne(id).pipe(
        map(x => x.value === 'pending')
    );

    all$ = this.store.pipe(selectAllEntities());
    allSorted$ = combineLatest([this.all$, this.sort$]).pipe(
        map(([list, sort]) => sortBy(list, sort))
    );
    one = (id: string) => this.store.pipe(selectEntity(id));

    page$ = this.store.pipe(selectCurrentPageEntities());
    pageNumber$ = this.store.pipe(selectCurrentPage());
    paginationData$ = this.store.pipe(selectPaginationData());
    canLoadMore$ = this.paginationData$.pipe(
        map(data => data.currentPage < data.lastPage)
    );
    isLastPage$ = this.paginationData$.pipe(
        map(data => data.currentPage === data.lastPage)
    );
    hasPage = (page: number) => this.store.query(hasPage(page));
    setPage = (page: number) => this.store.update(setCurrentPage(page));
    getPaginationData = () => this.store.query(getPaginationData());
    clearPages = () => this.store.update(deleteAllPages());

    setSort(sortBy: SortState): void {
        this.store.update(
            (state) => ({
                ...state,
                sortBy
            })
        );
    }

    getSort = () => this.store.getValue().sortBy;

    set(models: T[]): void {
        this.store.update(
            updateRequestCache(this.store.name),
            upsertEntities(models),
            updateRequestsStatus([this.store.name], 'success')
        );
    }

    getOne(id: string): T | undefined {
        return this.store.query(
            getEntity(id)
        );
    }

    addPage(response: PaginationData & { data: T[] }) {
        const { data, ...paginationData } = response;

        this.store.update(
            upsertEntities(data),
            updatePaginationData(paginationData),
            setPage(
                // this is supposed to be a page number, but TS asks for T['id']
                // type which is probably a bug in the library,
                // so we're ignoring it with 'as any' statement
                paginationData.currentPage as any,
                data.map((c) => c.id)
            ),
            updateRequestsStatus([this.store.name], 'success')
        );
    }

    upsert(model: T): void {
        this.store.update(
            updateRequestCache(model.id),
            upsertEntities([model]),
            updateRequestsStatus([model.id], 'success')
        );
    }

    remove(id: string): void {
        this.store.update(
            updateRequestCache(id),
            deleteEntities(id),
            updateRequestsStatus([id], 'success')
        );
    }

    clear(): void {
        this.store.update(
            deleteAllEntities()
        );
    }

    track(operation = ''): MonoTypeOperatorFunction<any> {
        let key = this.store.name;
        if (operation) {
            key += `_${operation}`;
        }
        return this.trackOperator(key);
    }

    trackOne(id: string): MonoTypeOperatorFunction<any> {
        return this.trackOperator(id);
    }

    skipWhileCached(id?: string): MonoTypeOperatorFunction<any> {
        return this.skipOperator(id || this.store.name);
    }

    skipWhilePageCached(page: number): MonoTypeOperatorFunction<any> {
        return skipWhilePageExists(this.store, page);
    }
}