import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  clubSortOptions,
  ClubsRepository,
  NoneedSort,
} from 'src/app/state/clubs.repository';
import { ClubsService } from 'src/app/state/clubs.service';
import {
  CountriesRepository,
  Country,
} from 'src/app/state/countries.repository';
import { CountriesService } from 'src/app/state/countries.service';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import {
  Transfer,
  TransfersRepository,
} from 'src/app/state/transfers.repository';
import { TransfersService } from 'src/app/state/transfers.service';

@Component({
  selector: 'app-no-need-reg-page',
  templateUrl: './no-need-reg-page.component.html',
  styleUrls: ['./no-need-reg-page.component.scss'],
})
export class NoNeedRegPageComponent implements OnInit {
  sortOptions = clubSortOptions;
  filterForm: FormGroup | null = null;
  id: string | null = null;

  filter: NoneedSort = {
    filter: '',
    countryId: undefined,
    transferId: undefined,
    tenantId: undefined,
    take: 10,
    page: undefined,
  };

  TransferOptions: DatalistOption[] | null = null;
  CountryOptions: DatalistOption[] | null = null;
  isAllLoadedCount: number = 0;

  set transfers(value: Transfer[] | null) {
    if (!value) {
      this.TransferOptions = null;
    } else {
      this.TransferOptions = value.map((x) => ({
        value: x.id,
        label: x?.name,
      }));
    }
  }

  set countries(value: Country[] | null) {
    if (!value) {
      this.CountryOptions = null;
    } else {
      this.CountryOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  constructor(
    public service: ClubsService,
    public repo: ClubsRepository,
    private activatedRoute: ActivatedRoute,
    public tenantRepo: TenantsRepository,
    private formBuilder: FormBuilder,
    private transferService: TransfersService,
    public transferRepo: TransfersRepository,
    private countryService: CountriesService,
    public countryRepo: CountriesRepository
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant?');
        if (this.id) {
          this.filter.tenantId = this.id;
          this.loadData(this.id);
        }
      },
    });
  }

  loadData(tenantId: string | null) {
    if (tenantId) {
      this.countryService.loadAllOptions(tenantId).subscribe((x) => {
        this.countries = x;
        this.isAllLoadedCount += 1;
      });
      this.transferService.loadAllOptions(tenantId).subscribe({
        next: (value) => {
          this.transfers = value;
          this.isAllLoadedCount += 1;
          var transferId = value[0].id;
          if (transferId) {
            this.setdata(transferId);
            this.service.reloadNoneedPageWithFilter(this.filter).subscribe();
          }
        },
      });
    }
  }

  setdata(id: string) {
    var transferId =
      localStorage.getItem(`NoNeedFilterTransferId${this.id}`) ?? id;
    this.filter.transferId = transferId;
    this.setTransferId(transferId);
    this.filter.countryId =
      localStorage.getItem(`NoNeedFilterCountryId${this.id}`) ?? undefined;
    this.filter.filter =
      localStorage.getItem(`NoNeedFilterSearch${this.id}`) ?? '';

    this.filterForm = this.formBuilder.group({
      filter: this.filter.filter ?? null,
      countryId: this.filter.countryId ?? null,
      transferId: [this.filter.transferId, Validators.required],
    });
  }

  setTransferId(id: string) {
    localStorage.setItem('noNeedTransferId', id);
  }

  updateValue(control: string, value: any) {
    const controlObject = this.filterForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  loadedCheck() {
    return this.isAllLoadedCount >= 2;
  }

  setToLocalStorage() {
    localStorage.setItem(
      `NoNeedFilterTransferId${this.id}`,
      this.filter.transferId ?? ''
    );
    localStorage.setItem(
      `NoNeedFilterSearch${this.id}`,
      this.filter.filter ?? ''
    );
    if (this.filter.countryId) {
      localStorage.setItem(
        `NoNeedFilterCountryId${this.id}`,
        this.filter.countryId
      );
    } else {
      localStorage.removeItem(`NoNeedFilterCountryId${this.id}`);
    }
  }

  searchSubmit() {
    if (this.filterForm && !this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }
    const transferId = this.filterForm?.value.transferId;
    this.filter.filter = this.filterForm?.value.filter;
    this.filter.transferId = transferId;
    this.filter.countryId = this.filterForm?.value.countryId;
    this.setToLocalStorage();
    this.setTransferId(transferId);
    this.service.reloadNoneedPageWithFilter(this.filter).subscribe();
  }
}
