import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import {
  Requirement,
  RequirementsRepository,
} from 'src/app/state/requirements.repository';
import { RequirementsService } from 'src/app/state/requirements.service';

@Component({
  selector: 'app-requirement-info-page',
  templateUrl: './requirement-info-page.component.html',
  styleUrls: ['./requirement-info-page.component.scss'],
})
export class RequirementInfoPageComponent implements OnInit {
  requirement$: Observable<Requirement> | null = null;
  tenantId: string | null = null;
  id: string | undefined;
  constructor(
    private requirementService: RequirementsService,
    private activatedRoute: ActivatedRoute,
    public repo: RequirementsRepository,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.requirement$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.requirementService.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.tenantId = value.get('tenant?');
      },
    });
  }
}
