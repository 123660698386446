import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Contact } from 'src/app/state/contacts.repository';

@Component({
  selector: 'app-contact-edit-form',
  templateUrl: './contact-edit-form.component.html',
  styleUrls: ['./contact-edit-form.component.scss']
})
export class ContactEditFormComponent implements OnInit {
  @Input() contact$?: Observable<Contact> | null;
  editedContact?: Contact | null;
  form: FormGroup | null = null;
  file: File | null = null;
 
  @Output() contactSubmit = new EventEmitter<Partial<Contact>>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if(this.form) {
      if((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.picture = this.file;
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if(this.form && this.file) {
      this.form.value.picture = this.file;
    }
  }

  constructor(private formBuilder: FormBuilder) { 
  }

  ngOnInit(): void {
    if(this.contact$) {
      this.contact$.subscribe(x => {
        if(x) {
          this.editedContact = x; 
          this.form = this.formBuilder.group({
            picture: [this.editedContact?.picture],
            description: [this.editedContact?.description || ''],
          });          
        }
      });      
    }
  }  
 
  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.form && this.file) {
      this.form.value.picture = this.file;      
    }

    this.contactSubmit.emit({
      picture: this.form?.value?.picture,
      description: this.form?.value?.description,
    })

    this.form?.markAsUntouched();
  }

}
