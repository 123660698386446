<div class="row text-white mb-1" style="background-color: #2F4A5D; " [style.background-image]="'url(' + (background || DEFAULT_BACKGROUND) + ')'">
  <div *ngIf="contact" class="mt-4 ml-3">
    <div class="col">
      <h3 class="px-2" name="contact">
        {{contact.firstName + " " + contact.lastName}}        
      </h3>      
      <h1 class="h3 m-2 p-0 text-start">
        <figure class="avatar avatar-200 rounded shadow">
          <img *ngIf="contact?.pictureUrl" src="{{env.apiUrl + '/' + contact?.pictureUrl}}">
          <i *ngIf="!contact?.pictureUrl" [ngClass]="['bi', icon]"></i>
        </figure>
      </h1>
    </div>    
  </div>
  <h2 class="mb-4 mt-2 text-muted">Contact Information</h2>
  <div class="col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Sport</label>
    <h5 class="ml-3" name="sport">{{contact?.tenant?.name ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Club</label>
    <h5 class="ml-3" name="club">{{contact?.club?.name ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">E-mail</label>
    <h5 class="ml-3" name="email">{{contact?.email ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Phone Number</label>
    <h5 class="ml-3" name="phoneNumber">{{contact?.phoneNumber ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Account Name</label>
    <h5 class="ml-3" name="accountName">{{contact?.accountName ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Job Title</label>
    <h5 class="ml-3" name="jobTitle">{{contact?.jobTitle ?? '-'}}</h5>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Whatsapp</label>
    <h5 class="ml-3" name="whatsapp">{{contact?.whatsapp ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Twitter</label>
    <h5 class="ml-3" name="twitter">{{contact?.twitter ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Instagram</label>
    <h5 class="ml-3" name="instagram">{{contact?.instagram ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Facebook</label>
    <h5 class="ml-3" name="facebook">{{contact?.facebook ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">TikTok</label>
    <h5 class="ml-3" name="tikTok">{{contact?.tikTok ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Agent1</label>
    <h5 class="ml-3" name="agent1">{{ checkPipesInStrings(contact?.agent1)}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Agent2</label>
    <h5 class="ml-3" name="agent2">{{checkPipesInStrings(contact?.agent2)}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3" >
    <label class="text-muted">Player CV url</label>
    <h5 class="ml-3" style="word-break: break-all;" name="link">{{contact?.playerCVLink ?? '-'}}</h5>
  </div>
  <h2 class="mb-4 mt-2 text-muted">Priorities</h2>
  <div class="row">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ml-3" name="sport">Countries:</h5>
    </div>
    <div *ngFor="let item of contact?.countryPriorityContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ms-3" name="countryPriorityContacts">{{item.name}}</h5>
    </div>
  </div>
  <div class="row">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ml-3" name="sport">Leagues:</h5>
    </div>
    <div *ngFor="let item of contact?.leaguePriorityContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ms-3" name="leaguePriorityContacts">{{item.name}}</h5>
    </div>
  </div>
  <div class="row">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ml-3" name="sport">Teams:</h5>
    </div>
    <div *ngFor="let item of contact?.teamPriorityContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ms-3" name="teamPriorityContacts">{{item.name}}</h5>
    </div>
  </div>
  <h2 class="mb-4 mt-2 text-muted">Exclussions</h2>
  <div class="row">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ml-3" name="sport">Countries:</h5>
    </div>
    <div *ngFor="let item of contact?.countryExclusionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ms-3" name="countryExclusionContacts">{{item.name}}</h5>
    </div>
  </div>

  <div class="row">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ml-3" name="leagues">Leagues:</h5>
    </div>
    <div *ngFor="let item of contact?.leagueExclusionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ms-3" name="leagues">{{item.name}}</h5>
    </div>
  </div>
  <div class="row">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ml-3" name="teams">Teams:</h5>
    </div>
    <div *ngFor="let item of contact?.teamExclusionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
      <h5 class="ms-3" name="teamExclusionContacts">{{item.name}}</h5>
    </div>
  </div>
  <h2 class="mb-4 mt-2 text-muted">Finance Information</h2>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Currency</label>
    <h5 class="ml-3" name="currency">{{contact?.valuta?.name ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Est transfer</label>
    <h5 class="ml-3" name="estTransfer">{{contact?.estTransfer ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Minimum Salary Netto</label>
    <h5 class="ml-3" name="minSalaryNetto">{{contact?.minSalaryNetto ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Minimum Salary Brutto</label>
    <h5 class="ml-3" name="minSalaryBrutto">{{contact?.minSalaryBrutto ?? '-'}}</h5>
  </div>
  <h2 class="mb-4 mt-2 text-muted">Player Information</h2>
  <div class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
    <h5 class="ml-3" name="sport">Level: {{contact?.playerLevel?.name ?? '-'}}</h5>
  </div>
  <div class="col-6 col-md-6 col-lg-1 mb-4 mt-3">
    <h5 class="ml-3" name="sport">Positions:</h5>
  </div>
  <div *ngFor="let item of contact?.positionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
    <div class="col-3 col-md-2 col-lg-1"></div>
    <h5 class="ms-3" name="position">{{item.name}}</h5>
  </div>  
  <h2 class="mb-4 mt-2 text-muted">Personal Information</h2>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">DateOfBirth</label>
    <h5 class="ml-3" name="dateOfBirth">{{(contact?.dateOfBirth | date: 'longDate') ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Nationality</label>
    <h5 class="ml-3" name="nationality">{{ checkPipesInStrings(contact?.nationality) }}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Marital Status</label>
    <h5 class="ml-3" name="maritalStatus">{{contact?.maritalStatus ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Number Of Kids</label>
    <h5 class="ml-3" name="numberOfKids">{{contact?.numberOfKids ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Right | Left</label>
    <h5 class="ml-3" name="rightyLefty">{{contact?.rightyLefty ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Height</label>
    <h5 class="ml-3" name="height">{{contact?.height ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Weight</label>
    <h5 class="ml-3" name="weight">{{contact?.weight ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Elite Prospects</label>
    <h5 class="ml-3" name="eliteProspects">{{contact?.eliteProspects ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Description</label>
    <h5 class="ml-3" name="description">{{contact?.description ?? '-'}}</h5>
  </div>

  <div class="row mb-2 mt-5 ml-2">
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <label class="text-muted">Created At</label>
      <h5 class="ml-3" name="createdAt">{{(contact?.createdAt | date:'longDate') ?? '-'}}</h5>
    </div>
    <div class=" col-md-6 col-lg-4 mb-4 mt-3">
      <label class="text-muted">Updated At</label>
      <h5 class="ml-3" name="updatedAt">{{(contact?.updatedAt | date:'longDate') ?? '-'}}</h5>
    </div>
  </div>
  <h2 class="mb-4 mt-2 text-muted">Contract Information</h2>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Mandate Enable</label>
    <h5 class="ml-3" name="mandate">{{contact?.mandate ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">People in Sport Priority</label>
    <h5 class="ml-3" name="pinsPriority">{{contact?.pinsPriority ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Include In App</label>
    <h5 class="ml-3" name="includeInApp">{{contact?.includeInApp ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Contract Type</label>
    <h5 class="ml-3" name="contractType">{{contact?.contractType ?? '-'}}</h5>
  </div>
  <div class=" col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Role</label>
    <h5 class="ml-3" name="role">{{contact?.role ?? '-'}}</h5>
  </div>
  <div class="col-md-6 col-lg-4 mb-4 mt-3">
    <label class="text-muted">Transfer Market</label>
    <h5 class="ml-3 text-break" name="transferMarket">{{contact?.transferMarket ?? '-'}}</h5>
  </div> 
  <ng-container>
    <ng-container *ngIf="contact?.contracts && contact?.contracts?.length">
      <h2 class="mb-4 mt-2 text-center">Contracts</h2>
      <div class="row primary px-0" style="margin: 0"  >
        <div class="col-md-12 col-lg-4 col-sm-12 text-center primary py-2" [class.active]="clicked === 1" (click)="clicked = 1">
          <label>Player contracts</label>      
        </div>
        <div class="col-md-12 col-lg-4 col-sm-12 text-center primary py-2" [class.active]="clicked === 2" (click)="clicked = 2">
          <label>Rep Contracts</label>      
        </div>
        <div class="col-md-12 col-lg-4 col-sm-12 text-center primary py-2 " [class.active]="clicked === 3" (click)="clicked = 3">
          <label>Other Contracts</label>      
        </div>
      </div>    
    </ng-container>
   <!-- Player Contracts Labels -->
    <div class="row active large-label" style="margin: 0" *ngIf="clicked === 1 && contact && contact.contracts && contact.contracts.length > 0">
      <div class="col-md-2 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">Club</label>      
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">Contract Type</label>      
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">Start Date</label>      
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">End Date</label>      
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">Link</label>      
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3 text-center">      
      </div>
         
    </div>
       <!-- Player Contracts Info -->
    <div class="row active" style="margin: 0"  *ngIf="clicked === 1 && contact && contact.contracts && contact.contracts.length > 0">
      <ng-container *ngVar="getContractsOfName(contact.contracts, 'PlayerContract') as playerContracts">
        <ng-container *ngIf="playerContracts && playerContracts.length > 0">
          <ng-container *ngFor="let contract of playerContracts">
            <div [class.large-label]="!contract?.clubName" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Club</label>
              <h6 class="ml-3" name="playerContractClub">{{contract?.clubName}}</h6>
            </div>
            <div [class.large-label]="!contract?.contractType" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Contract Type</label>
              <h6 class="ml-3" name="playerContractContractType">{{contract?.contractType}}</h6>
            </div>
            <div [class.large-label]="!contract?.startDate" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Start Date</label>
              <h6 class="ml-3" name="playerContractStartDate">{{contract?.startDate | date: 'dd/MM/yyyy'}}</h6>      
            </div>
            <div [class.large-label]="!contract?.endDate" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">End Date</label>
              <h6 class="ml-3" name="playerContractEndDate">{{contract?.endDate | date: 'dd/MM/yyyy'}}</h6>     
            </div>
            <div [class.large-label]="!contract?.link" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label [hidden]="!contract?.link" class="text-muted small-label mb-1">Link</label>
              <a [hidden]="!contract?.link" target="_blank"  (click)="goTo(contract?.link)"   class="btn py-0 btn-link text-white w-100"><h6 class="ml-3"  name="playerContractLink">Link</h6></a>  
            </div>
            <div class="col-md-3 col-lg-2 mb-4  text-center">
              <button class="btn primary" (click)="clickedDetails = 'PlayerContract'; clickedContract = contract; labelContract = '1 Contracts - Player'" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightPlayer" i18n="contractmodal">Details</button>
            </div>
            
          </ng-container>
        </ng-container>
      </ng-container>        
    </div>
       <!-- Rep Contracts Labels  -->
    <div class="row active large-label" style="margin: 0" *ngIf="clicked === 2 && contact && contact.contracts && contact.contracts.length > 0">
      <div class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">Start Date</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">End Date</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">Link</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
        <label class="text-muted">Termination (Months)</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">Comment</label>      
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3 text-center">      
      </div>
    </div>
     <!-- Rep Contracts Info  -->
    <div class="row active" style="margin: 0"  *ngIf="clicked === 2 && contact && contact.contracts && contact.contracts.length > 0">
      <ng-container *ngVar="getContractsOfName(contact.contracts, 'RepContract') as repContracts">
        <ng-container *ngIf="repContracts && repContracts.length > 0">
          <ng-container *ngFor="let contract of repContracts">
            <div [class.large-label]="!contract?.startDate" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Start Date</label>
              <h6 class="ml-3" name="repContractStartDate">{{contract?.startDate | date: 'dd/MM/yyyy'}}</h6>      
            </div>
            <div [class.large-label]="!contract?.endDate" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">End Date</label>
              <h6 class="ml-3" name="repContractEndDate">{{contract?.endDate | date: 'dd/MM/yyyy'}}</h6>     
            </div>
            <div [class.large-label]="!contract?.link" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Link</label>
              <a [hidden]="!contract?.link" target="_blank"  (click)="goTo(contract?.link)"   class="btn py-0 btn-link text-white w-100"><h6 class="ml-3"  name="repContractLink">Link</h6></a>  
            </div>
            <div [class.large-label]="!contract?.termination" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Termination (Months)</label>
              <h6 class="ml-3" name="repContractTermination">{{contract?.termination}}</h6>
            </div>
            <div [class.large-label]="!contract?.comment" class="col-md-3 col-lg-2 mb-4 mt-3  text-center">
              <label class="text-muted small-label mb-1">Comment</label>
              <h6 class="ml-3" name="repContractComment">{{contract?.comment}}</h6>
            </div>
            <div class="col-md-3 col-lg-2 mb-4  text-center">
              <button class="btn primary" (click)="clickedDetails = 'RepContract'; clickedContract = contract; labelContract = '2 Contracts - Rep'" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightRep" i18n="contractmodal">Details</button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>          
    </div>
     <!-- Other Contracts Labels  -->
    <div class="row active large-label" style="margin: 0" *ngIf="clicked === 3 && contact && contact.contracts && contact.contracts.length > 0">
      <div class="col-md-3 col-lg-2 mb-4 mt-3 text-center">        
        <label class="text-muted">Contract Partner</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">Link</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">Start Date</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">End Date</label>      
      </div>
      <div class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
        <label class="text-muted">Type</label>
      </div>
      <div class="col-md-2 col-lg-2 mb-4 mt-3 text-center">      
      </div>
    </div>
    <!-- Other Contracts Info  -->
    <div class="row active" style="margin: 0" *ngIf="clicked === 3 && contact && contact.contracts && contact.contracts.length > 0">
      <ng-container *ngVar="getContractsOfName(contact.contracts, 'OtherContract') as otherContracts">
        <ng-container *ngIf="otherContracts && otherContracts.length > 0">
          <ng-container *ngFor="let contract of otherContracts">          
            <div [class.large-label]="!contract?.contractPartner" class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
              <label class="text-muted small-label mb-1">Contract Partner</label>
              <h6 class="ml-3" name="otherContractContractPartner">{{contract?.contractPartner}}</h6> 
            </div>
            <div [class.large-label]="!contract?.link" class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
              <label class="text-muted small-label mb-1">Link</label>
              <a [hidden]="!contract?.link" target="_blank"  (click)="goTo(contract?.link)"   class="btn py-0 btn-link text-white w-100"><h6 class="ml-3"  name="otherContractLink">Link</h6></a>
            </div>
            <div [class.large-label]="!contract?.startDate" class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
              <label class="text-muted small-label mb-1">Start Date</label>
              <h6 class="ml-3" name="otherContractStartDate">{{contract?.startDate | date: 'dd/MM/yyyy'}}</h6>      
            </div>
            <div [class.large-label]="!contract?.endDate" class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
              <label class="text-muted small-label mb-1">End Date</label>
              <h6 class="ml-3" name="otherContractEndDate">{{contract?.endDate | date: 'dd/MM/yyyy'}}</h6>     
            </div>
            <div [class.large-label]="!contract?.type" class="col-md-3 col-lg-2 mb-4 mt-3 text-center">
              <label class="text-muted small-label mb-1">Type</label>
              <h6 class="ml-3" name="otherContractType">{{contract?.type}}</h6>
            </div>
            <div class="col-md-3 col-lg-2 mb-4 text-center">
              <button class="btn primary" (click)="clickedDetails = 'OtherContract'; clickedContract = contract; labelContract = '3 Contracts - Others'" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightOther" i18n="contractmodal">Details</button>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
  <div *ngIf="whoclicked[0] != null; else NoClicks;">
    <h2 class="mb-4 mt-2 text-muted">Who Clicked On You</h2>

    <div class="d-flex flex-column">
      <table class=" text-center table table-bordered table-hover w-75 ">
        <thead class="text-light">
          <tr>
            <th>Name</th>
            <th>Club</th>
            <th>Clicks</th>
          </tr>
        </thead>
        <tbody *ngFor="let item of whoclicked">
          <tr class="text-light">
            <td>
              <span>{{item.name}}</span>
            </td>
            <td>
              <span>{{item.club}}</span>
            </td>
            <td>
              <span>{{item.clicks}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-template #NoClicks>    
    <div class="col-md-6 col-lg-4 mb-4 mt-3">
      <h2 class="text-muted">Clicked</h2>
      <h6 class="mb-4 mt-2 text-muted">Nobody Clicked On You</h6>
    </div> 
  </ng-template>

  

  <div class="offcanvas offcanvas-end active" id="offcanvasRightPlayer" tabindex="-1"  aria-labelledby="offcanvasRight">
  <div class="offcanvas-header">
    <h5>{{labelContract}} ...</h5>
    <button type="button" class="btn-close btn-close-white text-reset dimmed" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body" *ngIf="clickedContract && clickedDetails === 'PlayerContract'">
    <div class="info">
      <div class="info-sub">Info</div>
    </div>   
    
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Club</h6>
        <span>{{clickedContract.clubName}}</span>
      </div>
    </div> 
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Contract Type</h6>
        <span>{{clickedContract.contractType}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Start Date</h6>
        <span>{{clickedContract.startDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div> 
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>End Date</h6>
        <span>{{clickedContract.endDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Link</h6>
        <a [hidden]="!clickedContract.link" target="_blank"  (click)="goTo(clickedContract.link)" class="btn py-0 btn-link ps-0 text-white"><span class="">Link</span></a>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Buy Out</h6>
        <span>{{clickedContract.buyOut}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Buy Out Date</h6>
        <span>{{clickedContract.buyOutDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Buy Out Comment</h6>
        <span>{{clickedContract.buyOutComment}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Option</h6>
        <span>{{clickedContract.option}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Option Date</h6>
        <span>{{clickedContract.optionDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Option Comment</h6>
        <span>{{clickedContract.optionComment}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Relegation or Promotion</h6>
        <span>{{clickedContract.relegationOrPromotion}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Relegation Comment</h6>
        <span>{{clickedContract.relegationComment}}</span>
      </div>
    </div>     
  </div>
</div>

<div class="offcanvas offcanvas-end active" id="offcanvasRightRep" tabindex="-1" aria-labelledby="offcanvasRightLabelRep">
  <div class="offcanvas-header">
    <h5>{{labelContract}} ...</h5>
    <button type="button" class="btn-close btn-close-white text-reset dimmed" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div *ngIf="clickedContract && clickedDetails === 'RepContract'" class="offcanvas-body" style="margin-top: -1rem;">
    <div class="is-styled info">
      <div class="is-styled info-sub">Info</div>      
    </div>   
    <br>     
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Start Date</h6>
        <span>{{clickedContract.startDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div> 
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>End Date</h6>
        <span>{{clickedContract.endDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Link</h6>
        <a [hidden]="!clickedContract.link" target="_blank"  (click)="goTo(clickedContract.link)" class="btn py-0 btn-link ps-0 text-white"><span class="ml-3">Link</span></a>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Termination (Months)</h6>
        <span>{{clickedContract.termination}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Comment</h6>
        <span>{{clickedContract.comment}}</span>
      </div>
    </div>
  </div>
</div>

<div class="offcanvas offcanvas-end active" id="offcanvasRightOther" tabindex="-1"  aria-labelledby="offcanvasRightLabelOther">
  <div class="offcanvas-header">
    <h5>{{labelContract}} ...</h5>
    <button type="button" class="btn-close btn-close-white text-reset dimmed" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div *ngIf="clickedContract && clickedDetails === 'OtherContract'" class="offcanvas-body" style="margin-top: -1rem;">
    <div class="info">
      <div class="info-sub">Info</div>
    </div>   
    <br>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Contract Partner</h6>
        <span>{{clickedContract.contractPartner}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Link</h6>
        <a [hidden]="!clickedContract.link" target="_blank"  (click)="goTo(clickedContract.link)" class="btn py-0 btn-link ps-0 text-white"><span class="ml-3">Link</span></a>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Start Date</h6>
        <span>{{clickedContract.startDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div> 
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>End Date</h6>
        <span>{{clickedContract.endDate | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>    
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Type</h6>
        <span>{{clickedContract.termination}}</span>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 mt-4">
        <h6>Comment</h6>
        <span>{{clickedContract.comment}}</span>
      </div>
    </div>        
  </div>
</div>


<!--
  <div class="modal fade" id="contractmodal" tabindex="-1" aria-labelledby="contractsmodal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content  active">
        <div class="modal-header">
          <h6 class="modal-title" id="contractmodalLabel" i18n="Title contracts window">{{labelContract}}</h6>
          <button type="button" class="btn-close text-dark" data-bs-dismiss="modal" aria-label="Close" i18n-aria-label="Close label @@close"></button>
        </div>
        <div class="modal-body custom-scroll" *ngIf="clickedContract">
          
          <!-- Player Contracts Labels         
          <div class="form-floating" *ngIf="clickedDetails === 'PlayerContract'">
            <div style="width:auto;overflow-x:scroll;overflow-y:scroll;" class="table-responsive custom-scroll">
                <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3   text-center">
                            <label class="text-muted">Club</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Contract Type</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Start Date</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">End Date</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Link</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Buy Out</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Buy Out Date</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Buy Out Comment</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Option</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Option Date</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Option Comment</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Relegation or Promotion</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Relegation Comment</label>      
                          </div>
                        </th>                        
                      </tr>
                    </thead>
                    <tbody>                      
                      <tr class="primary px-0">                                                 
                        <td class="text-center align-middle">{{clickedContract.clubName}}</td>
                        <td class="text-center align-middle">{{clickedContract.contractType}}</td>
                        <td class="text-center align-middle">{{clickedContract.startDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center align-middle">{{clickedContract.endDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center align-middle">{{clickedContract.link}}</td>
                        <td class="text-center align-middle">{{clickedContract.buyOut}}</td>
                        <td class="text-center align-middle">{{clickedContract.buyOutDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center align-middle">{{clickedContract.buyOutComment}}</td>
                        <td class="text-center align-middle">{{clickedContract.option}}</td>
                        <td class="text-center align-middle">{{clickedContract.optionDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center align-middle">{{clickedContract.optionComment}}</td>
                        <td class="text-center align-middle">{{clickedContract.relegationOrPromotion}}</td>
                        <td class="text-center align-middle">{{clickedContract.relegationComment}}</td>
                      </tr>                              
                    </tbody>
                  </table>
              </div>
          </div>
            <!-- Player Contracts Info 
          
            <!-- Rep Contracts Labels 
            <div class="form-floating" *ngIf="clickedDetails === 'RepContract'">
              <div style="width:auto;overflow-x:scroll;overflow-y:scroll;" class="table-responsive custom-scroll">
                  <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center text-white align-middle">
                            <div class="mb-4 mt-3   text-center">
                              <label class="text-muted">Start Date</label>      
                            </div>
                          </th>
                          <th class="text-center text-white align-middle">
                            <div class="mb-4 mt-3 mx-2  text-center">
                              <label class="text-muted">End Date</label>      
                            </div>
                          </th>
                          <th class="text-center text-white align-middle">
                            <div class="mb-4 mt-3 mx-2  text-center">
                              <label class="text-muted">Link</label>      
                            </div>
                          </th>
                          <th class="text-center text-white align-middle">
                            <div class="mb-4 mt-3 mx-2  text-center">
                              <label class="text-muted">Termination (Months)</label>      
                            </div>
                          </th>
                          <th class="text-center text-white align-middle">
                            <div class="mb-4 mt-3 mx-2  text-center">
                              <label class="text-muted">Comment</label>      
                            </div>
                          </th>                                             
                        </tr>
                      </thead>
                      <tbody>                        
                        <tr class="primary px-0">                                                 
                          <td class="text-center align-middle">{{clickedContract.startDate | date: 'dd/MM/yyyy'}}</td>
                          <td class="text-center align-middle">{{clickedContract.endDate | date: 'dd/MM/yyyy'}}</td>
                          <td class="text-center align-middle">{{clickedContract.link}}</td>
                          <td class="text-center align-middle">{{clickedContract.termination}}</td>
                          <td class="text-center align-middle">{{clickedContract.comment}}</td>                                  
                        </tr>                                
                      </tbody>
                    </table>
                </div>
            </div>
          <!-- Other Contracts Labels 
          <div class="form-floating" *ngIf="clickedDetails === 'OtherContract'">
            <div style="width:auto;overflow-x:scroll;overflow-y:scroll;" class="table-responsive custom-scroll">
                <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3   text-center">
                            <label class="text-muted">Contract Partner</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Link</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Start Date</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">End Date</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Type</label>      
                          </div>
                        </th>
                        <th class="text-center text-white align-middle">
                          <div class="mb-4 mt-3 mx-2  text-center">
                            <label class="text-muted">Comment</label>      
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>                      
                      <tr class="primary px-0">                                                 
                        <td class="text-center align-middle">{{clickedContract.contractPartner}}</td>
                        <td class="text-center align-middle">{{clickedContract.link}}</td>
                        <td class="text-center align-middle">{{clickedContract.startDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center align-middle">{{clickedContract.endDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center align-middle">{{clickedContract.type}}</td>
                        <td class="text-center align-middle">{{clickedContract.comment}}</td>                                
                      </tr>                              
                    </tbody>
                  </table>
              </div>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          
        </div>
      </div>
    </div>
  </div>  -->