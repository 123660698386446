import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Contact, ContactsRepository } from 'src/app/state/contacts.repository';
import { ContactsService } from 'src/app/state/contacts.service';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  tenantId: string | null = null;
  contact$: Observable<Contact> | null = null;
  contact: Contact | null = null;
  id?: string;
  submitErrors: string[] | null = null;
  icon: string = 'bi bi-person-workspace';
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';

  background: string | null = null;

  constructor(
    public repo: ContactsRepository,    
    private activatedRoute: ActivatedRoute,
    private service: ContactsService,
    private urlService: UrlIdResolverService,
    public env: EnvState,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.contact$ = this.urlService.resolve(
      this.activatedRoute.paramMap,
      id => this.service.loadOne(id),
      id => this.repo.one(id),
      id => this.repo.statusOne(id),
      id => this.id = id
    );
    this.activatedRoute.paramMap.subscribe({ 
      next: (value) => {        
        this.tenantId = value.get('tenant?');
      }
    });
    this.contact$.subscribe(x => this.contact = x);
   
}

  refreshPage() {
    window.location.reload();
  }

  updateContact(contact: Partial<Contact>) {
    this.submitErrors = null;
    if(this.id) {
      let id = this.id;
      this.service.updateContact(this.id, contact).subscribe({
        complete: () => {
          this.service.loadOne(id).subscribe(x => this.contact = x);                
        },
        error: data => this.submitErrors = data
      });
      this.router.navigate([`contacts/${this.tenantId}`]);   
    }
  }
}
