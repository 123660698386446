<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
    <div class="row mb-2">
        <div class="col">
            <h6 class="my-1" i18n="Basic info label">Basic Information</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group form-floating mb-3">
            <app-datalist-select id="teamSelect" [options]="teamOptions"
                [(activeValue)]="form.value.team"
                (activeValueChange)="updateValue('team', $event)"
                label="Team" i18n-label="Label Team"></app-datalist-select>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group form-floating mb-3">
            <input formControlName="logo" type="file" class="form-control"
                    placeholder="Club logo" i18n-placeholder="Club logo placeholder @@clubLogo"/>
            <label for="logo" i18n="clubLogo">Club logo</label>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group form-floating pb-3 d-grid mr-3">
                <button type="submit" class="btn btn-md-lg btn-primary shadow-sm" i18n="Save button @@save">Save</button>
          </div>
        </div>
    </div>
</form>