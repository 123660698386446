<h2 class="mb-4 text-primary" *ngIf="id === 'new'" i18n>Add team</h2>
<h2 class="mb-4 text-primary" *ngIf="id !== 'new'" i18n>Edit team</h2>
<div *ngIf="id === 'new' || (team$ | async)" class="row mb-4">
    <app-spinner *ngIf="leagueRepo.isLoading$ | async"></app-spinner>
    <div class="col-12">
        <app-team-create-form
         *ngIf="!(leagueRepo.isLoading$ | async)"
         [editedTeam]="team$ | async" 
         [leagues]="leagues"
         (teamSubmit)="createTeam($event)"></app-team-create-form>
        <h2 *ngIf="leagueRepo.isLoading$ | async"><app-spinner></app-spinner></h2>
        <app-error-alert title="Could not submit the sport profile" i18n-title
         [errors]="submitErrors"></app-error-alert>       
    </div>
</div>