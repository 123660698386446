<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create trailer label">Create transfer</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit trailer label">Edit transfer</h2>
<app-spinner *ngIf="tenantRepo.isLoading$ | async"></app-spinner>
<div *ngIf="(id === 'new') || (transfer$ | async)" class="row mb-4">
    <app-transfer-create-form 
    *ngIf="!(tenantRepo.isLoading$ | async)"
    [editedTransfer]="transfer$ | async"
    [tenants]="tenantRepo.tenants$ | async"
    (transferSubmit)="createTransfer($event)"
    [currentTenant]="tenantId"
    ></app-transfer-create-form>
    <app-error-alert title="Could not submit the transfer info" i18n-title="Could not submit transfer message"
    [errors]="submitErrors"></app-error-alert>
</div>
