<div class="row mt-1">
  <div class="col-12">

    <div *ngIf="d!=null; else elseBlock"  class="form-control">


      <div class="row mb-4">
        <div class="col">
          <h2 class="text-primary">{{ d.name }}</h2>
        </div>
        <div class="col-auto d-flex align-items-center">
          <button routerLink="/template/{{d.id}}" class="btn btn-sm btn-success mr-3">
            <ng-container i18n="@@add">Edit</ng-container>
          </button>
          <div class="btn btn-sm btn-success mr-3 text-white">
            <app-download-pdf [exportFactory]="preview"
                              [filenameExtension]="'.pdf'"
                              [filename]="d.name">
            </app-download-pdf>
          </div>
          <div class="btn btn-sm btn-success mr-3 text-white">
            <app-download-pdf [exportFactory]="downloadpure"
                              [filenameExtension]="'.docx'"
                              [filename]="d.name"
                              [titleOflabel]="'Download template'">
            </app-download-pdf>
          </div>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
    <h3 class="mt-3 text-muted">That Tenant Has No PDFs</h3>
    </ng-template>
  </div>
</div>

