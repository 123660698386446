import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface CreatedAtMatch {
    id: string;
    createdAt: Date;    
  }

export const createdAtMatchesSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:CreatedAt`, property: 'createdAt' }  
];

@Injectable({ providedIn: 'root' })
export class CreatedAtMatchesRepository extends BaseRepository<CreatedAtMatch> {

  constructor() {
    super('createdAtMatches', createdAtMatchesSortOptions);
  }

}