import { Component, Input, OnInit } from '@angular/core';
import { Team } from 'src/app/state/teams.repository';
import { TeamsService } from 'src/app/state/teams.service';

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss']
})
export class TeamsListComponent implements OnInit {
  @Input() entities: Team[] | null = null;
  @Input() tenantId: string | null = null;
  constructor(private service: TeamsService)
  { }

  deleteConfirmation: string | null = null;
 
  ngOnInit(): void {
  }
  handleDeleteClick(id: string) {
    this.service.delete(id, this.tenantId!).subscribe();
  }
}
