import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { AuthRepository } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { TenantFeatures } from 'src/app/state/feature.repository';
import { Tenant, TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { UiRepository } from 'src/app/state/ui.repository';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  features = this.env.features;
  isSync = false;
  isSyncError = false;
  tenants = new Array<Tenant>();
  tenantIds: string[] | null = null;
  tenantIdsForFilter: string[] | null = null;
  tenantsOrdered = new Array<Tenant>();
  isOrderReady: boolean = false;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private tenantService: TenantsService,
    public tenantsRepo: TenantsRepository,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState,
    private http: HttpClient
  ) {
    this.getTenants();
  }

  ngOnInit(): void {}

  getTenants() {
    this.tenantIds = this.repo.getTenantIds();
    if (this.tenantIds && this.tenantIds?.length > 0) {
      this.tenantIds.forEach((x) => {
        this.tenantService.loadOne(x).subscribe();
      });

      this.tenantsRepo.tenants$.subscribe((x) => {
        this.tenantsOrdered = x;
        this.tenants = this.filterMenu(this.tenantsOrdered);
      });
    }
  }

  filterMenu(data: Tenant[] | null) {
    this.tenantIdsForFilter = this.repo.getTenantIds();
    if (data) {
      return data
        ?.filter((x) => this.tenantIdsForFilter?.includes(x.id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    } else {
      return new Array<Tenant>();
    }
  }

  menuclose(tenantId?: string) {
    this.ui.toggleMenuOpened(false, tenantId);
  }

  logout() {
    this.menuclose();
    this.auth.logout().subscribe(() => this.router.navigate(['/signin']));
    return false; // prevent default
  }

  unimpersonate() {
    this.menuclose();
    this.auth.unimpersonate().subscribe(() => {
      this.getTenants();
      this.router.navigate(['/']);
    });
    return false; // prevent default
  }

  filterTenants(tenants?: Tenant[] | null): Tenant[] {
    if (tenants) {
      tenants = tenants.filter((x) => x.id);
      return tenants;
    }
    return [];
  }

  sync() {
    if (this.isSync) {
      return false;
    }
    this.isSyncError = false;
    this.isSync = true;
    this.http.post('/api/sync', null).subscribe({
      complete: () => window.location.reload(),
      error: () => {
        this.isSync = false;
        this.isSyncError = true;
      },
    });
    return false;
  }
}
