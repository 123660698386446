import { Component, OnInit } from '@angular/core';
import { SimpleCardAction } from '../../../shared/components/simple-card/simple-card.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-session-over-state',
  templateUrl: './session-over-state.component.html',
  styleUrls: ['./session-over-state.component.scss']
})
export class SessionOverStateComponent implements OnInit {
  testsLocation = 'localhost:9876';
  actions: SimpleCardAction[] = [
    {
      text: $localize `:Refresh page button:Refresh`,
      routerLink: ['/signin']
    }
  ];

  checker: boolean = true;
  constructor(
   private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.includes("/pins")) {
      this.checker = false;
    }
    if(!localStorage.getItem('refreshing') &&
        window.location.host !== this.testsLocation) {
        localStorage['refreshing'] = true;
        window.location.reload();        
    } else {
        localStorage.removeItem('refreshing');
    }
  }

  refresh($event: Event) {
    $event.preventDefault();
    window.location.reload();
  }

}
