import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseReadService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base-read.service';
import { Club, ClubsRepository, NoneedSort } from './clubs.repository';
import { Observable, of, switchMap, tap } from 'rxjs';
import { BaseService } from './abstract/base.service';
import { SortState } from '../modules/shared/pipes/sort.pipe';
import { PaginationData } from '@ngneat/elf-pagination';

const API = '/api/clubs';

@Injectable({ providedIn: 'root' })
export class ClubsService extends BaseService<Club> {
  constructor(http: HttpClient, repo: ClubsRepository) {
    super(API, http, repo);
  }

  loadForSearch(tenantId?: string, filterDeleted?: boolean) {
    return this.http.get<Club[]>(
      `${this.api}/forSearch/?tenantId=${tenantId}&filterDeleted=${filterDeleted}`
    );
  }

  updateClub(id: string, model: Partial<Club>) {
    return this.http.patch<Club>(`${API}/${id}`, model).pipe(
      tap((club) => this.repo.upsert(club)),
      switchMap((club) => {
        if (model.clubLogo) {
          const formData = new FormData();
          formData.append('file', model.clubLogo);
          return this.http.post<Club>(`${API}/${id}/file`, formData);
        }
        return of(club);
      }),
      this.repo.trackOne(id)
    );
  }

  search(name: string | undefined, id: string) {
    return this.http.get(API + `/search/${name}/${id}`);
  }

  sortNoneedWithFilter(sortBy: SortState, filter: NoneedSort) {
    this.repo.setSort(sortBy);
    return this.reloadNoneedPageWithFilter(filter);
  }

  reloadNoneedPageWithFilter(
    filter: NoneedSort
  ): Observable<PaginationData & { data: Club[] }> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadNoneedPageWithFilter(1, filter);
    }
    return this.loadNoneedPageWithFilter(1, filter);
  }

  loadNoneedPageWithFilter(
    page: number,
    filter: NoneedSort
  ): Observable<PaginationData & { data: Club[] }> {
    const sortOrder = this.repo.getSort();
    filter.page = page;
    filter.sort = sortOrder.parameter.property;
    filter.direction = sortOrder.direction;
    // const query = [
    //   `page=${page}`,
    //   `take=${filter.take}`,
    //   `sort=${sortOrder.parameter.property}`,
    //   `direction=${sortOrder.direction}`,
    //   `filter=${filter?.filter}`,
    //   `transferId=${filter?.transferId ?? null}`,
    //   `countryId=${filter?.countryId ?? null}`,
    //   `tenantId=${tenantId}`,
    // ];
    this.repo.setPage(page);
    return this.http
      .put<PaginationData & { data: Club[] }>(`${this.api}/noneed`, filter)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track()
      );
  }

  sortNoneed(sortBy: SortState, filter: NoneedSort) {
    this.repo.setSort(sortBy);
    return this.reloadNoneedPageWithFilter(filter);
  }
}
