import { Component, OnInit, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Feature, TenantFeatures } from 'src/app/state/feature.repository';
import { Tenant } from 'src/app/state/tenants.repository';

// TODO: Add features if applicable
const FEATURE_NAMES: { [f:string]: string } = {
  [TenantFeatures.Feature1]: 'Feature 1'
};

@Component({
  selector: 'app-tenant-form',
  templateUrl: './tenant-form.component.html',
  styleUrls: ['./tenant-form.component.scss']
})
export class TenantFormComponent implements OnInit {
  @Input() editedTenant?: Tenant | null;
  form?: FormGroup;
  file: File | null = null;

  @Input() set features(value: Feature[] | null) {
    if (!value) {
      this.featureOptions = null;
    } else {
      this.featureOptions = value.map(x => ({
        value: x.id,
        label: FEATURE_NAMES[x.name]
      }));
    }
  }

  @Output() tenantSubmit = new EventEmitter<Partial<Tenant>>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if(this.form) {
      if((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.image = this.file;
      }
    }
  }

  featureOptions: DatalistOption[] | null = null;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedTenant?.name, Validators.required],
      image: [this.editedTenant?.image],
      featureIds: [this.editedTenant?.features?.map(x => x.id)]
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.tenantSubmit.emit({
      name: this.form?.value?.name.toString(),
      image: this.file,
      featureIds: this.form?.value?.featureIds
    });
  }
}
