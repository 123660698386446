<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <li app-model-card *ngFor="let value of entities"
          class="list-group-item"
          [title]="value.firstName + ' ' + value.lastName"            
          [subtitle]="value.role"
          icon="bi bi-person-workspace"
          [date]="value.createdAt"           
          [id]="value.id"
          [image]="value.pictureUrl ? env.apiUrl + '/' + value.pictureUrl : undefined"
          [badge]="value?.club?.name"
          [isEditDisabled]="false" 
          [isDeleteDisabled]="false"          
          [isPlayerViewEnabled]="true"
          [tenantid]="tenantId"
          [tenantName]="tenantName"
          (deleteClick)="deleteConfirmation = value.id"
          routerLinkBase="/players/{{tenantId}}"></li>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete *ngIf="deleteConfirmation"                  
                    (confirm)="handleDeleteClick(deleteConfirmation);deleteConfirmation = null;"
                    (cancel)="deleteConfirmation = null"></app-confirm-delete>