import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getRegistry } from '@ngneat/elf';
import { switchMap, tap } from 'rxjs/operators';
import {
  AuthProps,
  AuthRepository,
  PasswordChangeRequest,
  trackAuthRequestsStatus,
} from './auth.repository';

const API = '/api/auth';

const USER_INDEPENDENT_STORES = ['ui', 'requests'];

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private repo: AuthRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  login(email: string, password: string, isRemembered = false) {
    return this.http
      .post<AuthProps>(API, {
        email: email,
        password: password,
        rememberme: isRemembered,
      })
      .pipe(
        tap((resp) => this.repo.setToken(resp?.token)),
        trackAuthRequestsStatus(this.repo.name)
      );
  }

  loginOffice365Auth(email: string) {
    return this.http
      .post<AuthProps>(`${API}/loginOffice365Auth/`, { email })
      .pipe(
        tap((resp) => this.repo.setToken(resp?.token)),
        trackAuthRequestsStatus(this.repo.name)
      );
  }

  forgot(email: string) {
    return this.http.post<AuthProps>(`${API}/forgot`, { email });
  }

  reset(password: string, id: string, token: string) {
    return this.http.post<AuthProps>(`${API}/reset`, { password, id, token });
  }

  changePassword(dto: PasswordChangeRequest) {
    return this.http.post<void>(`${API}/password`, dto);
  }

  changePasswordAsAdmin(dto: PasswordChangeRequest, userId: string) {
    dto.userId = userId;
    return this.http.post<void>(`${API}/passwordAsAdmin`, dto);
  }

  logout() {
    localStorage.setItem('specialToken', '');
    return this.http.delete(API).pipe(tap(() => this.clearStores()));
  }

  impersonate(id: string) {
    return this.http.post<AuthProps>(`${API}/impersonate`, { id }).pipe(
      tap(() => this.clearStores()),
      tap((resp) => this.repo.setToken(resp?.token)),
      trackAuthRequestsStatus(this.repo.name)
    );
  }

  unimpersonate() {
    return this.http.delete<AuthProps>(`${API}/impersonate`).pipe(
      tap(() => this.clearStores()),
      tap((resp) => this.repo.setToken(resp?.token)),
      trackAuthRequestsStatus(this.repo.name)
    );
  }

  refresh(token?: string) {
    let tokenOut;
    if (token && token.length >= 1) {
      tokenOut = token;
    } else {
      console.log('token not exist');
      tokenOut = 'empty';
    }
    if (
      this.router.url.includes('/pins') ||
      this.router.url.includes('/signin') ||
      this.router.url.includes('/office365auth')
    ) {
      return this.http.get<AuthProps>(`${API}/emptytoken`);
    } else {
      return this.http
        .post<AuthProps>(`${API}/refresh?token=${tokenOut}`, token)
        .pipe(
          tap((resp) => this.repo.setToken(resp?.token)),
          trackAuthRequestsStatus(this.repo.name)
        );
    }
  }

  autoRefresh() {
    let token = localStorage.getItem('specialToken');
    return this.repo.isExpiresSoon$.pipe(switchMap(() => this.refresh(token!)));
  }

  private clearStores(): void {
    getRegistry().forEach((store, key) => {
      if (!USER_INDEPENDENT_STORES.includes(key)) {
        store.reset();
      }
    });
  }
}
