import { Component, Input, OnInit } from '@angular/core';
import { filterEventStoreDefs } from '@fullcalendar/angular';
import { Tenant } from 'src/app/state/tenants.repository';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantListComponent implements OnInit {
  @Input() tenants: Tenant[] | null = null;  
 
  constructor() { }

  ngOnInit(): void {
    
  }
  filterTenants(tenants?: Tenant[] | null) : Tenant[] {
    if(tenants) {
      tenants = tenants.filter(x => x.id);
      return tenants;
    }
    return [];
  }
}
