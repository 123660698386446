import { Component, OnInit } from '@angular/core';
import { Click, PublicPlayer, UnauthorisedRepository } from 'src/app/state/unauthorised.repository';
import { UnauthorisedService } from 'src/app/state/unauthorised.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { empty } from 'rxjs';

@Component({
  selector: 'app-football-player-detail',
  templateUrl: './football-player-detail.component.html',
  styleUrls: ['./football-player-detail.component.scss']
})
export class FootballPlayerDetailComponent implements OnInit {

  contact_id: string | null = null;
  player_id: string | null = null;
  player: PublicPlayer | null = null;
  EntityClick: Click | null = null;
  transferurls: string | undefined;
  link: boolean = true;
  constructor(
    public service: UnauthorisedService,
    private router: Router,
    public repo: UnauthorisedRepository,
    private activatedRoute: ActivatedRoute,
    public env: EnvState) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.contact_id = value.get('contact_id');
        this.player_id = value.get('player_id');
        var ClientId = `${this.contact_id}`;
        var PlayerID = `${this.player_id}`;

        this.service.new_click({ ClientId, PlayerID }).subscribe();

        this.service.loadPlayer(`${this.player_id}`).subscribe(x => { this.player = x; this.transferurls = this.transferfilter(this.player?.transferMarket) })
      }
    });

  }

  transferfilter(router: string) {
    this.link=router.match(/^ *$/) == null;
    if (router != null && router.match(/^ *$/) == null) {
      if (router.slice(0, 4) != "http" && router.slice(0, 2) != "//") {
        router = "//" + router;
      }

    }
    return router

  }

  

  checkPipesInStrings(attr: string | undefined | null) {
    if(attr) {
      return attr.split("||").join("");
    }
    return "";
  }
    
}
