import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { League, LeagueRepository } from 'src/app/state/leagues.repository';
import { LeaguesService } from 'src/app/state/leagues.service';
import { Team, TeamsRepository } from 'src/app/state/teams.repository';
import { TeamsService } from 'src/app/state/teams.service';

@Component({
  selector: 'app-team-create-page',
  templateUrl: './team-create-page.component.html',
  styleUrls: ['./team-create-page.component.scss']
})
export class TeamCreatePageComponent implements OnInit {
  team$: Observable<Team> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;
  tenantId: string | null = null;
  leagues: League[] | null = null;

  constructor(
    private teamsService: TeamsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: TeamsRepository,
    private urlId: UrlIdResolverService,    
    private auth: AuthRepository,
    public leagueRepo: LeagueRepository,
    private leagueService: LeaguesService
  ) { 
    
  }

    ngOnInit(): void {      
      this.team$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.teamsService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
      this.activatedRoute.paramMap.subscribe({ 
        next: (value) => {        
          this.tenantId = value.get('tenant?');
          this.loadData(value.get('tenant?')!);
        }
      });      
    }

    loadData(id: string) {      
      let isNew = this.id === 'new' ? true : false; 
      this.leagueService.load(id, isNew).subscribe({
         next: (value) => {        
           this.leagues = value?.data;
         }    
         });
      }

    createTeam(team: Partial<Team>) {
      this.submitErrors = null;
      let updateResult: Observable<Team> | undefined;
      if(this.id === 'new') {
        if(this.tenantId) {
          team.tenantId = this.tenantId;
        }
        updateResult = this.teamsService.add(team, this.tenantId!);

      } else if(this.id) {
        if(this.tenantId) {
          team.tenantId = this.tenantId;
        }
        updateResult = this.teamsService.update(this.id , team);
      }
      if (updateResult) {       
        updateResult.subscribe({          
          complete: () => {
            this.router.navigate(['/teams/', this.tenantId]);
          },
          error: data => this.submitErrors = data
        });
      }
    }

}
