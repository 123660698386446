import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { skipWhileCached } from "@ngneat/elf-requests/lib/requests-cache";
import { combineLatest, map, Observable, of, switchMap, tap } from "rxjs";
import { CreatedAtMatch, CreatedAtMatchesRepository } from "./dates.repository";
import { IPreview, PDFTemplate, PDFTemplateRepository } from "./PDFTemplate.repository";

const API = '/api/PlayerCV';

@Injectable({ providedIn: 'root' })
export class PDFTemplateService {
  constructor(private http: HttpClient, private repo: PDFTemplateRepository,
    private dateRepo: CreatedAtMatchesRepository) { }

  load(tenant_id:string) {
    return this.http.get<any>(`${API}/pdftemplate/${tenant_id}`)
  }
  loadOne(id: string) {
    return this.http.get<any>(`${API}/edit/${id}`)
  }
  edit(id: string, PDFTemplate: any) {
    return this.http.put<any>(`${API}/edit/${id}`, PDFTemplate)
  }
  saveFile(id: string, file: File|null=null) {

    if (file != null) {
      const formData = new FormData();
      formData.append("file", file);
      return this.http.post<any>(`${API}/${id}/file`, formData)
    }
    return null
  }

  preview(tenant_id: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<Blob>(`${API}/${tenant_id}`, { headers: headers, responseType: 'blob' as any }).pipe(
      map(resp => {        
        const blob = new Blob([resp], { type: "application/pdf" });
        return window.URL.createObjectURL(blob);
      })
    );
  }

  download(tenant_id: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<Blob>(`${API}/puretemplate/${tenant_id}`, { headers: headers, responseType: 'blob' as any }).pipe(
      map(resp => {  
        const blob = new Blob([resp], { type: resp.type });
        return window.URL.createObjectURL(blob);
      })
    );
  }

}
