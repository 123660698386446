import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PDFTemplate , PDFTemplateRepository} from 'src/app/state/PDFTemplate.repository';
import { PDFTemplateService } from 'src/app/state/PDFTemplate.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-pdf-card',
  templateUrl: './pdf-card.component.html',
  styleUrls: ['./pdf-card.component.scss']
})
export class PdfCardComponent implements OnInit {

  constructor(private service: PDFTemplateService,
    private repo: PDFTemplateRepository,
    private activatedRoute: ActivatedRoute,
  ) {

    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant?');
        this.service.load(`${ this.id }`).subscribe(x => { this.d = x; });
      }
    }); 

  }
  id: string | null = null;
  d: PDFTemplate | null = null;
  ngOnInit(): void {
  }
  preview = () => this.service.preview(this.id!);
  downloadpure = () => this.service.download(this.id!);


}
