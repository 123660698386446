<div class="row mb-2">
  <div class="col mb-2">
    <h1 [class.handballwomen]="(tenantRepo.tenant(id!) | async)?.name?.includes('Handball')">Teams [{{(tenantRepo.tenant(id!) | async)?.name}}]</h1>
  </div>
  <div *ngIf="auth.isAnyAdmin$ | async" class="col-auto d-flex align-items-center">
    <!--<app-import-export filenamePrefix="teams"
      [exportFactory]="exportFactory"></app-import-export>-->
    <a routerLink="/teams/{{id}}/new" class="btn btn-sm btn-success">
      <i class="bi bi-plus"></i> <ng-container i18n="@@add">Add</ng-container>
    </a>
  </div>
  <app-filter-string (filterEmiter)=searchSubmit($event)>
  </app-filter-string>
</div>

<div class="row mb-4">
  <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
    <app-pagination [page]="(repo.pageNumber$ | async) || 1"
                    [total]="(repo.paginationData$ | async)?.lastPage || 0"
                    (pageChange)="service.loadPageWithFilter($event, 10, id!,filter).subscribe()"></app-pagination>
  </div>
  <div class="col-12 col-md-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions"
                       [sorting]="repo.sort$ | async"
                       (sortingChange)="service.sortWithFilter($event, id!,filter).subscribe()"></app-sort-dropdown>
  </div>
</div>
<div class="row mb-4">
    <div class="col">
      <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      <app-teams-list [entities]="repo.page$ | async"
      [tenantId]="id"></app-teams-list>
    </div>
</div>
