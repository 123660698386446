import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { UserRoles } from './auth.repository';
import { BaseRepository } from './abstract/base.repository';

export interface User {
  id: string;
  email: string;
  phoneNumber: string;
  name: string;
  surname: string;
  title: string;
  location: string;
  birthday?: Date;
  tenantIds?: string[];
  createdAt: Date;
  image: File | null;
  imagePath: string;
  roles: UserRoles[];
}

export const userSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Surname:Surname`, property: 'surname' },
  { label: $localize `:Sort label Date:Date`, property: 'createdAt' }
];

@Injectable({ providedIn: 'root' })
export class UsersRepository extends BaseRepository<User> {

  constructor() {
    super('users', userSortOptions);
  }

}