import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CreatedAtMatch,
  CreatedAtMatchesRepository,
} from 'src/app/state/dates.repository';
import {
  Match,
  MatchCreate,
  MatchesRepository,
} from 'src/app/state/matches.repository';
import { MatchesService } from 'src/app/state/matches.service';
import { Requirement } from 'src/app/state/requirements.repository';
import { RequirementsService } from 'src/app/state/requirements.service';

@Component({
  selector: 'app-requirements-list',
  templateUrl: './requirements-list.component.html',
  styleUrls: ['./requirements-list.component.scss'],
})
export class RequirementsListComponent implements OnInit {
  @Input() tenantId: string | null = null;
  readonly CLUB = $localize`:Label for club of requirement:Club: `;
  readonly POSITIONS = $localize`:Label for Positions of requirement:Positions: `;
  readonly LEVELS = $localize`:Label for levels of levels:Levels:`;
  readonly DESCRIPTION = $localize`:Label for Description of requirement:Description: `;
  dates?: CreatedAtMatch[];
  submitErrors: string[] | null = null;
  @Input() entities: Requirement[] | null = null;
  deleteConfirmation: string | null = null;

  constructor(
    private service: RequirementsService,
    private matchService: MatchesService,
    private matchRepo: MatchesRepository,
    private router: Router
  ) {}

  ngOnInit(): void {}

  handleDeleteClick(id: string) {
    this.service.delete(id).subscribe();
  }

  matching(id: string) {
    let dto = new MatchCreate();
    let updateResult: Observable<Match> | undefined;
    dto.requirementId = id;
    updateResult = this.matchService.create(dto);
    updateResult.subscribe({
      next: (value) => {
        if (value) {
          this.matchRepo.one(value.id);
          this.matchRepo.statusOne(value.id);
          this.matchRepo.upsert(value);
          localStorage.setItem('storedMatchId', value.id);
        }
      },
      complete: () => {
        this.router.navigate([`/matches/${id}`]);
      },
      error: (data) => (this.submitErrors = data),
    });
  }

  viewing(id: string) {
    localStorage.setItem('storedMatchId', '');
    this.router.navigate([`/matches/${id}`]);
  }
}
