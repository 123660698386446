import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { League } from './leagues.repository';

export interface Team {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  league: League;
  leagueId: string;
  tenantId: string;
  isBeenUsed: boolean;
}

export const teamsSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Name:CreatedAt`, property: 'createdAt' }  
];

@Injectable({ providedIn: 'root' })
export class TeamsRepository extends BaseRepository<Team> {

  constructor() {
    super('teams', teamsSortOptions);
  }

}
