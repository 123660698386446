import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(
    public repo: AuthRepository
  ) { }

  ngOnInit(): void {
  }

}
