<div class="btn-group" role="group" >
  <button *ngIf="pages.length > 5"
          class="btn btn-outline-primary"
          [class.disabled]="page === pages[0]"
          (click)="pageChange.emit(pages[0])">
    &laquo;
  </button>
  <button *ngIf="pages.length > 5"
          class="btn btn-outline-primary"
          [class.disabled]="page === pages[0]"
          (click)="pageChange.emit(page - 1)">
    &lsaquo;
  </button>
  <button *ngFor="let pageNumber of calculatePages()"
          class="btn"
          [class.btn-outline-primary]="page !== pageNumber"
          [class.btn-primary]="page === pageNumber"
          (click)="pageChange.emit(pageNumber)">
    {{ pageNumber }}
  </button>
  <button *ngIf="pages.length > 5"
          class="btn btn-outline-primary"
          [class.disabled]="page === pages[pages.length-1]"
          (click)="pageChange.emit(page + 1)">
    &rsaquo;
  </button>
  <button *ngIf="pages.length > 5"
          class="btn btn-outline-primary"
          [class.disabled]="page === pages[pages.length-1]"
          (click)="pageChange.emit(pages[pages.length-1])">
    &raquo;
  </button>
</div>
