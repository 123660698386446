import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Valuta } from 'src/app/state/valutas.repository';

@Component({
  selector: 'app-valutas-edit-form',
  templateUrl: './valutas-edit-form.component.html',
  styleUrls: ['./valutas-edit-form.component.scss']
})
export class ValutasEditFormComponent implements OnInit {
  @Input() editedValuta: Valuta | null = null;  
  form?: FormGroup;
  

  @Output() valutaSubmit = new EventEmitter<Partial<Valuta>>();
  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({      
      value: [this.editedValuta?.value]      
    });
  }
  
  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.valutaSubmit.emit({
      value: this.form?.value?.value      
    });
  }

}
