<div class="form-group form-floating">
  <ng-select class="form-control" [labelForId]="id" [multiple]="multiple"
             [(ngModel)]="activeValue" (ngModelChange)="activeValueChange.emit($event)">
    <ng-option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}<br>
      <span class="small text-muted">{{ option.sublabel }}</span>
    </ng-option>
  </ng-select>
  <label *ngIf="label" class="form-control-label" [attr.for]="id">{{ label }}</label>
</div>
