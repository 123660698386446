import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface Valuta {
    id: string;
    name: string;
    value: number;
    updatedAt: Date;
    tenantId: string;
  }

export const valutasSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Name:Value`, property: 'value' }  
];

@Injectable({ providedIn: 'root' })
export class ValutasRepository extends BaseRepository<Valuta> {

  constructor() {
    super('valuta', valutasSortOptions);
  }

}