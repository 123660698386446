<div>
    <app-spinner *ngIf="!match"></app-spinner>
    <app-matches-card *ngIf="match"
    (matchReload)="reloadMatch($event)"
    (matchSender)="sendToAc($event, true)"
    (matchSenderInfo)="sendToAc($event, false)"
    [match]="match"
    [hasBeenSent]="hasBeenSent"
    [createdAtMatchId]="createdAtMatchId"
    [dates]="datesRepo.all$ | async">
    </app-matches-card>    
</div>
