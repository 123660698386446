import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeParagraphTags',
})
export class RemoveParagraphTagsPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) {
      return '';
    }
    return value.replace(/<\/?p[^>]*>/g, '');
  }
}
