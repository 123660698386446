import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Contact } from './contacts.repository';
import { Team } from './teams.repository';

export interface Club {
  id: string;
  activeCampaignId: number;
  name: string;
  url: string;
  owner: string;
  createdAt: Date;
  updatedAt: Date;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phoneNumber: string;
  taxNumber: string;
  tenantName: string;
  description: string;
  numberOfEmployees: string;
  annualRevenue: string;
  industry: string;
  email: string;
  fax: string;
  twitter: string;
  instagram: string;
  teamId: string;
  team: Team;
  clubLogo: File | null;
  clubLogoUrl: string;
  contacts: Contact[];
}

export interface NoneedSort {
  filter?: string;
  transferId?: string;
  countryId?: string;
  page?: number;
  take?: number;
  sort?: string;
  direction?: string;
  tenantId?: string;
}

export const clubSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  { label: $localize`:Sort label Date:Date`, property: 'createdAt' },
];

@Injectable({ providedIn: 'root' })
export class ClubsRepository extends BaseRepository<Club> {
  constructor() {
    super('club', clubSortOptions);
  }
}
