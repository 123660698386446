<div class="row mb-2">
  <div class="col d-flex align-items-center">
    <h1
      [class.handballwomen]="
        (tenantRepo.tenant(id!) | async)?.name?.includes('Handball')
      "
    >
      Requirements [{{ (tenantRepo.tenant(id!) | async)?.name }}]
    </h1>
  </div>
  <div *ngIf="auth.isUser$ | async" class="col-auto d-flex align-items-center">
    <a routerLink="/requirements/{{ id }}/new" class="btn btn-sm btn-success">
      <i class="bi bi-plus"></i> <ng-container i18n="@@add">Add</ng-container>
    </a>
  </div>
</div>
<app-spinner *ngIf="!loadedCheck()"></app-spinner>
<div *ngIf="loadedCheck()">
  <app-sort-table
    [page]="(repo.pageNumber$ | async) || 1"
    [total]="(repo.paginationData$ | async)?.lastPage || 0"
    [clubs]="clubOptions"
    [tenantId]="id"
    [filterRequirement]="reqFilter"
    [contractStatus]="contractStatusOptions"
    [transfers]="transferOptions"
    [positions]="positionOptions"
    [countries]="countryOptions"
    [playersLevel]="playerLevelOptions"
    [activeTransfer]="activeTransfer"
    [sort]="repo.sort$ | async"
    (filterEmmiter)="loadDataOnDemand($event)"
    (sortEmmiter)="updateSort($event)"
  >
  </app-sort-table>
  <div class="row mb-4">
    <div class="col">
      <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      <app-requirements-list
        [tenantId]="id"
        [entities]="repo.page$ | async"
      ></app-requirements-list>
    </div>
  </div>
</div>
