import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Club } from 'src/app/state/clubs.repository';
import { League } from 'src/app/state/leagues.repository';
import { Team } from 'src/app/state/teams.repository';

@Component({
  selector: 'app-club-edit-form',
  templateUrl: './club-edit-form.component.html',
  styleUrls: ['./club-edit-form.component.scss']
})
export class ClubEditFormComponent implements OnInit {
  @Input() club$?: Observable<Club> | null;
  editedClub?: Club | null;
  form: FormGroup | null = null;
  file: File | null = null;
  teamOptions: DatalistOption[] | null = null;

  @Input() set teams(value: Team[] | null) {
    if (!value) {
      this.teamOptions = null;
    } else {
      this.teamOptions = value.map(x => ({
        value: x.id,
        label: `${x.name} - ${x.league?.name ?? ""} - ${x.league?.countryName ?? ""}`
      }));
    }
  }

  @Output() clubSubmit = new EventEmitter<Partial<Club>>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if(this.form) {
      if((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.logo = this.file;
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
    if(this.form && this.file) {
      this.form.value.logo = this.file;
    }
  }

  constructor(private formBuilder: FormBuilder) { 
    
  }

  ngOnInit(): void {
    if(this.club$) {
      this.club$.subscribe(x => {
        if(x) {
          this.editedClub = x; 
          this.form = this.formBuilder.group({
            team: [this.editedClub?.teamId || ''],
            logo: [this.editedClub?.clubLogo]
          });         
        }
      });
    }    
  }  
 
  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if(this.form && this.file) {
      this.form.value.picture = this.file;      
    }

    this.clubSubmit.emit({
      teamId: this.form?.value?.team || null,
      clubLogo: this.form?.value?.logo
    })

    this.form?.markAsUntouched();
  }
}
