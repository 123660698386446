import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { AbstractControl, FormBuilder, FormGroup, NG_ASYNC_VALIDATORS, Validators } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Transfer, TransfersRepository } from 'src/app/state/transfers.repository';
import { Club, ClubsRepository } from 'src/app/state/clubs.repository';
import { Position, PositionsRepository } from 'src/app/state/positions.repository';
import { PlayerLevel, PlayerLevelsRepository } from 'src/app/state/playerlevels.repository';
import { ContractStatus, ContractStatusesRepository } from 'src/app/state/contractstatuses.repository';
import { RequirementsFilter, RequirementsRepository, requirementsSortOptions } from 'src/app/state/requirements.repository';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { Country } from 'src/app/state/countries.repository';

@Component({
  selector: 'app-sort-table',
  templateUrl: './sort-table.component.html',
  styleUrls: ['./sort-table.component.scss']
})
export class SortTableComponent implements OnInit {
  @Input() page: number = 1;
  @Input() total: number = 0;
  @Input() sort: SortState | null = null;  
  @Input() tenantId: string | null = null;
  @Input() filterRequirement: RequirementsFilter | null = null;
  @Input() activeTransfer: Transfer | null = null;

  sortOptions = requirementsSortOptions;
  filterForm: FormGroup| null = null;
  TransferOptions: DatalistOption[] | null = null;
  CountryOptions: DatalistOption[] | null = null;
  ClubOptions: DatalistOption[] | null = null;
  PositionOptions: DatalistOption[] | null = null;
  PlayerLevelOptions: DatalistOption[] | null = null;
  ContractStatusOptions: DatalistOption[] | null = null;  
  filterSearch = true; 
  TransferOptionActiveId: string | null = null;
  ClubOptionsActiveIds: string[] | null = null;
  PositionOptionsActiveIds: string[] | null = null ;
  ContractStatusOptionsActiveIds: string[] | null = null;
  PlayerLevelOptionsActiveIds: string[] | null = null;

  @Output() filterEmmiter = new EventEmitter<Partial<RequirementsFilter>>();
  @Output() sortEmmiter = new EventEmitter<SortState>();
  
 
  @Input() set clubs(value: Club[] | null) {
    if (!value) {
      this.ClubOptions = null;
    } else {      
      this.ClubOptions = value.map(x => ({
        value: x.id,
        label: x.name
      }));
    }
  }
  @Input() set transfers(value: Transfer[] | null) {
    if (!value) {
      this.TransferOptions = null;
    } else {      
      this.TransferOptions = value.map(x => ({
        value: x.id,
        label: x?.name
      }));
    }
  } 
  @Input() set positions(value: Position[] | null) {
    if (!value) {
      this.PositionOptions = null;
    } else {
      this.PositionOptions = value.map(x => ({
        value: x.id,
        label: x.name
      }));
    }
  }
  @Input() set playersLevel(value: PlayerLevel[] | null) {
    if (!value) {
      this.PlayerLevelOptions = null;
    } else {
      this.PlayerLevelOptions = value.map(x => ({
        value: x.id,
        label: x.name
      }));
    }
  }

  @Input() set countries(value: Country[] | null) {
    if (!value) {
      this.CountryOptions = null;
    } else {
      this.CountryOptions = value.map(x => ({
        value: x.id,
        label: x.name
      }));
    }
  }
  @Input() set contractStatus(value: ContractStatus[] | null) {
    if (!value) {
      this.ContractStatusOptions = null;
    } else {
      this.ContractStatusOptions = value.map(x => ({
        value: x.id,
        label: x.name
      }));
    }
  }

  constructor(private formBuilder: FormBuilder) {    
  }

  ngOnInit(): void {   
    this.filterForm = this.formBuilder.group({
      transferId: [ this.filterRequirement?.transferId ?  this.filterRequirement?.transferId : this.activeTransfer?.id],
      clubIds: [ this.filterRequirement?.clubIds ],
      positionIds: [ this.filterRequirement?.positionIds ],
      countryIds: [ this.filterRequirement?.countryIds ],
      playerLevelIds: [ this.filterRequirement?.playerLevelIds ],
      contractStatusIds: [ this.filterRequirement?.contractStatusIds ],
      tenantId: [ this.filterRequirement?.tenantId ],
      sort: [ this.filterRequirement?.sort],
      direction: [ this.filterRequirement?.direction],
      page: [ this.filterRequirement?.page ],
      isFilterSearch: this.filterSearch
    })
  }

  updateValue(control: string, value: any) {
    const controlObject = this.filterForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  updateSort(parameterControl: string, directionControl: string, value: SortState) {
    this.sortEmmiter.emit(value);    
    const parameterControlObject = this.filterForm?.get(parameterControl);
    parameterControlObject?.setValue(value.parameter.property);
    parameterControlObject?.markAsTouched();
    const directionControlObject = this.filterForm?.get(directionControl);
    directionControlObject?.setValue(value.direction);
    directionControlObject?.markAsTouched();
  }

  changeFilterSearch() {
    if (!this.filterSearch) {
      this.filterSearch = true;
    }
    else {
      this.filterSearch = false;
    }
  }

  emitOnDemand() {
    this.filterEmmiter.emit({
      transferId: this.filterForm?.value?.transferId,
      clubIds: this.filterForm?.value?.clubIds,
      positionIds: this.filterForm?.value?.positionIds,
      playerLevelIds: this.filterForm?.value?.playerLevelIds,
      contractStatusIds: this.filterForm?.value?.contractStatusIds,
      countryIds: this.filterForm?.value?.countryIds,
      tenantId: this.tenantId,
      sort: this.filterForm?.value?.sort,
      direction: this.filterForm?.value?.direction,
      page: this.filterForm?.value?.page,
      take: 10
    });
  }

  resetForm() {
    this.filterForm = this.formBuilder.group({
      transferId: [null],
      clubIds: [null],
      positionIds: [null],
      playerLevelIds: [null],
      countryIds:[null],
      contractStatusIds: [null],
      tenantId: [this.filterRequirement?.tenantId],
      sort: [this.filterRequirement?.sort],
      direction: [this.filterRequirement?.direction],
      page: [this.filterRequirement?.page],
      isFilterSearch: this.filterSearch
    });
  }

  resetFilter() {
    this.resetForm();
  }

  onSubmit() {
    if (this.filterForm && !this.filterForm.valid) {
      this.filterForm.markAllAsTouched();
      return;
    }
    this.emitOnDemand()
    this.filterForm?.markAsUntouched();
  }

}
