import { Component, Input, OnInit } from '@angular/core';
import { Tenant } from 'src/app/state/tenants.repository';
import { Transfer } from 'src/app/state/transfers.repository';
import { TransfersService } from 'src/app/state/transfers.service';

@Component({
  selector: 'app-transfers-list',
  templateUrl: './transfers-list.component.html',
  styleUrls: ['./transfers-list.component.scss']
})
export class TransfersListComponent implements OnInit {
  @Input() entities: Transfer[] | null = null;
  @Input() tenants: Tenant[] | null = null;
  @Input() tenantId: string | null = null;
  deleteConfirmation: string | null = null;
  constructor(private service: TransfersService) { }

  ngOnInit(): void {
  }

  handleDeleteClick(id: string) {
    this.service.delete(id, this.tenantId!).subscribe();
  }

}
