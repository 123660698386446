import { Component, Input, OnInit } from '@angular/core';
import { Tenant } from 'src/app/state/tenants.repository';
import { User } from 'src/app/state/users.repository';
import { UserRoles } from '../../../../state/auth.repository';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit {
  @Input() users: User[] | null = null;
  @Input() tenants: Tenant[] | null = null;
  @Input() authUserRoles: string[] | null = null;
  
  constructor() { }

  ngOnInit(): void {
  }

  canEditUser(userRoles: string[]) {
    return this.authUserRoles?.includes(UserRoles.Superadmin) ||
      this.authUserRoles?.includes(UserRoles.TenantAdmin) && !userRoles.includes(UserRoles.Superadmin);
  }

}
