<app-spinner
    *ngIf="(repo.isLoading$ | async) ||
           (leaguesRepo.isLoading$ | async) ||
           (teamsRepository.isLoading$ | async) || 
           (countriesRepository.isLoading$ | async) ||
           (positionRepository.isLoading$ | async) || 
           (playerLevelRepository.isLoading$ | async) ||
           (valutasRepository.isLoading$ | async)"
></app-spinner>
<app-player-edit-form 
                    *ngIf="!(repo.isLoading$ | async) &&
                    !(leaguesRepo.isLoading$ | async) &&
                    !(teamsRepository.isLoading$ | async) &&
                    !(countriesRepository.isLoading$ | async) &&
                    !(positionRepository.isLoading$ | async) && 
                    !(playerLevelRepository.isLoading$ | async) &&
                    !(valutasRepository.isLoading$ | async)"
                    [contact$]="contact$"                    
                    [leaguePriorities]="leagues"
                    [leagueExclusions]="leagues"
                    [teamPriorities]="teams"
                    [teamExclusions]="teams"
                    [positions]="positions"
                    [playerLevels]="playerLevels"
                    [countryPriorities]="countries"
                    [countryExclusions]="countries"
                    [valutas]="valutas"
                    (contactSubmit)="updateContact($event)"></app-player-edit-form>
<app-error-alert title="Could not submit the contact" i18n-title="Contact submit error alert"
         [errors]="submitErrors"></app-error-alert>
         <div *ngIf="!(repo.isLoading$ | async) ||
                    !(leaguesRepo.isLoading$ | async) ||
                    !(teamsRepository.isLoading$ | async) || 
                    !(countriesRepository.isLoading$ | async) ||
                    !(positionRepository.isLoading$ | async) || 
                    !(playerLevelRepository.isLoading$ | async) ||
                    !(valutasRepository.isLoading$ | async)"
          class="row text-white mb-1" style="background-color: #2F4A5D;" [style.background-image]="'url(' + (background || DEFAULT_BACKGROUND) + ')'">    
            <div *ngIf="contact" class="mt-4 ml-3">       
                    <div class="col"> 
                        <h3 class="px-2" name="contact">{{contact?.firstName + " " + contact?.lastName}}                    
                        </h3>    
                            <h1 class="h3 m-2 p-0 text-start">
                                <figure class="avatar avatar-200 rounded shadow">
                                    <img *ngIf="contact?.pictureUrl" src="{{env.apiUrl + '/' + contact.pictureUrl}}" >
                                    <i *ngIf="!contact?.pictureUrl" [ngClass]="['bi', icon]"></i>
                                </figure>
                             </h1>
                    </div>
            </div>
            <h2 class="mb-4 mt-2 text-muted">Contact Information</h2>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Sport</label>
                <h5 class="ml-3" name="sport">{{contact?.tenant?.name ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Club</label>
                <h5 class="ml-3" name="club">{{contact?.club?.name ?? '-'}}</h5>
            </div>        
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">E-mail</label>
                <h5 class="ml-3" name="email">{{contact?.email ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Phone Number</label>
                <h5 class="ml-3" name="phoneNumber">{{contact?.phoneNumber ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Account Name</label>
                <h5 class="ml-3" name="accountName">{{contact?.accountName ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Job Title</label>
                <h5 class="ml-3" name="jobTitle">{{contact?.jobTitle ?? '-'}}</h5>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Whatsapp</label>
                <h5 class="ml-3" name="whatsapp">{{contact?.whatsapp ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Twitter</label>
                <h5 class="ml-3" name="twitter">{{contact?.twitter ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Instagram</label>
                <h5 class="ml-3" name="instagram">{{contact?.instagram ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Facebook</label>
                <h5 class="ml-3" name="facebook">{{contact?.facebook ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">TikTok</label>
                <h5 class="ml-3" name="tikTok">{{contact?.tikTok ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Agent1</label>
                <h5 class="ml-3" name="agent1">{{ checkPipesInStrings(contact?.agent1)}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Agent2</label>
                <h5 class="ml-3" name="agent2">{{ checkPipesInStrings(contact?.agent2)}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player CV url</label>
                <h5 class="ml-3" name="link">{{contact?.playerCVLink ?? '-'}}</h5>
            </div>
            <h2 class="mb-4 mt-2 text-muted">Priorities</h2>
            <div class="row">
                <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                    <h5 class="ml-3" name="sport">Countries:</h5>
                </div>
                <div *ngFor="let item of contact?.countryPriorityContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">            
                    <h5 class="ms-3" name="countryPriorityContacts">{{item.name}}</h5>
                </div> 
            </div>   
            <div class="row">
                <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                    <h5 class="ml-3" name="sport">Leagues:</h5>
                </div>
                <div *ngFor="let item of contact?.leaguePriorityContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">            
                    <h5 class="ms-3" name="leaguePriorityContacts">{{item.name}}</h5>
                </div>
            </div>
            <div class="row">
                <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                    <h5 class="ml-3" name="sport">Teams:</h5>
                </div>
                <div *ngFor="let item of contact?.teamPriorityContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">            
                    <h5 class="ms-3" name="teamPriorityContacts">{{item.name}}</h5>
                </div>
            </div>        
            <h2 class="mb-4 mt-2 text-muted">Exclussions</h2>  
            <div class="row">
                <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                    <h5 class="ml-3" name="sport">Countries:</h5>
                </div>
                <div *ngFor="let item of contact?.countryExclusionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">            
                    <h5 class="ms-3" name="countryExclusionContacts">{{item.name}}</h5>
                </div>
            </div>
           
            <div class="row">
                <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                    <h5 class="ml-3" name="leagues">Leagues:</h5>
                </div>
                <div *ngFor="let item of contact?.leagueExclusionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">            
                    <h5 class="ms-3" name="leagues">{{item.name}}</h5>
                </div>
            </div>
            <div class="row">
                <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                    <h5 class="ml-3" name="teams">Teams:</h5>
                </div>
                <div *ngFor="let item of contact?.teamExclusionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">            
                    <h5 class="ms-3" name="teamExclusionContacts">{{item.name}}</h5>
                </div>
            </div>
            <h2 class="mb-4 mt-2 text-muted">Finance Information</h2>   
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Currency</label>
                <h5 class="ml-3" name="currency">{{contact?.valuta?.name ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Est transfer</label>
                <h5 class="ml-3" name="estTransfer">{{contact?.estTransfer ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Minimum Salary Netto</label>
                <h5 class="ml-3" name="minSalaryNetto">{{contact?.minSalaryNetto ?? '-'}}</h5>
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Minimum Salary Brutto</label>
                <h5 class="ml-3" name="minSalaryBrutto">{{contact?.minSalaryBrutto ?? '-'}}</h5>
            </div>
            <h2 class="mb-4 mt-2 text-muted">Player Information</h2>   
            <div class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
                <h5 class="ml-3" name="sport">Level: {{contact?.playerLevel?.name ?? '-'}}</h5>
            </div>
            <div class="col-6 col-md-6 col-lg-1 mb-4 mt-3">
                <h5 class="ml-3" name="sport">Positions:</h5>
            </div>
            <div *ngFor="let item of contact?.positionContacts" class="col-6 col-md-6 col-lg-4 mb-4 mt-3">
                <div class="col-3 col-md-2 col-lg-1"></div>
                <h5 class="ms-3" name="position">{{item.name}}</h5>
            </div>
            <h2 class="mb-4 mt-2 text-muted">Contract Information</h2>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Mandate Enable</label>
                <h5 class="ml-3" name="mandate">{{contact?.mandate ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">People in Sport Priority</label>
                <h5 class="ml-3" name="pinsPriority">{{contact?.pinsPriority ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Include In App</label>
                <h5 class="ml-3" name="includeInApp">{{contact?.includeInApp ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Contract Type</label>
                <h5 class="ml-3" name="contractType">{{contact?.contractType ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Role</label>
                <h5 class="ml-3" name="role">{{contact?.role ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Transfer Market</label>
                <h5 class="ml-3 text-break" name="transferMarket">{{contact?.transferMarket ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Rep Contract</label>
                <h5 class="ml-3" name="repContract">{{contact?.repContract ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Rep Contract Start Date</label>
                <h5 class="ml-3" name="repContractStartDate">{{(contact?.repContractStartDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Rep Contract End Date</label>
                <h5 class="ml-3" name="repContractEndDate">{{(contact?.repContractEndDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Rep Contract Notice Of Termination</label>
                <h5 class="ml-3" name="repContractNoticeOfTermination">{{contact?.repContractNoticeOfTermination ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Rep Contract Remuneration</label>
                <h5 class="ml-3" name="repContractRemuneration">{{contact?.repContractRemuneration ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract</label>
                <h5 class="ml-3" name="playerContract">{{contact?.playerContract ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Start Date</label>
                <h5 class="ml-3" name="playerContractStartDate">{{(contact?.playerContractStartDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract End Date</label>
                <h5 class="ml-3" name="playerContractEndDate">{{(contact?.playerContractEndDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Rental Clause</label>
                <h5 class="ml-3" name="playerContractRentalClause">{{contact?.playerContractRentalClause ? "enabled" : "disabled"}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Rental Description</label>
                <h5 class="ml-3" name="playerContractRentalDescription">{{contact?.playerContractRentalDescription ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Buy Out Active</label>
                <h5 class="ml-3" name="playerContractBuyOutActive">{{contact?.playerContractBuyOutActive ? "yes" : "not"}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract BuyOut Active From Date</label>
                <h5 class="ml-3" name="playerContractBuyOutActiveFromDate">{{(contact?.playerContractBuyOutActiveFromDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Buy Out Amount</label>
                <h5 class="ml-3" name="playerContractBuyOutAmount">{{contact?.playerContractBuyOutAmount ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Buy Out Description</label>
                <h5 class="ml-3" name="playerContractBuyOutDescription">{{contact?.playerContractBuyOutDescription ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Relegation Clause</label>
                <h5 class="ml-3" name="playerContractRelegationClause">{{contact?.playerContractRelegationClause ? "enabled" : "disabled"}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Relegation Description</label>
                <h5 class="ml-3" name="playerContractRelegationDescription">{{contact?.playerContractRelegationDescription ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Option Clause</label>
                <h5 class="ml-3" name="playerContractOptionClause">{{contact?.playerContractOptionClause ? "enabled" : "disabled"}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Player Contract Description</label>
                <h5 class="ml-3" name="playerContractDescription">{{contact?.playerContractDescription ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Other Contract</label>
                <h5 class="ml-3" name="otherContact">{{contact?.otherContact ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Other Contract Start Date</label>
                <h5 class="ml-3" name="otherContractStartDate">{{(contact?.otherContractStartDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Other Contract End Date</label>
                <h5 class="ml-3" name="otherContractEndDate">{{(contact?.otherContractEndDate | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Other Contract Description</label>
                <h5 class="ml-3" name="otherContractEndDate">{{contact?.otherContractDescription ?? '-'}}</h5>            
            </div>
            <h2 class="mb-4 mt-2 text-muted">Personal Information</h2>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">DateOfBirth</label>
                <h5 class="ml-3" name="dateOfBirth">{{(contact?.dateOfBirth | date: 'longDate') ?? '-'}}</h5>
            </div>       
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Nationality</label>
                <h5 class="ml-3" name="nationality">{{ checkPipesInStrings(contact?.nationality)}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Marital Status</label>
                <h5 class="ml-3" name="maritalStatus">{{contact?.maritalStatus ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Number Of Kids</label>
                <h5 class="ml-3" name="numberOfKids">{{contact?.numberOfKids ?? '-'}}</h5>            
            </div>         
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Right | Left</label>
                <h5 class="ml-3" name="rightyLefty">{{contact?.rightyLefty ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Height</label>
                <h5 class="ml-3" name="height">{{contact?.height ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Weight</label>
                <h5 class="ml-3" name="weight">{{contact?.weight ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Elite Prospects</label>
                <h5 class="ml-3" name="eliteProspects">{{contact?.eliteProspects ?? '-'}}</h5>            
            </div>  
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Description</label>
                <h5 class="ml-3" name="description">{{contact?.description ?? '-'}}</h5>            
            </div>            
        
        <div class="row mb-2 mt-5 ml-2">
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Created At</label>
                <h5 class="ml-3" name="createdAt">{{(contact?.createdAt | date:'longDate') ?? '-'}}</h5>            
            </div>
            <div class=" col-md-6 col-lg-4 mb-4 mt-3">
                <label class="text-muted">Updated At</label>
                <h5 class="ml-3" name="updatedAt">{{(contact?.updatedAt | date:'longDate') ?? '-'}}</h5>            
            </div>
        </div>    
        
