import { FormBuilder, FormGroup } from '@angular/forms';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-filter-string',
  templateUrl: './filter-string.component.html',
  styleUrls: ['./filter-string.component.scss']
})
export class FilterStringComponent implements OnInit {
  filterForm: FormGroup | null = null;
  constructor(private router: Router,
  private formBuilder: FormBuilder) { }
  id: string | null = null;
  @Output() filterEmiter = new EventEmitter<string>();
  @Input() filterString?: string;

  ngOnInit(): void {
    this.router.events.subscribe(x => {
      const controlObject = this.filterForm?.get("nameFilter");
      controlObject?.setValue("");
    })
    this.filterForm = this.formBuilder.group({
      nameFilter: this.filterString,
    })
  }

  filterClear() {
      const controlObject = this.filterForm?.get("nameFilter");
      controlObject?.setValue("");
      this.filterEmiter.emit(this.filterForm?.value?.nameFilter);
    return;
  }
  
  filterStringSubmit() {
    if (!this.filterEmiter) {
      return;
    }
    this.filterEmiter.emit(this.filterForm?.value?.nameFilter);
    return;
  }
}
