import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/state/auth.service';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { Tenant, TenantsRepository } from 'src/app/state/tenants.repository';
import { combineLatest } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';

@Component({
  selector: 'app-impersonation-modal',
  templateUrl: './impersonation-modal.component.html',
  styleUrls: ['./impersonation-modal.component.scss']
})
export class ImpersonationModalComponent implements OnInit {
  console = console;
  selectedUserId: string | undefined;
  tenants = new Array<Tenant>();
  tenantIds: string[] | null = null;
  userOptions$ = combineLatest([this.repo.allSorted$]).pipe(
    map(([users]) => users.map(x => {
      let option: DatalistOption = {
        value: x.id,
        label: `${x.name} ${x.surname}`,
        sublabel: x.email,
      };
      return option;
    }))
  );

  constructor(
    public repo: UsersRepository,
    private service: UsersService,
    private auth: AuthService,
    private authRepo: AuthRepository,
    private tenantsService: TenantsService,
    private tenantsRepo: TenantsRepository,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  load() {
    this.service.load().subscribe();
    if (this.authRepo.isInRole(UserRoles.Superadmin)) {
      this.tenantsService.load().subscribe();
    }
  }

  getTenants() {
    this.tenantIds = this.authRepo.getTenantIds();
    if(this.tenantIds && this.tenantIds?.length > 0) {      
      this.tenantIds.forEach(x => {
        this.tenantsService.loadOne((x)).subscribe(y => this.tenants.push(y));
      });      
    }
  }

  impersonate() {
    if (!this.selectedUserId) {
      return;
    }
    this.auth.impersonate(this.selectedUserId)
      .subscribe({next: () => {
        this.getTenants();
      },
      complete: () => {
        this.router.navigate(['/']);
      }
    });
          
  }
}
