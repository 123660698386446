import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { Observable, switchMap, tap } from 'rxjs';
import { AuthRepository, PasswordChangeRequest, UserRoles } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { User, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';
import { Tenant, TenantsRepository } from '../../../../state/tenants.repository';
import { TenantsService } from '../../../../state/tenants.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {
  userData$?: Observable<User | undefined>;
  id?: string;
  submitErrors: string[] | null = null;
  tenantIds: string[] | null = null;
  tenants = new Array<Tenant>();
  isSuperAdmin: boolean = false;

  constructor(
    public repo: AuthRepository,
    private auth: AuthService,
    private users: UsersRepository,
    private service: UsersService,
    private router: Router,
    private authRep: AuthRepository,
    private tenantService: TenantsService,
    public tenantRepo: TenantsRepository,
  ) {
    this.getTenants();
  }

  ngOnInit(): void {
    if (this.repo.isInRole(UserRoles.Superadmin)) {
      this.tenantService.load().subscribe();
      this.isSuperAdmin = true;
    }
  
    this.repo.id$.pipe(
      filterNil(),
      switchMap(id => this.service.loadOne(id))
    ).subscribe();
    this.userData$ = this.repo.id$.pipe(
      filterNil(),
      switchMap(id => this.users.one(id)),
      tap(user => this.id = user?.id)
    );
  }

  onInfoSubmit(user: Partial<User>) {
    this.submitErrors = null;
    if (!this.id) {
      throw 'Can not identify current user';
    }
    this.service.update(this.id, user).subscribe({
      complete: () => {
        this.auth.refresh().subscribe(() => 
          this.router.navigate(['/profile']));
      },
      error: data => this.submitErrors = data
    });
  }

  getTenants() {
    this.tenantIds = this.authRep.getTenantIds();
    if (this.tenantIds && this.tenantIds?.length > 0) {
      this.tenantIds.forEach(x => {
        this.tenantService.loadOne((x)).subscribe(y => this.tenants.push(y));
      });
    }
  }

  onPasswordSubmit(dto: PasswordChangeRequest) {
    this.submitErrors = null;
    this.auth.changePassword(dto).subscribe({
      complete: () => {
        this.router.navigate(['/profile']);
      },
      error: data => this.submitErrors = data
    });
  }

}
