<div class="row mb-2">
  <div class="col">
    <h1 [class.handballwomen]="(tenantRepo.tenant(id!) | async)?.name?.includes('Handball')">Currencies [{{(tenantRepo.tenant(id!) | async)?.name}}]</h1>
  </div>
  <!--<div *ngIf="auth.isAnyAdmin$ | async" class="col-auto d-flex align-items-center">-->
    <!--<app-import-export filenamePrefix="currencies"
      [exportFactory]="exportFactory"></app-import-export>-->
  <!--</div>-->
</div>
<div class="row mb-4">
  <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
    <app-pagination [page]="(repo.pageNumber$ | async) || 1"
      [total]="(repo.paginationData$ | async)?.lastPage || 0"
      (pageChange)="service.loadPage($event, 10, id!).subscribe()"></app-pagination>
  </div>
  <div class="col-12 col-md-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions"
      [sorting]="repo.sort$ | async"
      (sortingChange)="service.sort($event, id!).subscribe()"></app-sort-dropdown>
  </div>
</div>
<div class="row mb-4">
    <div class="col">
      <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      <app-valutas-list [entities]="repo.page$ | async"
      [tenantId]="id"></app-valutas-list>
    </div>
</div>
