<div class="modal fade" bsModal [config]="{ show: true }" id="deleteModal" tabindex="-1" 
    #deleteModal aria-hidden="true" (onHidden)="cancel.emit()">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content bg-lights border-0">
        <div class="modal-header">
          <h6 class="modal-title" id="deleteModalLabel" i18n="Title for confirmation dialog">Confirm your action</h6>
          <button type="button" class="btn-close" aria-label="Close" i18n-aria-label="Close label @@close"
            (click)="cancel.emit()"></button>
        </div>
        <div class="modal-body">
          <div class="form-floating mb-3">
            <!--<p class="mb-1" i18n="Confirm delete message start">You are about to delete the following entry:</p>
            <p class="mb-1"><strong>{{ deleteCandidate }}</strong></p>-->
            <p class="mb-0" i18n="Confirm delete message end">Please confirm your action by clicking Delete.</p>
          </div>
        </div>
        <div class="modal-footer justify-content-center">
            <button class="btn btn-danger me-2"
                (click)="confirm.emit()" i18n="Delete button">Delete</button>
            <button class="btn btn-secondary"
                (click)="cancel.emit()" i18n="@@cancel">Cancel</button>
        </div>
      </div>
    </div>
</div>
