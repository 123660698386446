import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseReadService } from "./abstract/base-read.service";
import { PlayerLevel, PlayerLevelsRepository } from "./playerlevels.repository";

const API = '/api/playerlevels';

@Injectable({ providedIn: 'root' })
export class PlayerLevelsService extends BaseReadService<PlayerLevel> {

  constructor(
    http: HttpClient,
    repo: PlayerLevelsRepository
  ) {
    super(API, http, repo);
  }
}