import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseReadService } from './abstract/base-read.service';
import { CountriesRepository, Country } from './countries.repository';

const API = '/api/countries';

@Injectable({ providedIn: 'root' })
export class CountriesService extends BaseReadService<Country> {
  constructor(http: HttpClient, repo: CountriesRepository) {
    super(API, http, repo);
  }

  loadForSearch(tenantId?: string, filterDeleted?: boolean) {
    return this.http.get<Country[]>(
      `${this.api}/forSearch/?tenantId=${tenantId}&filterDeleted=${filterDeleted}`
    );
  }
}
