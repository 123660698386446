import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { League } from 'src/app/state/leagues.repository';
import { Team } from 'src/app/state/teams.repository';

@Component({
  selector: 'app-team-create-form',
  templateUrl: './team-create-form.component.html',
  styleUrls: ['./team-create-form.component.scss']
})
export class TeamCreateFormComponent implements OnInit {
  @Input() editedTeam: Team | null = null;  
  form?: FormGroup;
  leagueOptions: DatalistOption[] | null = null;
  
  @Input() set leagues(value: League[] | null) {
    if (!value) {
      this.leagueOptions = null;
    } else {
      this.leagueOptions = value.map(x => ({
        value: x.id,
        label: `${x.name} - ${x.countryName}`
      }));
    }
  }

  @Output() teamSubmit = new EventEmitter<Partial<Team>>();
  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.editedTeam?.name, Validators.required],
      leagueId: [this.editedTeam?.leagueId, Validators.required]      
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.teamSubmit.emit({
      name: this.form?.value?.name.toString(),
      leagueId: this.form?.value?.leagueId      
    });
  }

}
