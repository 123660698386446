import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Club } from 'src/app/state/clubs.repository';
import { Contact } from 'src/app/state/contacts.repository';
import { ContractStatus } from 'src/app/state/contractstatuses.repository';
import { Country } from 'src/app/state/countries.repository';
import { PlayerLevel } from 'src/app/state/playerlevels.repository';
import { Position } from 'src/app/state/positions.repository';
import { Requirement } from 'src/app/state/requirements.repository';
import { Transfer } from 'src/app/state/transfers.repository';
import { Valuta } from 'src/app/state/valutas.repository';

@Component({
  selector: 'app-requirement-create-form',
  templateUrl: './requirement-create-form.component.html',
  styleUrls: ['./requirement-create-form.component.scss'],
})
export class RequirementCreateFormComponent implements OnInit {
  @Input() editedRequirement: Requirement | null = null;
  @Input() tenantId: string | null = null;

  form?: FormGroup;
  advancedSearch = false;
  positionOptions: DatalistOption[] | null = null;
  contractStatusOptions: DatalistOption[] | null = null;
  playerLevelOptions: DatalistOption[] | null = null;
  clubOptions: DatalistOption[] | null = null;
  transferOptions: DatalistOption[] | null = null;
  contactOptions: DatalistOption[] | null = null;
  valutaOptions: DatalistOption[] | null = null;
  countryOptions: DatalistOption[] | null = null;
  activeTransfer: Transfer | null = null;
  activeCurrency?: Valuta | null = null;
  checkOptions?: boolean | null = null;

  rightyLefty = ['Right', 'Left', 'Both'];
  rightyLeftyOptions: DatalistOption[] = this.rightyLefty.map((x) => ({
    value: x,
    label: x,
  }));

  maritalStatuses = ['Married', 'Single'];
  maritalStatusOptions: DatalistOption[] = this.maritalStatuses.map((x) => ({
    value: x,
    label: x,
  }));

  pinsPriorities = ['A', 'B', 'C', 'D', 'E'];
  pinsPriorityOptions: DatalistOption[] = this.pinsPriorities.map((x) => ({
    value: x,
    label: x,
  }));

  contractTypes = ['PinsPlayer', 'Mandate'];
  contractTypeOptions: DatalistOption[] = this.contractTypes.map((x) => ({
    value: x,
    label: x,
  }));

  @Input() set positions(value: Position[] | null) {
    if (!value) {
      this.positionOptions = null;
    } else {
      this.positionOptions = value.map((x) => ({
        value: x.id,
        label: x?.name,
      }));
    }
  }

  @Input() set contractStatuses(value: ContractStatus[] | null) {
    if (!value) {
      this.contractStatusOptions = null;
    } else {
      this.contractStatusOptions = value.map((x) => ({
        value: x.id,
        label: x?.name,
      }));
    }
  }

  @Input() set playerLevels(value: PlayerLevel[] | null) {
    if (!value) {
      this.playerLevelOptions = null;
    } else {
      this.playerLevelOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set clubs(value: Club[] | null) {
    if (!value) {
      this.clubOptions = null;
    } else {
      this.clubOptions = value.map((x) => ({
        value: x.id,
        label: `${x?.name} - ${x.team?.league?.name ?? ''}`,
      }));
    }
  }

  @Input() allContacts: Contact[] | null = null;

  @Input() set contacts(value: Contact[] | null) {
    if (!value) {
      this.contactOptions = null;
    } else {
      this.contactOptions = value.map((x) => ({
        value: x.id,
        label: `${x?.firstName} ${x?.lastName}`,
      }));
    }
  }

  @Input() set transfers(value: Transfer[] | null) {
    if (!value) {
      this.transferOptions = null;
    } else {
      const now = new Date();
      this.activeTransfer = value.filter(
        (x) => x.fromDate < now && x.toDate > now
      )[0];
      this.transferOptions = value.map((x) => ({
        value: x.id,
        label: x?.name,
      }));
    }
  }

  @Input() set valutas(value: Valuta[] | null) {
    if (!value) {
      this.valutaOptions = null;
    } else {
      this.activeCurrency = value.find((x) => x.name === 'EUR');
      this.valutaOptions = value.map((x) => ({
        value: x.id,
        label: x?.name,
      }));
    }
  }

  @Input() set countries(value: Country[] | null) {
    if (!value) {
      this.countryOptions = null;
    } else {
      this.countryOptions = value.map((x) => ({
        value: x.id,
        label: x?.name,
      }));
    }
  }

  @Input() set checker(value: boolean | null) {
    if (!value) {
      this.checkOptions = null;
    } else {
      this.checkOptions = value;
    }
  }
  updateValue(control: string, value: any) {
    if (control == 'positionRequirementIds') {
      this.checkValidation(value);
    }
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  constructor(private formBuilder: FormBuilder) {}

  changeAdvancedSearch() {
    if (!this.advancedSearch) {
      this.advancedSearch = true;
    } else {
      this.advancedSearch = false;
    }
  }

  @Output() requirementSubmit = new EventEmitter<Partial<Requirement>>();

  ngOnInit(): void {
    this.contacts = this.allContacts;
    this.form = this.formBuilder.group({
      clubId: [this.editedRequirement?.clubId || '', Validators.required],
      transferId: [
        this.editedRequirement?.transferId || '',
        Validators.required,
      ],
      contactId: [this.editedRequirement?.contactId || '', Validators.required],
      maxNetto: [this.editedRequirement?.maxNetto || 0],
      maxBrutto: [this.editedRequirement?.maxBrutto || 0],
      valutaId: [this.editedRequirement?.valutaId],
      maxTransferSum: [this.editedRequirement?.maxTransferSum || 0],
      isAdvancedSearch: [this.editedRequirement?.isAdvancedSearch || false],
      minAge: [this.editedRequirement?.minAge || 0],
      maxAge: [this.editedRequirement?.maxAge || 0],
      maritalStatus: [this.editedRequirement?.maritalStatus],
      countryId: [this.editedRequirement?.countryId],
      rightyLefty: [this.editedRequirement?.rightyLefty || ''],
      pinsPriority: [this.editedRequirement?.pinsPriority || ''],
      contractType: [this.editedRequirement?.contractType],
      minHeight: [this.editedRequirement?.minHeight || 0],
      maxHeight: [this.editedRequirement?.maxHeight || 0],
      minWeight: [this.editedRequirement?.minWeight || 0],
      maxWeight: [this.editedRequirement?.maxWeight || 0],
      positionRequirementIds: [
        this.editedRequirement?.positionRequirements.map((x) => x.id),
        Validators.required,
      ],
      playerLevelRequirementIds: [
        this.editedRequirement?.playerLevelRequirements.map((x) => x.id),
        Validators.required,
      ],
      contractStatusRequirementIds: [
        this.editedRequirement?.contractStatusRequirements.map((x) => x.id),
        Validators.required,
      ],
      description: [this.editedRequirement?.description],
    });
    this.advancedSearch = this.form?.value.isAdvancedSearch;
    this.checkNoNeed();
    this.checkValidation(this.form?.value?.positionRequirementIds);
  }

  checkNoNeed() {
    var transferId = localStorage.getItem('noNeedTransferId');
    var clubId = localStorage.getItem('noNeedClubId');
    localStorage.removeItem('noNeedTransferId');
    localStorage.removeItem('noNeedClubId');
    if (transferId && clubId) {
      this.form?.controls['transferId'].setValue(transferId);
      this.form?.controls['clubId'].setValue(clubId);

      this.updateContacts();
    }
  }

  updateContacts() {
    var clubId = this.form?.value.clubId;
    if (clubId && this.allContacts) {
      var filterContacts = this.allContacts.filter((x) => x.clubId == clubId);
      this.contacts = filterContacts;
    } else {
      this.contacts = this.allContacts;
    }
  }

  ngAfterContentChecked(): void {
    if (this.checkOptions == true) {
      if (this.activeTransfer?.id != undefined && this.activeCurrency?.id) {
        this.updateValue('transferId', this.activeTransfer?.id);
        this.updateValue('valutaId', this.activeCurrency?.id);
        this.checkOptions = false;
      }
    }
  }

  checkValidation(value?: any) {
    var playerLevel = this.form?.get('playerLevelRequirementIds');
    var contractStatus = this.form?.get('contractStatusRequirementIds');
    var noneed = this.positionOptions?.find((x) => x.label == 'No need');
    if (noneed && value != null && value.includes(noneed.value)) {
      playerLevel?.clearValidators();
      playerLevel?.updateValueAndValidity();
      contractStatus?.clearValidators();
      contractStatus?.updateValueAndValidity();
    } else {
      playerLevel?.setValidators(Validators.required);
      playerLevel?.updateValueAndValidity();
      contractStatus?.setValidators(Validators.required);
      contractStatus?.updateValueAndValidity();
    }
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.requirementSubmit.emit({
      clubId: this.form?.value?.clubId,
      tenantId: this.editedRequirement?.tenantId ?? this.tenantId!,
      transferId: this.form?.value?.transferId,
      contactId: this.form?.value?.contactId,
      maxNetto: this.form?.value?.maxNetto,
      maxBrutto: this.form?.value?.maxBrutto,
      valutaId: this.form?.value?.valutaId,
      maxTransferSum: this.form?.value?.maxTransferSum,
      isAdvancedSearch: this.form?.value?.isAdvancedSearch,
      minAge: this.form?.value?.minAge,
      maxAge: this.form?.value?.maxAge,
      maritalStatus: this.form?.value?.maritalStatus,
      countryId: this.form?.value?.countryId,
      rightyLefty: this.form?.value?.rightyLefty,
      pinsPriority: this.form?.value?.pinsPriority,
      contractType: this.form?.value?.contractType,
      minHeight: this.form?.value?.minHeight,
      maxHeight: this.form?.value?.maxHeight,
      minWeight: this.form?.value?.minWeight,
      maxWeight: this.form?.value?.maxWeight,
      positionRequirementIds: this.form?.value?.positionRequirementIds,
      playerLevelRequirementIds: this.form?.value?.playerLevelRequirementIds,
      contractStatusRequirementIds:
        this.form?.value?.contractStatusRequirementIds,
      description: this.form?.value?.description,
    });
    this.form?.markAsUntouched();
  }
}
