import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Team, TeamsRepository, teamsSortOptions } from 'src/app/state/teams.repository';
import { TeamsService } from 'src/app/state/teams.service';
import { TenantsRepository } from 'src/app/state/tenants.repository';

@Component({
  selector: 'app-teams-page',
  templateUrl: './teams-page.component.html',
  styleUrls: ['./teams-page.component.scss']
})
export class TeamsPageComponent implements OnInit {
  sortOptions = teamsSortOptions;
  exportFactory = () => this.service.export();
  id: string | null = null;
  entityList: Team[] | null = null;
  filter: string | undefined;
  constructor(
    public service: TeamsService,
    public repo: TeamsRepository,
    public auth: AuthRepository,
    private activatedRoute: ActivatedRoute,
    public tenantRepo: TenantsRepository
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({ 
      next: (value) => {        
        this.id = value.get('tenant?');
        this.service.reloadPageWithFilter(this.id!).subscribe();
      }    
    });
  }
  searchSubmit($event: string) {
    this.filter = $event
    if ($event != null) {
      this.service.reloadPageWithFilter(`${this.id}`,undefined, $event).subscribe()
    }
  }
}
