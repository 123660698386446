<h2 class="mb-4 text-primary" *ngIf="id !== 'new'"i18n>Price per 1 €(EUR) TO {{(valuta$ | async)?.name}}</h2>
<div *ngIf="(valuta$ | async)" class="row mb-4">
    <div class="col-12">
        <app-valutas-edit-form
         [editedValuta]="valuta$ | async"         
         (valutaSubmit)="update($event)"></app-valutas-edit-form>
        <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
        <app-error-alert title="Could not submit the currency" i18n-title
         [errors]="submitErrors"></app-error-alert>       
    </div>
</div>