import { Injectable } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Contact } from './contacts.repository';
import { Requirement } from './requirements.repository';
import { Tenant } from './tenants.repository';

export interface Match {
  id: string;
  requirementId: string;
  requirement: Requirement;
  point: number;
  isForEmail: boolean;
  createdAt: Date;
  updatedAt: Date;
  contactIds: string[];
  contacts: Contact[];
  contactMatches: ContactMatch[]
}

export interface ContactMatch {
  id: string,
  matchId: string,
  contactId: string,
  contact: Contact,
  isForEmail: boolean,
  playerPlanMark: number,
  financeMark: number,
  contractMark: number,
  personalMark: number,
  hasBeenSent: boolean,
  sentToACMatch: SentToACMatch,
  tenantId: string,
  tenant: Tenant
}

export interface SentToACMatch {
  id: string;
  contactMatchId: string;
  createdAt: Date;
  updatedAt: Date;  
}

export class MatchCreate {
  requirementId?: string;  
}

export const matchSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Point`, property: 'point' },
  { label: $localize `:Sort label Date:Date`, property: 'createdAt' }
];

export interface MatchProps {
  sortBy: SortState;
}

@Injectable({ providedIn: 'root' })
export class MatchesRepository extends BaseRepository<Match> {

  constructor() {
    super('matches', matchSortOptions);
  }

}

// const { state, config } = createState(
//   withProps<MatchProps>({ 
//     sortBy: { parameter: matchSortOptions[1], direction: 'desc'} 
//   }),
//   withEntities<Match>(),
//   withRequestsCache(), 
//   withRequestsStatus()
// );
// const store = new Store({ name: 'matches', state, config });
// export const trackMatchRequestsStatus = createRequestsStatusOperator(store);
// export const skipWhileMatchesCached = createRequestsCacheOperator(store);

// @Injectable({ providedIn: 'root' })
// export class MatchesRepository {
//   name = store.name;

//   sort$ = store.pipe(select(state => state.sortBy));
    
//   isLoadingOne$ = (id: Match['id']) => store.pipe(
//     selectRequestStatus(id),
//     map(x => x.value === 'pending')
//   );

//   match = (id: string) => store.pipe(selectEntity(id));
//   status = (id: string) => store.pipe(selectRequestStatus(id, { groupKey: store.name }));

//   setSort(sortBy: SortState) {
//     store.update((state) => ({
//       ...state,
//       sortBy
//     }));
//   }

//   setMatch(match: Match) {
//     store.update(
//       updateRequestCache(store.name),
//       upsertEntities(match),
//       updateRequestsStatus([store.name], 'success')
//     );
//   }

//   track(operation = ''): MonoTypeOperatorFunction<any> {
//     let key = store.name;
//     if (operation) {
//         key += `_${operation}`;
//     }
//     return trackMatchRequestsStatus(key);
// }

//   upsertMatch(match: Match) {
//     store.update(
//       updateRequestCache(match.id),
//       upsertEntities([match]),
//       updateRequestsStatus([match.id], 'success')
//     );
//   }
// }