<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="table-responsive">
    <table style="margin-top:10px" class="table table-hover table-bordered">
      <thead>
        <tr>
          <th class="col-6 col-md-3 col-lg-2">Data</th>
          <th class="col-12 col-md-6 col-lg-4">Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Name</td>
          <td>
            <div [appValidateState]="form.get('name')" class=" form-group form-floating">
              <input formControlName="name" type="text" class="form-control"
                     placeholder="Transfer name" i18n-placeholder="@@years">
              <label for="name" i18n="@@name">Transfer name</label>
            </div>
          </td>
        </tr>
        <tr>
          <td>Sport</td>
          <td>
            {{currentTennantName}}
          </td>
        </tr>
        <tr>
          <td>Years</td>
          <td>
            <div [appValidateState]="form.get('years')" class="form-group form-floating">
              <input (ngModelChange)="resetValidator()" formControlName="years" type="text" class="form-control"
                     placeholder="Years" i18n-placeholder="@@years">
              <label *ngIf="!form.get('years')?.touched" for="years" i18n="@@name">Years</label>
              <label style="color: red;" *ngIf="form.get('years')?.hasError('required')
                                                         && form.get('years')?.touched"
                     for="years" i18n="@@name">Field is mandatory</label>
              <label style="color: red;" *ngIf="form.get('years')?.touched
                                                         && form.get('years')?.hasError('pattern')"
                     for="years" i18n="@@name">Field has to include 8 digits like: 1990-2020</label>
            </div>
          </td>
        </tr>
        <tr>
          <td>From Date</td>
          <td>
            <div [appValidateState]="form.get('fromDate')" class="form-group form-floating">
              <input (ngModelChange)="resetValidator()" id="fromDate" formControlName="fromDate" type="date" class="form-control" name="fromDate">
              <label for="fromDate" class="col-xs-3 col-sm-auto col-form-label">From Date</label>
            </div>
          </td>
        </tr>
        <tr>
          <td>To Date</td>
          <td>
            <div [appValidateState]="form.get('toDate')" [class.is-invalid]="form.get('fromDate')?.value > form.get('toDate')?.value && form.get('toDate')?.touched" class="form-group form-floating">
              <input (ngModelChange)="resetValidator()" id="toDate" formControlName="toDate" type="date" class="form-control" name="toDate">
              <label for="toDate" class="col-xs-3 col-sm-auto col-form-label">To Date</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div style="margin-bottom:10px;" *ngIf="isDatesError">
    <span style="color:red">From - To dates interval is icorect</span>
  </div>
  <div style="margin-bottom:10px;" *ngIf="iYearsError">
    <span style="color:red">Years interval is icorect</span>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="@@save">Save</button>
      </div>
    </div>
  </div>
</form>
