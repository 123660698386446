<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="row mb-2">
    <div class="col">
      <h6 class="my-1" i18n="Basic info label">Basic Information</h6>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="leaguePriorities"
          [options]="leaguePriorityOptions"
          [multiple]="true"
          [(activeValue)]="form.value.leaguePrioritiesIds"
          (activeValueChange)="updateValue('leaguePrioritiesIds', $event)"
          label="League Priorities"
          i18n-label="Label leaguePriorities"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="leagueExclusions"
          [options]="leagueExclusionOptions"
          [multiple]="true"
          [(activeValue)]="form.value.leagueExclusionsIds"
          (activeValueChange)="updateValue('leagueExclusionsIds', $event)"
          label="League Exclusions"
          i18n-label="Label leagueExclusions"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="picture"
          type="file"
          class="form-control"
          placeholder="Contact picture"
          i18n-placeholder="Contact picture placeholder @@picture"
        />
        <label for="picture" i18n="picture">Contact picture</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="countryPriorities"
          [options]="countryPriorityOptions"
          [multiple]="true"
          [(activeValue)]="form.value.countryPrioritiesIds"
          (activeValueChange)="updateValue('countryPrioritiesIds', $event)"
          label="Country Priorities"
          i18n-label="Label countryPriorities"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="countryExclusions"
          [options]="countryExclusionOptions"
          [multiple]="true"
          [(activeValue)]="form.value.countryExclusionsIds"
          (activeValueChange)="updateValue('countryExclusionsIds', $event)"
          label="Country Exclusions"
          i18n-label="Label countryExclusions"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('positionsIds')?.hasError('required') &&
          form.get('positionsIds')?.touched
        "
      >
        <app-datalist-select
          id="positions"
          [options]="positionOptions"
          [multiple]="true"
          [(activeValue)]="form.value.positionsIds"
          (activeValueChange)="updateValue('positionsIds', $event)"
          label="Positions"
          i18n-label="Label positionsIds"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="teamPriority"
          [options]="teamPriorityOptions"
          [multiple]="true"
          [(activeValue)]="form.value.teamPrioritiesIds"
          (activeValueChange)="updateValue('teamPrioritiesIds', $event)"
          label="Team Priorities"
          i18n-label="Label teamPrioritiesIds"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="teamExclusion"
          [options]="teamExclusionOptions"
          [multiple]="true"
          [(activeValue)]="form.value.teamExclusionsIds"
          (activeValueChange)="updateValue('teamExclusionsIds', $event)"
          label="Team Exclusions"
          i18n-label="Label teamExclusionsIds"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('playerLevelId')?.hasError('required') &&
          form.get('playerLevelId')?.touched
        "
      >
        <app-datalist-select
          id="playerLevels"
          [options]="playerLevelOptions"
          [(activeValue)]="form.value.playerLevelId"
          (activeValueChange)="updateValue('playerLevelId', $event)"
          label="Player level"
          i18n-label="Label playerLevelId"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div
        class="form-group form-floating mb-3"
        [class.is-invalid]="
          form.get('valutaId')?.hasError('required') &&
          form.get('valutaId')?.touched
        "
      >
        <app-datalist-select
          id="valuta"
          [options]="valutaOptions"
          [(activeValue)]="form.value.valutaId"
          (activeValueChange)="updateValue('valutaId', $event)"
          label="Currency"
          i18n-label="Label valutaId"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <app-datalist-select
          id="rightyLefty"
          [options]="rightyLeftyOptions"
          [(activeValue)]="form.value.rightyLefty"
          (activeValueChange)="updateValue('rightyLefty', $event)"
          label="Right Left"
          i18n-label="Label rightyLefty"
        ></app-datalist-select>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="linkToCV"
          type="text"
          class="form-control"
          placeholder="Link to player CV"
          i18n-placeholder="Link to player CV placeholder @@linkToCV"
        />
        <label for="linkToCV" i18n="linkToCV">Link to player CV</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="estTransfer"
          type="number"
          class="form-control"
          placeholder="Est Transfer"
          i18n-placeholder="Est Transfer placeholder @@estTransfer"
        />
        <label for="estTransfer" i18n="estTransfer">Est Transfer</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="height"
          type="number"
          class="form-control"
          placeholder="Height"
          i18n-placeholder="Height placeholder @@height"
        />
        <label for="height" i18n="height">Height</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="weight"
          type="number"
          class="form-control"
          placeholder="weight"
          i18n-placeholder="Weight placeholder @@weight"
        />
        <label for="weight" i18n="weight">Weight</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="minSalaryNetto"
          type="number"
          class="form-control"
          placeholder="Minimum Salary Netto"
          i18n-placeholder="Minimum Salary Netto placeholder @@minSalaryNetto"
        />
        <label for="minSalaryNetto" i18n="minSalaryNetto"
          >Minimum Salary Netto</label
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="minSalaryBrutto"
          type="number"
          class="form-control"
          placeholder="Minimum Salary Brutto"
          i18n-placeholder="Minimum Salary Brutto placeholder @@minSalaryBrutto"
        />
        <label for="minSalaryBrutto" i18n="minSalaryBrutto"
          >Minimum Salary Brutto</label
        >
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input
          formControlName="formerClub"
          type="text"
          class="form-control"
          placeholder="Former Club Name"
        />
        <label for="minSalaryBrutto">Former Club</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <textarea
          rows="4"
          style="height: 100%"
          formControlName="internationalLevel"
          type="text"
          class="form-control"
          placeholder="International Level"
        ></textarea>
        <label for="internationalLevel">International Level</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <textarea
          rows="4"
          style="height: 100%"
          formControlName="statistics"
          type="text"
          class="form-control"
          placeholder="Statistics"
        ></textarea>
        <label for="statistics">Statistics</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <textarea
          rows="4"
          style="height: 100%"
          formControlName="description"
          type="text"
          class="form-control"
          placeholder="Description"
          i18n-placeholder="Description placeholder @@description"
        ></textarea>
        <label for="description" i18n="@@description">Description</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <textarea
          rows="4"
          style="height: 100%"
          formControlName="playerReferences"
          type="text"
          class="form-control"
          placeholder="References"
        ></textarea>
        <label for="references">References</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="mandate"
        />
        <label for="mandate" i18n="mandate" class="form-check-label"
          >Mandate</label
        >
      </div>
    </div>
  </div>
  <div class="mt-3" formArrayName="videoInfos">
    <div class="" *ngFor="let item of getControls(); let i = index">
      <div class="row mb-2">
        <div class="col">
          <h6 class="my-1" i18n="Basic info label">Video {{ i + 1 }}</h6>
        </div>
      </div>
      <div class="row mb-2">
        <div [formGroupName]="i" class="col-12 col-md-6 col-lg-4">
          <div
            class="form-group form-floating mb-3"
            [class.is-invalid]="
              item.get('title')?.hasError('required') &&
              item.get('title')?.touched
            "
          >
            <input
              formControlName="title"
              type="text"
              class="form-control"
              placeholder="Title"
              i18n-placeholder="Title placeholder @@title"
            />
            <label for="Title" i18n="Title">Title</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div
            [formGroupName]="i"
            class="form-group form-floating mb-3"
            [class.is-invalid]="
              item.get('videoUrl')?.hasError('required') &&
              item.get('videoUrl')?.touched
            "
          >
            <input
              formControlName="videoUrl"
              type="text"
              class="form-control"
              placeholder="Video Url"
              i18n-placeholder="Video Url placeholder @@videoUrl"
            />
            <label for="videoUrl" i18n="@@Video Url">Video Url</label>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="row">
            <div class="col">
              <div [formGroupName]="i" class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="setPrimary(i)"
                  formControlName="isPrimary"
                />
                <label for="isPrimary" i18n="Primary" class="form-check-label"
                  >Primary</label
                >
              </div>
              <div [formGroupName]="i" class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  (click)="setFullMatch(i)"
                  formControlName="isFullMatch"
                />
                <label
                  for="isFullMatch"
                  i18n="Full match"
                  class="form-check-label"
                  >Full match</label
                >
              </div>
            </div>
            <div class="col-auto d-flex align-items-end mb-2">
              <i class="btn btn-danger" (click)="deleteVideo(i)">Delete</i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="row">
      <div class="col-auto d-flex align-items-end">
        <div class="form-group form-floating pb-3 d-grid mr-3">
          <button
            type="button"
            (click)="addNewVideoInfo()"
            class="btn btn-md-lg btn-success shadow-sm"
            i18n="Add button @@add"
          >
            <i class="bi bi-plus"></i> Add Video
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4">
    <div class="form-group form-floating pb-3 d-grid mr-3">
      <button
        type="submit"
        class="btn btn-md-lg btn-primary shadow-sm"
        i18n="Save button @@save"
      >
        Save
      </button>
    </div>
  </div>
</form>
