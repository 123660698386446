import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { withProps } from '@ngneat/elf';
import { Store, createState } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface UIProps {
  isMenuOpened: boolean;
  isSessionOver: boolean;
  isMenuExpanded: boolean;
  isInstallPromptDismissed: boolean;
  currentTenantId: string | undefined;
}

const { state, config } = createState(withProps<UIProps>({ 
  isSessionOver: false,
  isMenuOpened: false,
  isMenuExpanded: true,
  isInstallPromptDismissed: false,
  currentTenantId: undefined
}));
const store = new Store({ name: 'ui', state, config });
persistState(store, { 
  storage: localStorageStrategy,
  source: store => store.pipe(
    select(state => ({ ...state, isSessionOver: false, isMenuOpened: false }))
  )
});

@Injectable({ providedIn: 'root' })
export class UiRepository {
  isSessionOver$ = store.pipe(select(state => state.isSessionOver));
  isMenuOpened$ = store.pipe(select(state => state.isMenuOpened));
  isMenuExpanded$ = store.pipe(select(state => state.isMenuExpanded));
  isInstallPromptDismissed$ = store.pipe(select(state => state.isInstallPromptDismissed));

  dismissInstallPrompt() {
    store.update(state => ({
      ...state,
      isInstallPromptDismissed: true
    }));
  }

  setSessionOver() {
    store.update((state) => ({
      ...state,
      isSessionOver: true
    }));
  }

  toggleMenuOpened(isOpen?: boolean, tenantId?: string) {
    store.update(state => ({
      ...state,
      isMenuOpened: isOpen !== undefined 
        ? isOpen : !state.isMenuOpened,
      currentTenantId: tenantId
    }));
  }

  toggleMenuExpanded(isOpen?: boolean) {    
    store.update(state => ({
      ...state,
      isMenuExpanded: isOpen !== undefined 
        ? isOpen : !state.isMenuExpanded
    }));
  }

  getCurrentTenantId() {    
    return store.getValue().currentTenantId;
  }
}
