export class EnvState {
    production = false;
    name = "AppName";
    apiUrl = "";
    features = {
        news: false,
        sport: false,
        offlineChanges: false
    };
    locale = 'en-gb';
}