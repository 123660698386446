<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let value of entities">
          <div class="row align-items-center">
            <div class="col-12 col-md">
              <div class="row mb-3 mb-md-0">
                <div class="col-auto position-relative">
                  <app-entity-avatar
                    [icon]="'bi bi-funnel'"
                  ></app-entity-avatar>
                </div>
                <div class="col">
                  <p>
                    <span class="text-muted me-2"
                      ><strong>{{ CLUB }}</strong></span
                    >
                    <span>{{ value.club.name }}</span>
                    <br />
                    <small class="text-muted me-2"
                      ><strong>{{ POSITIONS }}</strong></small
                    >
                    <small *ngFor="let position of value.positionRequirements">
                      <strong class="text me-2">{{ position.name }}</strong>
                    </small>
                    <small class="text-muted me-2"
                      ><strong>{{ LEVELS }}</strong></small
                    >
                    <small *ngFor="let level of value.playerLevelRequirements">
                      <strong class="text me-2">{{ level.name }}</strong>
                    </small>
                  </p>
                </div>
                <div
                  *ngIf="value.description"
                  class="col d-flex align-items-center p-0 m-0 ps-74"
                >
                  <small class="text-muted me-2 description"
                    ><strong>{{ DESCRIPTION }}</strong> {{ value.description }}
                  </small>
                </div>
                <!-- <div class="col-auto col-auto-xs align-items-center ms-2">
                  <small class="text-muted me-2"
                    ><strong>{{ DESCRIPTION }}</strong> {{ value.description }}
                  </small>
                </div> -->
              </div>
            </div>

            <div
              class="col-auto col-auto-xs align-items-center text-md-end ms-2"
            >
              <small class="text-muted w-100" i18n="Added date label"
                >Added {{ value.createdAt | date : "longDate" }}</small
              >
            </div>
            <div
              class="col-auto col-auto-xs align-items-center text-md-end ms-0 p-0"
            >
              <div class="d-flex flex-row">
                <div class="col-auto pe-0 pl-0 d-flex align-items-end">
                  <a
                    class="btn btn-md btn-link w-100"
                    (click)="viewing(value.id)"
                    [class.disabled]="value?.matches?.length === 0"
                  >
                    <i class="bi bi-activity me-1"></i>
                    <ng-container i18n="@@results">Show results</ng-container>
                  </a>
                </div>
                <div
                  class="col-auto col-auto-xs pe-0 pl-0 d-flex align-items-end"
                >
                  <a
                    class="btn btn-md btn-link w-100"
                    (click)="matching(value.id)"
                  >
                    <i class="bi bi-joystick me-1"></i>
                    <ng-container i18n="@@matching">Matching</ng-container>
                  </a>
                </div>
                <div
                  class="col-auto col-auto-xs pe-0 pl-0 d-flex align-items-end"
                >
                  <a
                    class="btn btn-md btn-link w-100"
                    [routerLink]="['/requirements/', tenantId, value.id]"
                  >
                    <i class="bi bi-pencil me-1"></i>
                    <!-- <ng-container i18n="@@edit">Edit</ng-container> -->
                  </a>
                </div>
                <div
                  class="col-auto col-auto-xs pe-0 pl-0 d-flex align-items-end"
                >
                  <a
                    class="btn btn-md btn-link w-100"
                    [routerLink]="['/requirements/info/', tenantId, value.id]"
                  >
                    <i class="bi bi-eye"></i>
                  </a>
                </div>
                <div class="col-auto col-auto-xs pe-0 d-flex align-items-end">
                  <button
                    type="button"
                    class="btn btn-md btn-link w-100 text-danger"
                    (click)="deleteConfirmation = value.id"
                    title="Delete"
                    i18n-title="Delete button"
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete
  *ngIf="deleteConfirmation"
  (confirm)="handleDeleteClick(deleteConfirmation); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
