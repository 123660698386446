import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./abstract/base.service";
import { Transfer, TransfersRepository } from "./transfers.repository";

const API = '/api/transfers';

@Injectable({ providedIn: 'root' })
export class TransfersService extends BaseService<Transfer> {

  constructor(
    http: HttpClient,
    repo: TransfersRepository
  ) {
    super(API, http, repo);
  }
  
}