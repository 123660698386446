import { formatDate } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { ROLE_NAMES } from 'src/app/modules/shared/pipes/translate-roles.pipe';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { Tenant } from 'src/app/state/tenants.repository';
import { User } from 'src/app/state/users.repository';
import { UserCreateUpdateDto } from 'src/app/state/users.service';

@Component({
  selector: 'app-user-basic-info-form',
  templateUrl: './user-basic-info-form.component.html',
  styleUrls: ['./user-basic-info-form.component.scss']
})
export class UserBasicInfoFormComponent implements OnInit {
  readonly ROLE_DESCRIPTIONS = {
    [UserRoles.Superadmin]: $localize `:Role description - Superadmin:Can edit system data and create tenants`,
    [UserRoles.TenantAdmin]: $localize `:Role description - Administrator:Can view and edit all data inside a tenant`,
    [UserRoles.User]: $localize `:Role description - User:Can view data`,
  };
  ROLE_NAMES = ROLE_NAMES;
  isSuperAdmin?: boolean;
  
  tenantOptions: DatalistOption[] | null = null;
  roleOptions: UserRoles[] = [];
  form?: FormGroup;
  file: File | null = null;

  @Input() isSuperAdminForT?: boolean;
  @Input() initialValue?: User | null;
  @Input() set tenants(value: Tenant[] | null) {
    if (!value) {
      this.tenantOptions = null;
    } else {
      if ((value[0] as any)?.length) {
        this.tenantOptions = (value[0] as any).map((x: Tenant) => ({
          value: x.id,
          label: x.name
        }));
      }
      else {
        this.tenantOptions = value.map((x: Tenant) => ({
          value: x.id,
          label: x.name
        }));
      }
    
    }
  }
  
  @Output() userSubmit = new EventEmitter<UserCreateUpdateDto>();

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    if(this.form) {
      if((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.picture = this.file;
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  constructor(
    private formBuilder: FormBuilder,
    public authRepo: AuthRepository
  ) {
    if (this.authRepo.isInRole(UserRoles.Superadmin)) {
      this.roleOptions = Object.values(UserRoles);
    } else if (this.authRepo.isInRole(UserRoles.TenantAdmin)) {
      this.roleOptions = Object.values(UserRoles)
        .filter(r => r !== UserRoles.Superadmin);
    }
  }

  ngOnInit(): void {
    const birthday = this.initialValue && this.initialValue.birthday
      ? formatDate(this.initialValue.birthday, 'yyyy-MM-dd', 'en')
      : '';
    this.isSuperAdmin = this.initialValue?.roles[0] === 'Superadmin';
    this.form = this.formBuilder.group({
      name: [this.initialValue?.name || ''],
      surname: [this.initialValue?.surname || ''],
      email: [this.initialValue?.email, [Validators.required, Validators.email]],
      phone: [this.initialValue?.phoneNumber || ''],
      picture: [this.initialValue?.image || ''],
      location: [this.initialValue?.location || ''],
      title: [this.initialValue?.title || ''],
      birthday: [birthday],
      password: [''],
      tenant: [this.initialValue?.tenantIds || ''],
      roles: this.formBuilder.array(
        this.roleOptions.map(r => this.initialValue
          && this.initialValue.roles.indexOf(r) >= 0)
      )
    });
  }
  
  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const roles = (this.form?.value?.roles as boolean[])
      .map((x, i) => (x && this.roleOptions[i]) as UserRoles)
      .filter(x => !!x);
    this.userSubmit.emit({ 
      email: this.form?.value?.email,
      phoneNumber: this.form?.value?.phone,
      name: this.form?.value?.name,
      surname: this.form?.value?.surname,
      title: this.form?.value?.title,
      location: this.form?.value?.location,
      birthday: this.form?.value?.birthday || null,
      image: this.file,
      password : this.form?.value?.password,
      tenantIds: this.form?.value?.tenant || null,
      roles: roles
    });
    this.form?.markAsUntouched();    
  }

}
