import { Component, Input, OnInit } from '@angular/core';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { Contact } from 'src/app/state/contacts.repository';
import { PlayersService } from 'src/app/state/players.service';
import { AuthRepository } from '../../../../state/auth.repository';

@Component({
  selector: 'app-players-list',
  templateUrl: './players-list.component.html',
  styleUrls: ['./players-list.component.scss']
})
export class PlayersListComponent implements OnInit {
  @Input() entities: Contact[] | null = null;
  @Input() tenantId: string | null = null;
  @Input() tenantName: string | null = null;
  deleteConfirmation: string | null = null;
 
  constructor(
    public env: EnvState,
    public authrepo: AuthRepository,
    private service: PlayersService
  ) { }

  ngOnInit(): void {
  }
  handleDeleteClick(id: string) {
    this.service.delete(id, this.tenantId!).subscribe();
  }
}
