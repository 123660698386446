import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Team } from './teams.repository';

export interface League {
    id: string;
    name: string;
    countryName: string;
    teams: Team[];
  }

export const leaguesSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label CountryName:CountryName`, property: 'countryName' }
];

@Injectable({ providedIn: 'root' })
export class LeagueRepository extends BaseRepository<League> {

  constructor() {
    super('leagues', leaguesSortOptions);
  }

}