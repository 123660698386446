import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'li[app-model-card]',
  templateUrl: './model-card.component.html',
  styleUrls: ['./model-card.component.scss'],
})
export class ModelCardComponent implements OnInit {
  @Input() title?: string;
  @Input() image?: string;
  @Input() subtitle?: string;
  @Input() badge?: string;
  @Input() icon?: string;
  @Input() date?: Date;
  @Input() routerLinkBase!: string;
  @Input() routerLinkNoNeed!: string;
  @Input() roles?: string[];
  @Input() id!: string;
  @Input() isDeleteDisabled = true;
  @Input() isEditDisabled = true;
  @Input() isPlayerViewEnabled = false;
  @Input() notForNoNeed = true;
  @Input() tenantid?: string | null = null;
  @Input() tenantName?: string | null = null;
  @Output() deleteClick = new EventEmitter<void>();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  handleDeleteClick($event: Event) {
    $event.preventDefault();
    if (!this.isDeleteDisabled) {
      this.deleteClick.emit();
    }
  }

  handleRequirement() {
    localStorage.setItem('noNeedClubId', this.id);
    this.router.navigate([this.routerLinkNoNeed]);
  }
}
