import { Pipe, PipeTransform } from "@angular/core";
import { UserRoles } from "src/app/state/auth.repository";

export const ROLE_NAMES = {
  [UserRoles.Superadmin]: $localize `:Role - Superadmin:Superadmin`,
  [UserRoles.TenantAdmin]: $localize `:Role - Administrator:Administrator`,
  [UserRoles.User]: $localize `:Role - User:User`,
};

@Pipe({ name: 'translateRoles' })
export class TranslateRolesPipe implements PipeTransform {
  transform(input: UserRoles[] | null | undefined): string[] | undefined {
    return input?.map(x => ROLE_NAMES[x]);
  }
}
