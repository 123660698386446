<div class="row mt-1">
    <div class="col-12">
      <div class="card shadow-md mb-4">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let value of entities">
            <div class="row align-items-center">
              <div class="col-12 col-md">
                <div class="row mb-3 mb-md-0">
                  <div class="col-auto position-relative">
                    <app-entity-avatar [icon]="'bi bi-people'"></app-entity-avatar>
                  </div>
                  <div class="col">
                    <p>
                      <span>{{value.name}}</span>
                      <br>
                      <small>
                        <span *ngIf="value.league"
                          class="me-2 badge bg-primary">{{
                          value?.league?.name}}
                        </span>
                        <span class="text-muted me-2">{{value?.league?.countryName}}</span>
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-5">
                <div class="row">
                  <div class="col d-flex align-items-center text-md-end">
                    <small class="text-muted mr-2 w-100" i18n="Added date label">Added {{value.createdAt | date:'longDate'}}</small>
                  </div>
                  <div class="col-auto">
                    <div class="vr h-100"></div>
                  </div>
                  <div class="col-auto pl-0 d-flex align-items-center">
                    <a class="btn btn-md btn-link w-100" [routerLink]="['/teams/', tenantId , value.id]">
                      <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
                    </a>
                  </div>
                  <div class="col-auto pl-0 d-flex align-items-center"
                       *ngIf="!value.isBeenUsed">
                    <button type="button" class="btn btn-md btn-link w-100 text-danger"
                            (click)="deleteConfirmation = value.id" title="Delete" i18n-title="Delete button">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                  <div class="col-auto pl-0 d-flex align-items-center"
                       *ngIf="value.isBeenUsed">
                    <button type="button" class="btn btn-md btn-link w-100 text-white disabled">
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
<app-confirm-delete *ngIf="deleteConfirmation"
                    (confirm)="handleDeleteClick(deleteConfirmation);deleteConfirmation = null;"
                    (cancel)="deleteConfirmation = null"></app-confirm-delete>
