import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';
import { Tenant, TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { Transfer, TransfersRepository } from 'src/app/state/transfers.repository';
import { TransfersService } from 'src/app/state/transfers.service';

@Component({
  selector: 'app-transfer-create-page',
  templateUrl: './transfer-create-page.component.html',
  styleUrls: ['./transfer-create-page.component.scss']
})
export class TransferCreatePageComponent implements OnInit {
  transfer$: Observable<Transfer> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;
  tenants = new Array<Tenant>();
  tenantIds: string[] | null = null;
  tenantId: string | null = null;
  currentTennant?: Tenant;

  constructor(
    private transferService: TransfersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: TransfersRepository,
    private urlId: UrlIdResolverService,
    private tenantService: TenantsService,
    public tenantRepo: TenantsRepository,
    private auth: AuthRepository   
  ) { 
    this.getTenants();
  }

    ngOnInit(): void {
      this.getTenants();      
      this.transfer$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.transferService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
      this.activatedRoute.paramMap.subscribe({ 
        next: (value) => {        
          this.tenantId = value.get('tenant?');
        }    
      });
    }

    getTenants() {
      this.tenantIds = this.auth.getTenantIds();
      if(this.tenantIds && this.tenantIds?.length > 0) {      
        this.tenantIds.forEach(x => {
          this.tenantService.loadOne((x)).subscribe(y => this.tenants.push(y));
        });     
      }
    }

    createTransfer(transfer: Partial<Transfer>) {
      this.submitErrors = null;
      let updateResult: Observable<Transfer> | undefined;
      if(this.id === 'new'){
        updateResult = this.transferService.add(transfer, this.tenantId!);

      } else if(this.id){
        updateResult = this.transferService.update(this.id , transfer);
      }
      if (updateResult) {
        updateResult.subscribe({
          complete: () => {
            this.router.navigate(['/transfers/', this.tenantId]);
          },
          error: data => this.submitErrors = data
        });
      }
    }

}
