<div class="closemenu text-muted" (click)="menuclose()" i18n="Close menu label">
  Close Menu
</div>
<div class="sidebar">
  <div class="row">
    <div class="col-auto mx-auto text-center">
      <app-user-card
        class="mobile-card"
        size="md"
        (profileClick)="menuclose()"
      ></app-user-card>
      <app-user-card
        class="desktop-card"
        [size]="(ui.isMenuExpanded$ | async) ? 'md' : 'sm'"
        (profileClick)="menuclose()"
      ></app-user-card>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12">
      <ul class="nav nav-pills">
        <li class="nav-item" *ngIf="repo.isSuperAdmin$ | async">
          <a
            class="nav-link"
            routerLink="/tenants"
            routerLinkActive="active"
            (click)="menuclose()"
          >
            <div
              class="avatar avatar-40 rounded icon"
              title="Sports"
              i18n-title
            >
              <i class="bi bi-stack"></i>
            </div>
            <div class="col" i18n>Sports</div>
          </a>
        </li>

        <div>
          <li
            class="nav-item"
            *ngIf="(repo.isSuperAdmin$ | async) || (repo.isAnyAdmin$ | async)"
          >
            <a
              class="nav-link"
              routerLink="/users"
              routerLinkActive="active"
              (click)="menuclose()"
            >
              <div
                class="avatar avatar-40 rounded icon"
                title="Users"
                i18n-title="Label for users page"
              >
                <i class="bi bi-people"></i>
              </div>
              <div class="col" i18n="Label for users page">Users</div>
            </a>
          </li>
          <div
            *ngIf="(repo.isTenantAdmin$ | async) || (repo.isUser$ | async)"
            class="accordion accordion-flush"
            id="accordionMenu"
          >
            <div *ngFor="let item of tenants; let i = index">
              <div
                class="accordion-item nav-item"
                style="background-color: #2f4a5d"
              >
                <a
                  class="nav-link btn btn-md accordion-button"
                  [class.collapsed]="
                    !(
                      (item.id && ui.getCurrentTenantId() == item.id) ||
                      tenants.length === 1
                    )
                  "
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#flush-collapse' + i"
                  aria-expanded="false"
                  aria-controls="'flush-collapse' + i"
                  [attr.id]="'accordionHeader' + i"
                >
                  <app-entity-avatar
                    [img]="item.imagePath"
                    [alt]="item.name"
                    [avatarSize]="40"
                    icon="bi-stack"
                  ></app-entity-avatar>
                  <div class="col ms-2" i18n>{{ item?.name }}</div>
                </a>
                <div
                  style="background-color: #2f4a5d"
                  [attr.id]="'flush-collapse' + i"
                  class="accordion-collapse collapse"
                  [class.show]="
                    (item.id && ui.getCurrentTenantId() == item.id) ||
                    tenants.length === 1
                  "
                  [attr.aria-labelledby]="'accordionHeader' + i"
                  data-bs-parent="#accordionMenu"
                >
                  <div style="background-color: #2f4a5d" class="accordion-body">
                    <li
                      class="nav-item"
                      *ngIf="features.sport && (repo.isTenantAdmin$ | async)"
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/clubs/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Clubs"
                          i18n-title
                        >
                          <i class="bi bi-shield-shaded"></i>
                        </div>
                        <div class="col" i18n>Clubs</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="features.sport && (repo.isTenantAdmin$ | async)"
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/teams/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Teams"
                          i18n-title
                        >
                          <i class="bi bi-people"></i>
                        </div>
                        <div class="col" i18n>Teams</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="features.sport && (repo.isTenantAdmin$ | async)"
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/contacts/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Contacts"
                          i18n-title
                        >
                          <i class="bi bi-person-workspace"></i>
                        </div>
                        <div class="col" i18n>Contacts</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="features.sport && (repo.isTenantAdmin$ | async)"
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/transfers/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Transfers"
                          i18n-title
                        >
                          <i class="bi bi-briefcase"></i>
                        </div>
                        <div class="col" i18n>Transfers</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="features.sport && (repo.isTenantAdmin$ | async)"
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/currencies/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Currencies"
                          i18n-title
                        >
                          <i class="bi bi-cash-stack"></i>
                        </div>
                        <div class="col" i18n>Currencies</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="features.sport && (repo.isTenantAdmin$ | async)"
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/pdftemplate/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="PDF Template"
                          i18n-title
                        >
                          <i class="bi bi-file-earmark-pdf"></i>
                        </div>
                        <div class="col" i18n>PDF Template</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="
                        (features.sport && (repo.isTenantAdmin$ | async)) ||
                        (repo.isUser$ | async)
                      "
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/players/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Players"
                          i18n-title
                        >
                          <i class="bi bi-person-badge"></i>
                        </div>
                        <div class="col" i18n>Players</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="
                        (features.sport && (repo.isUser$ | async)) ||
                        (repo.isTenantAdmin$ | async)
                      "
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/requirements/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Requirements"
                          i18n-title
                        >
                          <i class="bi bi-funnel"></i>
                        </div>
                        <div class="col" i18n>Requirements</div>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="
                        (features.sport && (repo.isUser$ | async)) ||
                        (repo.isTenantAdmin$ | async)
                      "
                    >
                      <a
                        class="nav-link"
                        (click)="this.ui.toggleMenuOpened(true, item.id)"
                        routerLink="/noneedregistered/{{ item.id }}"
                        routerLinkActive="active"
                      >
                        <div
                          class="avatar avatar-40 rounded icon"
                          title="Nothing registered"
                          i18n-title
                        >
                          <i class="bi bi-file-text"></i>
                        </div>
                        <div class="col" i18n>Nothing registered</div>
                      </a>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<li class="nav-item" *ngIf="features.news">
                        <a class="nav-link" routerLink="/news" routerLinkActive='active' (click)="menuclose()">
                            <div class="avatar avatar-40 rounded icon"
                                title="News" i18n-title="Label for news page">
                                <i class="bi bi-file-earmark-text"></i>
                            </div>
                            <div class="col" i18n="Label for news page">News</div>
                        </a>
                    </li>-->
        </div>
        <hr class="bg-white" />
        <li
          class="nav-item"
          *ngIf="features.sport && (repo.isSuperAdmin$ | async)"
        >
          <a
            href
            class="nav-link"
            (click)="sync()"
            [class.disabled]="isSync"
            [class.text-danger]="isSyncError"
          >
            <div
              class="avatar avatar-40 rounded icon"
              title="ActiveCampaign"
              i18n
            >
              <i *ngIf="!isSync && !isSyncError" class="bi bi-arrow-repeat"></i>
              <i *ngIf="!isSync && isSyncError" class="bi bi-x-octagon"></i>
              <app-spinner *ngIf="isSync"></app-spinner>
            </div>
            <div class="col" i18n>ActiveCampaign</div>
          </a>
        </li>
        <li class="nav-item mb-0">
          <a
            *ngIf="!(repo.isImpersonating$ | async)"
            class="nav-link"
            href="#logout"
            (click)="logout()"
          >
            <div
              class="avatar avatar-40 rounded icon"
              title="Logout"
              i18n-title="Logout button"
            >
              <i class="bi bi-box-arrow-right"></i>
            </div>
            <div class="col" i18n="Logout button">Logout</div>
          </a>
          <a
            *ngIf="repo.isImpersonating$ | async"
            class="nav-link"
            href="#logout"
            (click)="unimpersonate()"
          >
            <div
              class="avatar avatar-40 rounded icon"
              title="Stop impersonating"
              i18n-title="Stop impersonation button"
            >
              <i class="bi bi-box-arrow-right"></i>
            </div>
            <div class="col" i18n="Stop impersonation button">
              Stop impersonating
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
