import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { ClubPageComponent } from '../modules/sport/pages/club-page/club-page.component';
import { ClubsPageComponent } from '../modules/sport/pages/clubs-page/clubs-page.component';
import { ContactInfoPageComponent } from '../modules/sport/pages/contact-info-page/contact-info-page.component';
import { ContactPageComponent } from '../modules/sport/pages/contact-page/contact-page.component';
import { ContactsPageComponent } from '../modules/sport/pages/contacts-page/contacts-page.component';
import { MatchesPageComponent } from '../modules/sport/pages/matches-page/matches-page.component';
import { PlayerInfoPageComponent } from '../modules/sport/pages/player-info-page/player-info-page.component';
import { PlayerPageComponent } from '../modules/sport/pages/player-page/player-page.component';
import { PlayersPageComponent } from '../modules/sport/pages/players-page/players-page.component';
import { RequirementCreatePageComponent } from '../modules/sport/pages/requirement-create-page/requirement-create-page.component';
import { RequirementsPageComponent } from '../modules/sport/pages/requirements-page/requirements-page.component';
import { TeamCreatePageComponent } from '../modules/sport/pages/team-create-page/team-create-page.component';
import { TeamsPageComponent } from '../modules/sport/pages/teams-page/teams-page.component';
import { TransferCreatePageComponent } from '../modules/sport/pages/transfer-create-page/transfer-create-page.component';
import { TransfersPageComponent } from '../modules/sport/pages/transfers-page/transfers-page.component';
import { ValutaUpdatePageComponent } from '../modules/sport/pages/valuta-update-page/valuta-update-page.component';
import { ValutasPageComponent } from '../modules/sport/pages/valutas-page/valutas-page.component';
import { FootballPlayerDetailComponent } from '../modules/sport/components/football-player-detail/football-player-detail.component';
import { HandballmanPlayerDetailComponent } from '../modules/sport/components/handballman-player-detail/handballman-player-detail.component';
import { HandballwomPlayerDetailComponent } from '../modules/sport/components/handballwom-player-detail/handballwom-player-detail.component';
import { IcehockeyPlayerDetailComponent } from '../modules/sport/components/icehockey-player-detail/icehockey-player-detail.component';
import { PdfPageComponent } from '../modules/sport/pages/pdf-page/pdf-page.component';
import { PdfEditPageComponent } from '../modules/sport/pages/pdf-edit-page/pdf-edit-page.component';
import { UserRoles } from '../state/auth.repository';
import { HomeGuard } from '../home.guard';
import { MailtesterComponent } from '../modules/sport/pages/mailtester/mailtester.component';
import { NoNeedRegPageComponent } from '../modules/sport/pages/no-need-reg-page/no-need-reg-page.component';
import { RequirementInfoPageComponent } from '../modules/sport/pages/requirement-info-page/requirement-info-page.component';

const routes: Routes = [
  {
    path: 'pins/Football/:name/:contact_id/:player_id',
    component: FootballPlayerDetailComponent,
  },
  {
    path: 'pins/Handball - Men/:name/:contact_id/:player_id',
    component: HandballmanPlayerDetailComponent,
  },
  {
    path: 'pins/Handball - Women/:name/:contact_id/:player_id',
    component: HandballwomPlayerDetailComponent,
  },
  {
    path: 'pins/Icehockey/:name/:contact_id/:player_id',
    component: IcehockeyPlayerDetailComponent,
  },
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'players/:tenant?',
        component: PlayersPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.User] },
      },
      {
        path: 'players/:tenant?/:id',
        component: PlayerInfoPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.User] },
      },
      {
        path: 'players/:tenant?/:id/edit',
        component: PlayerPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.User] },
      },
      {
        path: 'clubs/:tenant?',
        component: ClubsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'clubs/:tenant?/:id',
        component: ClubPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'contacts/:tenant?',
        component: ContactsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'contacts/:tenant?/:id',
        component: ContactInfoPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'contacts/:tenant?/:id/edit',
        component: ContactPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'transfers/:tenant?',
        component: TransfersPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'transfers/:tenant?/:id',
        component: TransferCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'requirements/:tenant?',
        component: RequirementsPageComponent,
        canActivate: [HomeGuard],
        data: {
          authorize: [
            UserRoles.Superadmin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
        },
      },
      {
        path: 'requirements/:tenant?/:id',
        component: RequirementCreatePageComponent,
        canActivate: [HomeGuard],
        data: {
          authorize: [
            UserRoles.Superadmin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
        },
      },
      {
        path: 'requirements/info/:tenant?/:id',
        component: RequirementInfoPageComponent,
        canActivate: [HomeGuard],
        data: {
          authorize: [
            UserRoles.Superadmin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
        },
      },
      {
        path: 'noneedregistered/:tenant?',
        component: NoNeedRegPageComponent,
        canActivate: [HomeGuard],
        data: {
          authorize: [
            UserRoles.Superadmin,
            UserRoles.TenantAdmin,
            UserRoles.User,
          ],
        },
      },
      {
        path: 'matches/:id/:createdAt',
        component: MatchesPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.User] },
      },
      {
        path: 'matches/:id',
        component: MatchesPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin, UserRoles.User] },
      },
      {
        path: 'contact-info/:tenantId?/:id',
        component: ContactInfoPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'teams/:tenant?',
        component: TeamsPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'teams/:tenant?/:id',
        component: TeamCreatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'currencies/:tenant?',
        component: ValutasPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'currencies/:tenant?/:id',
        component: ValutaUpdatePageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'pdftemplate/:tenant?',
        component: PdfPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'template/:id?',
        component: PdfEditPageComponent,
        canActivate: [AuthGuard],
        data: { authorize: [UserRoles.TenantAdmin] },
      },
      {
        path: 'mailtester',
        component: MailtesterComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class SportRoutingModule {}
