import { Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CreatedAtMatch,
  CreatedAtMatchesRepository,
} from 'src/app/state/dates.repository';
import { Match, MatchesRepository } from 'src/app/state/matches.repository';
import { MatchesService } from 'src/app/state/matches.service';

@Component({
  selector: 'app-matches-page',
  templateUrl: './matches-page.component.html',
  styleUrls: ['./matches-page.component.scss'],
})
export class MatchesPageComponent implements OnInit {
  match$?: Observable<Match> | null;
  match?: Match | null = null;
  id?: string;
  matchId?: string | null = null;
  submitErrors: string[] | null = null;
  createdAtMatchId?: string | null = null;
  hasBeenSent = false;

  constructor(
    public repo: MatchesRepository,
    private activatedRoute: ActivatedRoute,
    private service: MatchesService,
    public datesRepo: CreatedAtMatchesRepository
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((x) => {
      this.id = x.get('id')!;
    });
    this.service.loadDates(this.id).subscribe();
    this.loadData();
    this.checkCurrentMatch();
  }

  checkCurrentMatch() {
    let item = localStorage.getItem('storedMatchId');
    if (item !== '') {
      this.createdAtMatchId = localStorage.getItem('storedMatchId');
    }
    let sent = this.match?.contactMatches.find((x) => x.hasBeenSent === true);
    if (sent) {
      this.hasBeenSent = true;
    } else {
      this.hasBeenSent = false;
    }
  }

  ngDoCheck() {
    this.checkCurrentMatch();
  }

  reloadMatch($event?: string) {
    if ($event) {
      this.service.loadOneDirectly($event).subscribe((x) => {
        this.match = x;
        localStorage.setItem('storedMatchId', $event);
        this.createdAtMatchId = $event;
      });
    }
  }

  refreshPage() {
    window.location.reload();
  }

  loadData() {
    this.match?.contactMatches.map((x) => (this.hasBeenSent = x.hasBeenSent));
    let id = localStorage.getItem('storedMatchId');
    if (id !== '') {
      this.service.loadOneDirectly(id!).subscribe((x) => {
        this.match = x;
        this.createdAtMatchId = id;
      });
    } else if (!this.matchId && this.id) {
      this.match$ = this.service.loadOne(this.id);
      if (this.match$) {
        this.match$.subscribe((x) => {
          this.match = x;
          this.matchId = x.id;
          this.createdAtMatchId = x.id;
        });
      }
    }
  }

  sendToAc($event: Partial<Match>, isForEmail?: boolean) {
    if ($event) {
      this.service.sendToAc($event, isForEmail).subscribe({
        complete: () => {
          this.refreshPage();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
