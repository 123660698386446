import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import {
  clubSortOptions,
  ClubsRepository,
} from 'src/app/state/clubs.repository';
import { ClubsService } from 'src/app/state/clubs.service';
import { TenantsRepository } from 'src/app/state/tenants.repository';

@Component({
  selector: 'app-clubs-page',
  templateUrl: './clubs-page.component.html',
  styleUrls: ['./clubs-page.component.scss'],
})
export class ClubsPageComponent implements OnInit {
  sortOptions = clubSortOptions;
  exportFactory = () => this.service.export();
  id: string | null = null;
  filter: string | undefined;

  constructor(
    public service: ClubsService,
    public repo: ClubsRepository,
    private activatedRoute: ActivatedRoute,
    public tenantRepo: TenantsRepository
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant?');
        this.service.reloadPageWithFilter(this.id!).subscribe();
      },
    });
  }

  searchSubmit($event: string) {
    this.filter = $event;
    if ($event != null) {
      this.service
        .reloadPageWithFilter(`${this.id}`, undefined, $event)
        .subscribe();
    }
  }
}
