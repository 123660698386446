import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseReadService } from "./abstract/base-read.service";
import { BaseService } from "./abstract/base.service";
import { Valuta, ValutasRepository } from "./valutas.repository";

const API = '/api/valutas';

@Injectable({ providedIn: 'root' })
export class ValutasService extends BaseService<Valuta> {

  constructor(
    http: HttpClient,
    repo: ValutasRepository
  ) {
    super(API, http, repo);
  }
}