import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { UnauthorisedRepository, Click, PublicPlayer, WhoClicked } from "./unauthorised.repository";

const API = '/api/unauthorised';

@Injectable({ providedIn: 'root' })
export class UnauthorisedService {
  constructor(
    private http: HttpClient,
    private repo: UnauthorisedRepository) {
  }




  loadPlayer(id: string) {
    return this.http.get<PublicPlayer>(API + `/player/${id}`)
  }
  loadWhoClicked(contact_id:string, player_id: string) {
    return this.http.get<WhoClicked>(API + `/WhoClickedFull/${contact_id}/${player_id}`)

  }
  loadOnWhomClicked(contact_id: string, player_id: string ) {
    return this.http.get<WhoClicked>(API + `/OnWhomClickedFull/${contact_id}/${player_id}`)
  }

  count_clicks(client_id: string, player_id: string) {
    return this.http.get<number>(API + `/clicks/${client_id}/${player_id}`)
  }
  whoClicked(player_id: string) {
    return this.http.get<string[]>(API + `/WhoClicked/${player_id}`)
  }
  OnWhomClicked(player_id: string) {
    return this.http.get<string[]>(API + `/OnWhomClicked/${player_id}`)
  }
  new_click(Entity:Click) {
    return this.http.post(API + `/new_click`, Entity)
  }

  preview(tenant_id: string, player_id:string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get<Blob>(`${API}/getpdf/${tenant_id}/${player_id}`, { headers: headers, responseType: 'blob' as any }).pipe(
      map(resp => {
        const blob = new Blob([resp], { type: "application/pdf" });
        return window.URL.createObjectURL(blob);
      })
    );
  }
}
