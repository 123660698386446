<!-- profile picture -->
<app-user-header-image></app-user-header-image>

<div class="row py-4 mb-4">
  <div class="col d-grid" *ngIf="repo.isSuperAdmin$ | async">
    <app-impersonation-modal></app-impersonation-modal>
  </div>
  <div class="col d-grid">
    <a routerLink="/profile/edit" class="btn btn-primary shadow-sm" i18n="Edit profile button">Edit profile</a>
  </div>
</div>
