import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import {
  RequirementsFilter,
  Requirement,
  RequirementsRepository,
  trackReqRequestsStatus,
  skipWhileReqsCached,
} from './requirements.repository';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { PaginationData } from '@ngneat/elf-pagination';
const API = '/api/requirements';

@Injectable({ providedIn: 'root' })
export class RequirementsService extends BaseService<Requirement> {
  constructor(
    http: HttpClient,
    repo: RequirementsRepository,
    private repoBase: RequirementsRepository
  ) {
    super(API, http, repo);
  }

  loadbyfilter(
    RequirementsFilter: Partial<RequirementsFilter>
  ): Observable<PaginationData & { data: Requirement[] }> {
    return this.http
      .post<PaginationData & { data: Requirement[] }>(
        `${API}/filter`,
        RequirementsFilter
      )
      .pipe(
        tap((res) => {
          this.repoBase.addPage(res);
        }),
        trackReqRequestsStatus(this.repo.name)
      );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.api}/${id}`).pipe(
      tap(() => this.repo.remove(id)),
      this.repo.trackOne(id)
    );
  }
}
