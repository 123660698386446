import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { DatalistOption } from '../modules/shared/components/datalist-select/datalist-select.component';
import { BaseRepository } from './abstract/base.repository';

export interface Transfer {
  id: string;
  createdAt: Date;
  name: string;  
  years: string;
  fromDate: Date;
  tenantId?: string;
  toDate: Date;  
}

export const transferSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' },
  { label: $localize `:Sort label Surname:Years`, property: 'years' },
  { label: $localize `:Sort label Date:Date`, property: 'createdAt' }
];


@Injectable({ providedIn: 'root' })
export class TransfersRepository extends BaseRepository<Transfer> {

  constructor() {
    super('transfers', transferSortOptions);
  }

}