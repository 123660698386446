import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Contact, Contract } from 'src/app/state/contacts.repository';
import { PlayersRepository } from 'src/app/state/players.repository';
import { PlayersService } from 'src/app/state/players.service';
import { UnauthorisedService } from 'src/app/state/unauthorised.service';
import { UnauthorisedRepository, Click, PublicPlayer,WhoClicked } from "src/app/state/unauthorised.repository";


@Component({
  selector: 'app-player-info-page',
  templateUrl: './player-info-page.component.html',
  styleUrls: ['./player-info-page.component.scss']
})
export class PlayerInfoPageComponent implements OnInit {
  clicked = 1;
  clickedDetails = 'PlayerContract';
  clickedContract: Contract | null = null;
  labelContract = '1 Contracts - Player'
  contact$: Observable<Contact> | null = null;
  contact: Contact | null = null;
  id?: string;
  submitErrors: string[] | null = null;
  icon: string = 'bi bi-person-workspace';
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';
  whoclickedId: string[] = [];
  whoclicked: WhoClicked[] = [];

  background: string | null = null;

  constructor(
    public repo: PlayersRepository,
    private activatedRoute: ActivatedRoute,
    private service: PlayersService,
    private UnauhorisedService: UnauthorisedService,
    private urlService: UrlIdResolverService,
    public env: EnvState
  ) { }

  ngOnInit(): void {
    this.contact$ = this.urlService.resolve(
      this.activatedRoute.paramMap,
      id => this.service.loadOne(id),
      id => this.repo.one(id),
      id => this.repo.statusOne(id),
      id => this.id = id
    );
    this.contact$.subscribe(x => this.contact = x);
    this.UnauhorisedService.whoClicked(`${this.id}`).subscribe(x => {
      this.whoclickedId = x;
        this.whoclickedId?.forEach(y => {
          this.UnauhorisedService.loadWhoClicked(`${this.id}`, y).subscribe(z => {

            if (z!=null) {
              this.whoclicked.push(z);
            }
          })
      })  
    });
  }

  getContractsOfName(contracts: Contract[], name: string): Contract[] {
    return contracts.filter(x => x.contractName === name);
  }

  checkPipesInStrings(attr: string | undefined | null) {
    if(attr) {
      return attr.split("||").join("");
    }
    return "-";
  }

  goTo(url: string) {
    if(url.includes("https://")) {
      window.open(`https://${url.replace("https://", "")}/`);
    } 
    else if(url.includes("http://")) {
      window.open(`http://${url.replace("http://", "")}/`);
    }
    else {
      window.open(`https://${url}/`);
    }    
  }
}
