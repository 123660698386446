<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create trailer label">
  Create requirement
</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit trailer label">
  Edit requirement
</h2>
<div *ngIf="id === 'new' || (requirement$ | async)" class="row mb-4">
  <app-spinner *ngIf="!loadedCheck()"></app-spinner>
  <div *ngIf="loadedCheck()">
    <app-requirement-create-form
      [editedRequirement]="requirement$ | async"
      [positions]="positions!"
      [contractStatuses]="contractStatuses!"
      [countries]="countries!"
      [playerLevels]="playerLevels!"
      [clubs]="clubs!"
      [transfers]="transfers!"
      [allContacts]="contacts!"
      [valutas]="valutas!"
      [tenantId]="this.tenantId"
      [checker]="true"
      (requirementSubmit)="createRequirement($event)"
    ></app-requirement-create-form>
    <app-error-alert
      title="Could not submit the requirement info"
      i18n-title="Could not submit requirement message"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
