import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { EnvState } from '../../shared/helpers/env-state';

@Injectable()
export class ImageInterceptor implements HttpInterceptor {
  static readonly resourcesRegex = /^\/?resources.*/i;

  constructor(private env: EnvState) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (this.env.apiUrl && event instanceof HttpResponse) {
          this.appendBaseUrl(event.body);
        }
        return event;
      })
    );
  }

  public static buildUrl(url: string, baseUrl: string) {
    return `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`;
  }

  appendBaseUrl(object: any) {
    if (!object || !(object instanceof Object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.appendBaseUrl(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.appendBaseUrl(item);
        }
      }

      if (value instanceof Object) {
        this.appendBaseUrl(value);
      }

      if (typeof value === 'string'
        && (key === 'imagePath' || key === 'url')
        && ImageInterceptor.resourcesRegex.test(value)) {
        object[key] = ImageInterceptor.buildUrl(value, this.env.apiUrl);
      }
    }
  }
}
