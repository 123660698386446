<div class="row mb-4">
  <div class="col">
    <h2 class="text-primary" i18n>Sports</h2>
  </div>
  <!--<div class="col-auto d-flex align-items-center">
    <a routerLink="/tenants/new" class="btn btn-sm btn-success">
      <i class="bi bi-plus"></i> <ng-container i18n="Add tenant button @@add">Add</ng-container>
    </a>
  </div>-->
</div>
<div class="row mb-4">
  <div class="col-auto ms-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions" 
      [sorting]="repo.sort$ | async"
      (sortingChange)="repo.setSort($event)"></app-sort-dropdown>
  </div>
</div>

<div class="row mb-4">
  <div class="col">
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-tenant-list *ngIf="!(repo.isLoading$ | async)" 
      [tenants]="repo.tenantsSorted$ | async"></app-tenant-list>
  </div>
</div>
