import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseReadService } from "./abstract/base-read.service";
import { ContractStatus, ContractStatusesRepository } from "./contractstatuses.repository";


const API = '/api/contractStatuses';

@Injectable({ providedIn: 'root' })
export class ContractStatusesService extends BaseReadService<ContractStatus> {

  constructor(
    http: HttpClient,
    repo: ContractStatusesRepository
  ) {
    super(API, http, repo);
  }
}