import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Club, ClubsRepository } from 'src/app/state/clubs.repository';
import { ClubsService } from 'src/app/state/clubs.service';
import { Contact, ContactsRepository } from 'src/app/state/contacts.repository';
import { ContactsService } from 'src/app/state/contacts.service';
import {
  ContractStatus,
  ContractStatusesRepository,
} from 'src/app/state/contractstatuses.repository';
import { ContractStatusesService } from 'src/app/state/contractstatuses.service';
import {
  CountriesRepository,
  Country,
} from 'src/app/state/countries.repository';
import { CountriesService } from 'src/app/state/countries.service';
import {
  PlayerLevel,
  PlayerLevelsRepository,
} from 'src/app/state/playerlevels.repository';
import { PlayerLevelsService } from 'src/app/state/playerlevels.service';
import {
  Position,
  PositionsRepository,
} from 'src/app/state/positions.repository';
import { PositionsService } from 'src/app/state/positions.service';
import {
  Requirement,
  RequirementsRepository,
} from 'src/app/state/requirements.repository';
import { RequirementsService } from 'src/app/state/requirements.service';
import {
  Transfer,
  TransfersRepository,
} from 'src/app/state/transfers.repository';
import { TransfersService } from 'src/app/state/transfers.service';
import { Valuta, ValutasRepository } from 'src/app/state/valutas.repository';
import { ValutasService } from 'src/app/state/valutas.service';

@Component({
  selector: 'app-requirement-create-page',
  templateUrl: './requirement-create-page.component.html',
  styleUrls: ['./requirement-create-page.component.scss'],
})
export class RequirementCreatePageComponent implements OnInit {
  requirement$: Observable<Requirement> | null = null;
  tenantId: string | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;
  countries?: Country[] | null = null;
  contractStatuses?: ContractStatus[] | null = null;
  playerLevels?: PlayerLevel[] | null = null;
  positions?: Position[] | null = null;
  clubs?: Club[] | null = null;
  transfers?: Transfer[] | null = null;
  contacts?: Contact[] | null = null;
  valutas?: Valuta[] | null = null;

  isAllLoadedCount: number = 0;

  constructor(
    private requirementService: RequirementsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public repo: RequirementsRepository,
    private urlId: UrlIdResolverService,
    private countryService: CountriesService,
    public countryRepo: CountriesRepository,
    private contractStatusService: ContractStatusesService,
    public contractStatusRepo: ContractStatusesRepository,
    private playerLevelService: PlayerLevelsService,
    public playerLevelRepo: PlayerLevelsRepository,
    private positionService: PositionsService,
    public positionRepo: PositionsRepository,
    private clubService: ClubsService,
    public clubRepo: ClubsRepository,
    private transferService: TransfersService,
    public transferRepo: TransfersRepository,
    private contactService: ContactsService,
    public contactRepo: ContactsRepository,
    private valutaService: ValutasService,
    public valutaRepo: ValutasRepository
  ) {}

  ngOnInit(): void {
    this.requirement$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.requirementService.loadOne(id),
      (id) => this.repo.one(id),
      (id) => this.repo.statusOne(id),
      (id) => (this.id = id)
    );
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.tenantId = value.get('tenant?');

        this.loadData(value.get('tenant?'));
      },
    });
  }

  loadData(id: string | null) {
    if (id) {
      //let isNew = this.id === 'new' ? true : false;
      this.countryService.loadAllOptions(id).subscribe((x) => {
        this.countries = x;
        this.isAllLoadedCount += 1;
      });

      this.contractStatusService.loadAllOptions(id).subscribe((x) => {
        this.contractStatuses = x;
        this.isAllLoadedCount += 1;
      });

      this.playerLevelService.loadAllOptions(id).subscribe((x) => {
        this.playerLevels = x;
        this.isAllLoadedCount += 1;
      });

      this.positionService.loadAllOptions(id).subscribe((x) => {
        this.positions = x;
        this.isAllLoadedCount += 1;
      });

      this.positionService.loadAllOptions(id).subscribe((x) => {
        this.positions = x;
        this.isAllLoadedCount += 1;
      });

      this.clubService.loadAllOptions(id).subscribe((x) => {
        this.clubs = x;
        this.isAllLoadedCount += 1;
      });

      this.transferService.loadAllOptions(id).subscribe((x) => {
        this.transfers = x;
        this.isAllLoadedCount += 1;
      });

      this.contactService.loadAllOptions(id).subscribe((x) => {
        this.contacts = x;
        this.isAllLoadedCount += 1;
      });

      this.valutaService.loadAllOptions(id).subscribe((x) => {
        this.valutas = x;
        this.isAllLoadedCount += 1;
      });
    }
    // else {
    //   this.countryService.load().subscribe();
    //   this.contractStatusService.load().subscribe();
    //   this.playerLevelService.load().subscribe();
    //   this.positionService.load().subscribe();
    //   this.clubService.load().subscribe();
    //   this.transferService.load().subscribe();
    //   this.contactService.load().subscribe();
    //   this.valutaService.load().subscribe();
    // }
  }

  loadedCheck() {
    return this.isAllLoadedCount >= 9;
  }

  createRequirement(requirement: Partial<Requirement>) {
    this.submitErrors = null;
    let updateResult: Observable<Requirement> | undefined;
    if (this.id === 'new') {
      updateResult = this.requirementService.add(requirement, this.tenantId!);
    } else if (this.id) {
      updateResult = this.requirementService.update(this.id, requirement);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/requirements/', this.tenantId]);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
