import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { Contact } from 'src/app/state/contacts.repository';
import { ContactMatch } from 'src/app/state/matches.repository';

@Component({
  selector: 'app-contact-preview-card',
  templateUrl: './contact-preview-card.component.html',
  styleUrls: ['./contact-preview-card.component.scss']
})
export class ContactPreviewCardComponent implements OnInit {
  @Input() contact: Contact | null = null;
  @Input() contactMatch?: ContactMatch | null = null;
  @Output() contactEmitter = new EventEmitter<string>();
  
  constructor(public env: EnvState) {  }
  icon: string = 'bi bi-person-workspace';
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';
  ngOnInit(): void {    
  }

  goTo(url: string) {
    window.open(url);
  }

  getAvg(contactMatch: ContactMatch): number {
    if(contactMatch) {
      let avg = Math.round(contactMatch.contractMark + contactMatch.financeMark + contactMatch.personalMark + contactMatch.playerPlanMark) / 4;
      if(avg) {
        return avg;
      }
      return 0;
    }
    return 0;
  } 
}
