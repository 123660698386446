import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import { ContactsRepository } from 'src/app/state/contacts.repository';
import { ContactsService } from 'src/app/state/contacts.service';
import {
  contactsSortOptions,
  PlayersRepository,
} from 'src/app/state/players.repository';
import { PlayersService } from 'src/app/state/players.service';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';

@Component({
  selector: 'app-players-page',
  templateUrl: './players-page.component.html',
  styleUrls: ['./players-page.component.scss'],
})
export class PlayersPageComponent implements OnInit {
  sortOptions = contactsSortOptions;
  exportFactory = () => this.service.export();
  id: string | null = null;
  tenantName: string | null = null;
  filter: string | undefined;

  constructor(
    public service: PlayersService,
    public repo: PlayersRepository,
    public auth: AuthRepository,
    private activatedRoute: ActivatedRoute,
    public tenantRepo: TenantsRepository,
    public tenantsService: TenantsService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant?');
        this.service.reloadPageWithFilter(this.id!).subscribe((x) => {
          this.setTenantName();
        });
      },
    });
  }

  searchSubmit($event: string) {
    this.filter = $event;
    if ($event != null) {
      this.service
        .reloadPageWithFilter(`${this.id}`, undefined, $event)
        .subscribe();
    }
  }
  setTenantName() {
    if (this.id) {
      this.tenantsService.loadTenant(this.id).subscribe((y) => {
        this.tenantName = y.name;
      });
    }
  }
}
