import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { CreatedAtMatch } from 'src/app/state/dates.repository';
import { Match, SentToACMatch } from 'src/app/state/matches.repository';

@Component({
  selector: 'app-matches-card',
  templateUrl: './matches-card.component.html',
  styleUrls: ['./matches-card.component.scss'],
})
export class MatchesCardComponent implements OnInit {
  submitErrors: string[] | null = null;
  @Input() match?: Match | null = null;
  contactIds = new Array<string>();
  isClicked = false;
  @Input() createdAtMatchId?: string | null = null;
  createdAtOptions: DatalistOption[] | null = null;
  @Output() matchReload = new EventEmitter<string>();
  @Output() matchSender = new EventEmitter<Partial<Match>>();
  @Output() matchSenderInfo = new EventEmitter<Partial<Match>>();
  @Input() hasBeenSent?: boolean;
  copied = false;
  infoToCopy = '';

  @Input() set dates(value: CreatedAtMatch[] | null) {
    if (!value) {
      this.createdAtOptions = null;
    } else {
      this.createdAtOptions = value
        .map((x) => ({
          value: x.id,
          label: x.createdAt.toLocaleString(),
        }))
        .reverse();
    }
  }
  constructor(public env: EnvState) {}

  goTo(url: string) {
    window.open(url);
  }
  ngOnInit(): void {
    this.checkCurrentMatch();
    this.match?.contactMatches.find((x) => x.contactId === '');
    this.buildInfoToCopy();
  }

  checkCurrentMatch() {
    let item = localStorage.getItem('storedMatchId');
    if (item !== '') {
      this.createdAtMatchId = item;
    }
  }

  @HostListener('click')
  onClick(): void {
    if (!this.infoToCopy) return;
    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData! || window;
      var pattern = '&#13;&#10;',
        re = new RegExp(pattern, 'g');
      clipboard.setData(
        'text',
        this.infoToCopy ? this.infoToCopy.replace(re, '\r\n') : ''
      );
      e.preventDefault();
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
    this.copied = true;
  }

  addContactIdToMatch(id: string) {
    if (this.contactIds?.length > 0) {
      let exist = this.contactIds.find((x) => x === id);
      if (exist) {
        const index = this.contactIds.indexOf(id, 0);
        if (index > -1) {
          this.contactIds.splice(index, 1);
        }
      } else {
        this.contactIds.push(id);
      }
    } else {
      this.contactIds.push(id);
    }
  }

  updateValue(value: any) {
    this.createdAtMatchId = value;
    this.matchReload.emit(value);
    this.createdAtMatchId = localStorage.getItem('storedMatchId');
  }

  sendToAcInfo(match?: Partial<Match> | null) {
    if (match && this.contactIds.length > 0) {
      this.matchSenderInfo.emit({
        id: match.id,
        contactIds: this.contactIds,
      });
    } else {
      this.submitErrors = ['Please add contacts to your match'];
    }
  }

  buildInfoToCopy() {
    let contactId = this.match?.requirement.contactId;
    let codedNewLine = '&#13;&#10;';
    let siteUrl = this.env.production
      ? 'https://empower.peopleinsport.eu/pins/'
      : 'https://localhost:4200/pins/';
    if (contactId && this.match?.contactMatches) {
      this.match.contactMatches.forEach((x) => {
        if (x.hasBeenSent && !x.isForEmail) {
          let tenantName = x.tenant?.name;
          tenantName = tenantName.charAt(0).toUpperCase() + tenantName.slice(1);
          const contactName = `${x.contact.firstName} ${x.contact.lastName}`;
          const contactPath = `${siteUrl}${tenantName}/${contactName}/cv/${x.contactId}`;
          const newLine = codedNewLine;

          this.infoToCopy =
            this.infoToCopy === ''
              ? `${this.infoToCopy}${contactName}${newLine}${contactPath}${newLine}`
              : `${this.infoToCopy}${newLine}${contactName}${newLine}${contactPath}${newLine}`;
        }
      });
    }
  }

  sendToAc(match?: Partial<Match> | null) {
    if (match && this.contactIds.length > 0) {
      this.matchSender.emit({
        id: match.id,
        contactIds: this.contactIds,
      });
    } else {
      this.submitErrors = ['Please add contacts to your match'];
    }
  }
}
