<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
  <div class="row mb-2">
    <div class="col">
        <h6 class="my-1" i18n="Basic info label">Basic Information</h6>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="name" type="text" class="form-control"
               placeholder="Name" i18n-placeholder="User name placeholder @@name"/>
        <label for="name" i18n="@@name">Name</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="surname" type="text" class="form-control"
               placeholder="Surname" i18n-placeholder="User surname placeholder @@userSurname"/>
        <label for="surname" i18n="@@userSurname">Surname</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div [appValidateState]="form.get('email')" class="form-group form-floating mb-3">
        <input formControlName="email" type="email" class="form-control" [attr.disabled]="initialValue"
               placeholder="Email" i18n-placeholder="User email placeholder @@userEmail"/>
        <label for="email" i18n="@@userEmail">Email</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="phone" type="tel" class="form-control"
               placeholder="Phone number" i18n-placeholder="User phone number placeholder @@userPhoneNum"/>
        <label for="phone" i18n="@@userPhoneNum">Phone number</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="picture" type="file" class="form-control"
               placeholder="Profile picture" i18n-placeholder="User profile picture placeholder @@userProfilePict"/>
        <label for="picture" i18n="userProfilePict">Profile picture</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="location" type="text" class="form-control"
               placeholder="Location" i18n-placeholder="User location placeholder @@userLocation"/>
        <label for="location" i18n="@@userLocation">Location</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="title" type="text" class="form-control"
               placeholder="Title" i18n-placeholder="Title placeholder @@title"/>
        <label for="title" i18n="@@title">Title</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <input formControlName="birthday" type="date" class="form-control"
               placeholder="Date of birth" i18n-placeholder="User birthday @@userBirthday"/>
        <label for="birthday" i18n="@@userBirthday">Date of birth</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="!initialValue">
      <div [appValidateState]="form.get('password')" class="form-group form-floating mb-3">
        <input formControlName="password" type="password" class="form-control"
               placeholder="Password" required i18n-placeholder="User password @@userPassword"/>
        <label for="password" i18n="@@userPassword">Password</label>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4" *ngIf="(authRepo.isAnyAdmin$ | async)">
      <div class="form-group form-floating mb-3">
        <app-datalist-select-tenants class="app-datalist-select-tenants-double-height" [options]="tenantOptions"
          [(activeValue)]="form.value.tenant"
          [multiple]="true"
          (activeValueChange)="updateValue('tenant', $event)"
          label="Tenant" i18n-label="Label tenant"></app-datalist-select-tenants>
      </div>
    </div>
  </div>
  <div class="row mb-2" *ngIf="roleOptions && roleOptions.length">
    <div class="col">
        <h6 class="my-1" i18n="Label roles">Roles</h6>
    </div>
  </div>
  <div class="row mb-3" *ngIf="roleOptions && roleOptions.length">
    <div class="col">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <div class="card">
          <ul class="list-group list-group-horizontal bg-none">
              <li class="no-outer-border list-group-item flex-fill w-100"
                *ngFor="let role of roleOptions; let roleIndex = index" formArrayName="roles">
                  <div class="row">
                      <div class="col-auto pr-0 align-self-center text-end">
                          <div class="form-check form-switch">
                              <input class="form-check-input" type="checkbox"
                                [formControlName]="roleIndex" [attr.id]="'checkbox-' + role">
                              <label class="form-check-label" [attr.for]="'checkbox-' + role"></label>
                          </div>
                      </div>
                      <div class="col">
                          <h6 class="mb-1">{{ ROLE_NAMES[role] }}</h6>
                          <p class="text-muted small">{{ ROLE_DESCRIPTIONS[role] }}</p>
                      </div>
                  </div>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="@@save">Save</button>
      </div>
    </div>
  </div>
</form>
