import { Injectable } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Contact } from './contacts.repository';
import { Requirement } from './requirements.repository';

export interface PDFTemplate {
  id: string;
  tenantid: string;
  path: string;
  name: string;
  file: File ;
}

export interface IPreview {
  path: string;
  name: string;
}

@Injectable({ providedIn: 'root' })
export class PDFTemplateRepository  {

  constructor() {
  }

}
