import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordChangeRequest } from 'src/app/state/auth.repository';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss']
})
export class ChangePasswordFormComponent implements OnInit {
  form?: FormGroup;

  @Output() passwordSubmit = new EventEmitter<PasswordChangeRequest>();
  @Input() needCurrentPassword = true;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.needCurrentPassword) {
      this.form = this.formBuilder.group({
        currentPassword: ['', Validators.required],
        password: ['', Validators.required],
      });
    }
    else {
      this.form = this.formBuilder.group({
        password: ['', Validators.required],
      });
    }
  }

  onSubmit() {
    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.needCurrentPassword) {
      this.passwordSubmit.emit({
        password: this.form?.value?.password,
        currentPassword: this.form?.value?.currentPassword,
      });
    }
    else {
      this.passwordSubmit.emit({
        password: this.form?.value?.password,
        currentPassword: ''
      });
    }
    this.form?.markAsUntouched();
  }
}
