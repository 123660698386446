<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <li
          app-model-card
          *ngFor="let value of entities"
          class="list-group-item"
          [title]="value.name"
          [subtitle]="CONTACTS + value.contacts.length"
          [badge]="value.tenantName"
          icon="bi-shield-shaded"
          [image]="
            value.clubLogoUrl ? env.apiUrl + '/' + value.clubLogoUrl : undefined
          "
          [date]="value.createdAt"
          [id]="value.id"
          [isDeleteDisabled]="false"
          [notForNoNeed]="notForNoNeed"
          (deleteClick)="deleteConfirmation = value.id"
          routerLinkBase="/clubs/{{ tenantId }}"
          routerLinkNoNeed="/requirements/{{ tenantId }}/new"
        ></li>
      </ul>
    </div>
  </div>
</div>
<app-confirm-delete
  *ngIf="deleteConfirmation"
  (confirm)="handleDeleteClick(deleteConfirmation); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
