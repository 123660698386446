import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import {
  AuthRepository,
  Office365Settings,
} from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  title: any;
  error: string | null = null;
  testsLocation = 'localhost:9876';
  settings$: Observable<Office365Settings> | null = null;
  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
    rememberMe: false,
  });

  URL: string = '';
  Scope: string = '';
  ClientID: string = '';

  private returnUrl$: Observable<string>;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public env: EnvState,
    private http: HttpClient
  ) {
    this.returnUrl$ = this.route.queryParamMap.pipe(
      map((params) => params.get('returnUrl') || '/')
    );
  }

  ngOnInit(): void {
    if (window.location.host !== this.testsLocation) {
      this.route.paramMap.subscribe({
        next: (value) => {
          this.error = value.get('error');
        },
      });
    }
    this.loadSettings();
    if (
      (!this.ClientID || !this.URL || !this.Scope) &&
      !localStorage.getItem('refreshed') &&
      window.location.host !== this.testsLocation
    ) {
      localStorage['refreshed'] = true;
      window.location.reload();
    } else {
      localStorage.removeItem('refreshed');
    }

    this.repo.isAuthenticated$
      .pipe(
        filter((x) => x),
        take(1),
        withLatestFrom(this.returnUrl$)
      )
      .subscribe(([_, returnUrl]) => {
        this.router.navigateByUrl(returnUrl);
      });
  }

  loadSettings() {
    this.settings$ = this.http.get<Office365Settings>('/api/auth/office');

    this.http.get<Office365Settings>('/api/auth/office').subscribe((x) => {
      this.ClientID = x.clientId;
      this.Scope = x.scope;
      this.URL = x.url;
    });
  }

  private showTooltip() {
    var tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltiptriggerList.map(function (e: any) {
      return new bootstrap.Tooltip(e);
    });
  }

  private renderError(err: any) {
    this.title = err.error;
    setTimeout(this.showTooltip);
  }

  login(): void {
    this.auth
      .login(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.loginForm.value.rememberMe
      )
      .subscribe({
        error: (err) => this.renderError(err),
      });
    this.router.navigate([
      `/requirements/00000000-0000-0000-0000-000000000000`,
    ]);
  }
  navigateToOffice() {
    this.router.navigate([
      'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id={{settings.clientId}}&response_type=code&redirect_uri={{settings.url}}/office365auth&response_mode=query&scope={{settings.scope}}&state=12345',
    ]);
  }
}
