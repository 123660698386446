import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

var _appDatalistSelectNextId = 0;

export type DatalistOption = { label: string, sublabel?: string, value: any };

const DELETED_RECORD = $localize `:Dropdown label for a deleted options:[Deleted record]`;
const DELETED_RECORD_SUBTITLE = $localize `:Dropdown description for a deleted options:This option has been removed from the database`;

@Component({
  selector: 'app-datalist-select',
  templateUrl: './datalist-select.component.html',
  styleUrls: ['./datalist-select.component.scss']
})
export class DatalistSelectComponent implements OnInit {
  @Input() multiple = false;
  @Input() activeValue: any | undefined;
  @Output() activeValueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() label: string | undefined;
  @Input() options: DatalistOption[] | null = [];

  id = `appDatalistSelect_${_appDatalistSelectNextId++}`;


  constructor() { }

  ngOnInit(): void {
    if (!this.activeValue) {
      this.activeValue = undefined;
    } else {
      const values: any[] = this.multiple ? this.activeValue : [ this.activeValue ];
      // If any deleted/unknown value are pre-selected,
      // we display them in the list as such.
      values.forEach(value => {
        if (!this.options?.find(x => x.value === value)) {
          this.options?.unshift({
            label: DELETED_RECORD,
            sublabel: DELETED_RECORD_SUBTITLE,
            value
          });
        }
      });
    } 
  }
}
