import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlayersPageComponent } from './pages/players-page/players-page.component';
import { PlayersListComponent } from './components/players-list/players-list.component';
import { ModelCardComponent } from './components/model-card/model-card.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClubsPageComponent } from './pages/clubs-page/clubs-page.component';
import { ClubsListComponent } from './components/clubs-list/clubs-list.component';
import { ClubPageComponent } from './pages/club-page/club-page.component';
import { TransferCreatePageComponent } from './pages/transfer-create-page/transfer-create-page.component';
import { TransfersListComponent } from './components/transfers-list/transfers-list.component';
import { TransfersPageComponent } from './pages/transfers-page/transfers-page.component';
import { TransferCreateFormComponent } from './components/transfer-create-form/transfer-create-form.component';
import { ClubEditFormComponent } from './components/club-edit-form/club-edit-form.component';
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { ContactsListComponent } from './components/contacts-list/contacts-list.component';
import { ContactEditFormComponent } from './components/contact-edit-form/contact-edit-form.component';
import { RequirementsPageComponent } from './pages/requirements-page/requirements-page.component';
import { RequirementCreatePageComponent } from './pages/requirement-create-page/requirement-create-page.component';
import { RequirementsListComponent } from './components/requirements-list/requirements-list.component';
import { RequirementCreateFormComponent } from './components/requirement-create-form/requirement-create-form.component';
import { MatchesPageComponent } from './pages/matches-page/matches-page.component';
import { ContactPreviewCardComponent } from './components/contact-preview-card/contact-preview-card.component';
import { MatchesCardComponent } from './components/matches-card/matches-card.component';
import { ContactInfoPageComponent } from './pages/contact-info-page/contact-info-page.component';
import { TeamCreatePageComponent } from './pages/team-create-page/team-create-page.component';
import { TeamsPageComponent } from './pages/teams-page/teams-page.component';
import { TeamCreateFormComponent } from './components/team-create-form/team-create-form.component';
import { TeamsListComponent } from './components/teams-list/teams-list.component';
import { ValutasPageComponent } from './pages/valutas-page/valutas-page.component';
import { ValutaUpdatePageComponent } from './pages/valuta-update-page/valuta-update-page.component';
import { ValutasListComponent } from './components/valutas-list/valutas-list.component';
import { ValutasEditFormComponent } from './components/valutas-edit-form/valutas-edit-form.component';
import { PlayerPageComponent } from './pages/player-page/player-page.component';
import { PlayerInfoPageComponent } from './pages/player-info-page/player-info-page.component';
import { PlayerEditFormComponent } from './components/player-edit-form/player-edit-form.component';
import { HandballmanPlayerDetailComponent } from './components/handballman-player-detail/handballman-player-detail.component';
import { FootballPlayerDetailComponent } from './components/football-player-detail/football-player-detail.component';
import { IcehockeyPlayerDetailComponent } from './components/icehockey-player-detail/icehockey-player-detail.component';
import { HandballwomPlayerDetailComponent } from './components/handballwom-player-detail/handballwom-player-detail.component';
import { SortTableComponent } from './components/sort-table/sort-table.component';
import { PdfPageComponent } from './pages/pdf-page/pdf-page.component';
import { PdfCardComponent } from './components/pdf-card/pdf-card.component';
import { PdfEditPageComponent } from './pages/pdf-edit-page/pdf-edit-page.component';
import { PdfEditFormComponent } from './components/pdf-edit-form/pdf-edit-form.component';
import { NoNeedRegPageComponent } from './pages/no-need-reg-page/no-need-reg-page.component';
import { RequirementInfoPageComponent } from './pages/requirement-info-page/requirement-info-page.component';
import { RequirementInfoComponentComponent } from './components/requirement-info-component/requirement-info-component.component';

@NgModule({
  declarations: [
    PlayersPageComponent,
    PlayersListComponent,
    ModelCardComponent,
    ClubsPageComponent,
    ClubsListComponent,
    ClubPageComponent,
    TransferCreatePageComponent,
    TransfersListComponent,
    TransfersPageComponent,
    TransferCreateFormComponent,
    ClubEditFormComponent,
    ContactsPageComponent,
    ContactPageComponent,
    ContactsListComponent,
    ContactEditFormComponent,
    RequirementsPageComponent,
    RequirementCreatePageComponent,
    RequirementsListComponent,
    RequirementCreateFormComponent,
    MatchesPageComponent,
    ContactPreviewCardComponent,
    MatchesCardComponent,
    ContactInfoPageComponent,
    TeamCreatePageComponent,
    TeamsPageComponent,
    TeamCreateFormComponent,
    TeamsListComponent,
    ValutasPageComponent,
    ValutaUpdatePageComponent,
    ValutasListComponent,
    ValutasEditFormComponent,
    PlayerPageComponent,
    PlayerInfoPageComponent,
    PlayerEditFormComponent,
    HandballmanPlayerDetailComponent,
    HandballwomPlayerDetailComponent,
    FootballPlayerDetailComponent,
    IcehockeyPlayerDetailComponent,
    SortTableComponent,
    PdfPageComponent,
    PdfCardComponent,
    PdfEditPageComponent,
    PdfEditFormComponent,
    NoNeedRegPageComponent,
    RequirementInfoPageComponent,
    RequirementInfoComponentComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [PlayersPageComponent, ClubsPageComponent],
})
export class SportModule {}
