<div class="row">
    <div class="col-auto">
        <button type="button" class="btn btn-link menu-btn open-btn" 
            aria-label="Toggle menu"
            i18n-aria-label="Header's menu icon"
            (click)="repo.toggleMenuOpened()">
            <i class="bi bi-list"></i>
        </button>
        <button type="button" class="btn btn-link menu-btn expand-btn"  
            aria-label="Toggle menu"
            i18n-aria-label="Header's menu icon"
            (click)="repo.toggleMenuExpanded()">
            <i class="bi bi-list"></i>
        </button>
    </div>
    <div class="col pl-0">
        <app-logo></app-logo>
    </div>
    <div class="col-auto align-self-center">
        <app-user-card size="sm"></app-user-card>
    </div>
</div>