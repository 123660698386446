<div class="row mb-2">
  <div class="col">
    <h6 class="my-1" i18n="Basic info label">Basic Search</h6>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label transfer">Transfer</div>
    <div>{{ requirement?.transfer?.name }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label club">Club</div>
    <div>{{ requirement?.club?.name }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label contact">Contact</div>
    <div>
      {{
        requirement?.contact?.firstName + " " + requirement?.contact?.lastName
      }}
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label positions">Positions</div>
    <div class="d-flex flex-wrap">
      <div *ngFor="let position of requirement?.positionRequirements">
        <span class="pe-2">{{ position.name ?? "-" }}</span>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label playerLevels">Player Level</div>
    <div class="d-flex flex-wrap">
      <div *ngFor="let playerLevel of requirement?.playerLevelRequirements">
        <span class="pe-2">{{ playerLevel.name ?? "-" }}</span>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label contractStatuses">Contract Status</div>
    <div class="d-flex flex-wrap">
      <div
        *ngFor="let contractStatus of requirement?.contractStatusRequirements"
      >
        <span class="pe-2">{{ contractStatus.name ?? "-" }}</span>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="maxNetto">Max Netto</div>
    <div>{{ requirement?.maxNetto }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="maxBrutto">Max Brutto</div>
    <div>{{ requirement?.maxBrutto }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="maxTransferSum">Max Transfer Sum</div>
    <div>{{ requirement?.maxTransferSum ?? "-" }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label currency">Currency</div>
    <div>{{ requirement?.valuta?.name ?? "-" }}</div>
  </div>
  <div class="col-12 col-lg-8 mb-3">
    <div class="label" i18n-placeholder="description">Description</div>
    <div>{{ requirement?.description ?? "-" }}</div>
  </div>

  <div class="col-12 mt-2">
    <h6 class="my-1" i18n="Basic info label">Advanced Search</h6>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="minAge">Min Age</div>
    <div>{{ requirement?.minAge }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="maxAge">Max Age</div>
    <div>{{ requirement?.maxAge }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label contractType">Contract Type</div>
    <div>{{ requirement?.contractType ?? "-" }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label maritalStatus">Marital Status</div>
    <div>{{ requirement?.maritalStatus ?? "-" }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label nationality">Nationality</div>
    <div>{{ requirement?.country?.name ?? "-" }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label pinsPriority">PINS Priority</div>
    <div>{{ requirement?.pinsPriority ? requirement?.pinsPriority : "-" }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="minHeight">Min Height</div>
    <div>{{ requirement?.minHeight }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="maxHeight">Max Height</div>
    <div>{{ requirement?.maxHeight }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n-label="Label rightyLefty">Right Left</div>
    <div>{{ requirement?.rightyLefty ? requirement?.rightyLefty : "-" }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="minWeight">Min Weight</div>
    <div>{{ requirement?.minWeight }}</div>
  </div>
  <div class="col-12 col-md-6 col-lg-4 mb-3">
    <div class="label" i18n="maxWeight">Max Weight</div>
    <div>{{ requirement?.maxWeight }}</div>
  </div>
</div>
