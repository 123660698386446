import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Valuta, ValutasRepository } from 'src/app/state/valutas.repository';
import { ValutasService } from 'src/app/state/valutas.service';

@Component({
  selector: 'app-valuta-update-page',
  templateUrl: './valuta-update-page.component.html',
  styleUrls: ['./valuta-update-page.component.scss']
})
export class ValutaUpdatePageComponent implements OnInit {
  valuta$: Observable<Valuta> | null = null;
  id: string | undefined;
  submitErrors: string[] | null = null;
  tenantId: string | null = null;  

  constructor(
    private valutasService: ValutasService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public repo: ValutasRepository,
    private urlId: UrlIdResolverService,    
    private auth: AuthRepository
  ) { 
    
  }

    ngOnInit(): void {      
      this.valuta$ = this.urlId.resolve(
        this.activatedRoute.paramMap,
        id => this.valutasService.loadOne(id),
        id => this.repo.one(id),
        id => this.repo.statusOne(id),
        id => this.id = id
      );
      this.activatedRoute.paramMap.subscribe({ 
        next: (value) => {        
          this.tenantId = value.get('tenant?');          
        }
      });      
    }

    update(valuta: Partial<Valuta>) {
      this.submitErrors = null;
      let updateResult: Observable<Valuta> | undefined;
      
        if(this.tenantId) {
          valuta.tenantId = this.tenantId;       
        
      if(this.id) {
        updateResult = this.valutasService.update(this.id , valuta);
      }
      if (updateResult) {       
        updateResult.subscribe({          
          complete: () => {
            this.router.navigate(['/currencies/', this.tenantId]);
          },
          error: data => this.submitErrors = data
        });
      }
    }
  }
}
