import { HttpClient } from '@angular/common/http';
import { PaginationData } from '@ngneat/elf-pagination';
import { map, Observable, tap } from 'rxjs';
import { SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './base.repository';

export const DEFAULT_ENTITIES_PER_PAGE = 10;

export class BaseReadService<T extends { id: string }> {
  constructor(
    protected api: string,
    protected http: HttpClient,
    protected repo: BaseRepository<T>
  ) {}

  loadAllOptions(id: string): Observable<T[]> {
    return this.http.get<T[]>(`${this.api}/options/${id}`);
  }

  load(
    tenantId?: string,
    filterDeleted?: boolean
  ): Observable<PaginationData & { data: T[] }> {
    return this.http
      .get<PaginationData & { data: T[] }>(
        `${this.api}?tenantId=${tenantId}&filterDeleted=${filterDeleted}`
      )
      .pipe(
        tap((res) => this.repo.set(res.data)),
        this.repo.track()
      );
  }

  sortWithFilter(sortBy: SortState, tenantId?: string, filter?: string) {
    this.repo.setSort(sortBy);
    return this.reloadPageWithFilter(tenantId, undefined, filter);
  }

  reloadPageWithFilter(
    tenantId?: string,
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE,
    filter?: string
  ): Observable<PaginationData & { data: T[] }> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageWithFilter(1, data.perPage, tenantId, filter);
    }
    return this.loadPageWithFilter(1, defaultTake, tenantId, filter);
  }

  loadPageWithFilter(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    tenantId?: string,
    filter?: string
  ): Observable<PaginationData & { data: T[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `tenantId=${tenantId}`,
      `filter=${filter}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: T[] }>(
        `${this.api}/filter/?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track()
      );
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    tenantId?: string
  ): Observable<PaginationData & { data: T[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `tenantId=${tenantId}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: T[] }>(`${this.api}?${query.join('&')}`)
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  sort(sortBy: SortState, tenantId?: string) {
    this.repo.setSort(sortBy);
    return this.reloadPage(tenantId);
  }

  reloadPage(
    tenantId?: string,
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: T[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPage(data.currentPage, data.perPage, tenantId);
    }
    return this.loadPage(1, defaultTake, tenantId);
  }

  loadOne(id: string): Observable<T> {
    return this.http.get<T>(`${this.api}/${id}`).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id)
    );
  }

  export(): Observable<string> {
    return this.http
      .get<Blob>(`${this.api}/csv`, { responseType: 'blob' as any })
      .pipe(
        map((resp) => {
          const blob = new Blob([resp], { type: resp.type });
          return window.URL.createObjectURL(blob);
        })
      );
  }
}
