import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { AuthRepository } from 'src/app/state/auth.repository';
import { Club, ClubsRepository } from 'src/app/state/clubs.repository';
import { ClubsService } from 'src/app/state/clubs.service';
import {
  ContractStatus,
  ContractStatusesRepository,
} from 'src/app/state/contractstatuses.repository';
import { ContractStatusesService } from 'src/app/state/contractstatuses.service';
import {
  CountriesRepository,
  Country,
} from 'src/app/state/countries.repository';
import { CountriesService } from 'src/app/state/countries.service';
import {
  PlayerLevel,
  PlayerLevelsRepository,
} from 'src/app/state/playerlevels.repository';
import { PlayerLevelsService } from 'src/app/state/playerlevels.service';
import {
  Position,
  PositionsRepository,
} from 'src/app/state/positions.repository';
import { PositionsService } from 'src/app/state/positions.service';
import {
  RequirementsFilter,
  RequirementsRepository,
} from 'src/app/state/requirements.repository';
import { RequirementsService } from 'src/app/state/requirements.service';
import { TenantsRepository, Tenant } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import {
  Transfer,
  TransfersRepository,
} from 'src/app/state/transfers.repository';
import { TransfersService } from 'src/app/state/transfers.service';
import { UiRepository } from 'src/app/state/ui.repository';
@Component({
  selector: 'app-requirements-page',
  templateUrl: './requirements-page.component.html',
  styleUrls: ['./requirements-page.component.scss'],
})
export class RequirementsPageComponent implements OnInit {
  clubOptions: Club[] | null = null;
  transferOptions: Transfer[] | null = null;
  countryOptions: Country[] | null = null;
  positionOptions: Position[] | null = null;
  playerLevelOptions: PlayerLevel[] | null = null;
  contractStatusOptions: ContractStatus[] | null = null;
  reqFilter: RequirementsFilter | null = null;
  id: string | null = null;
  flagIdChanged: string | null = null;
  tenantIds: string[] | null = null;
  countOfTenants: number | undefined;
  tenants = new Array<Tenant>();
  tenantsOrdered = new Array<Tenant>();
  tenantIdsForFilter: string[] | null = null;
  activeTransfer: Transfer | null = null;
  isAllLoadedCount: number = 0;

  constructor(
    public ui: UiRepository,
    public service: RequirementsService,
    private router: Router,
    public repo: RequirementsRepository,
    private activatedRoute: ActivatedRoute,
    public auth: AuthRepository,
    public tenantRepo: TenantsRepository,
    private tenantService: TenantsService,
    private clubService: ClubsService,
    public clubRepo: ClubsRepository,
    private transferService: TransfersService,
    public transferRepo: TransfersRepository,
    private positionService: PositionsService,
    public positionRepo: PositionsRepository,
    private playerLevelService: PlayerLevelsService,
    public playerLevelsRepo: PlayerLevelsRepository,
    private contractStatusService: ContractStatusesService,
    public contractStatusRepo: ContractStatusesRepository,
    private countryService: CountriesService,
    public countryRepo: CountriesRepository
  ) {
    this.tenantService.getCountOfTenants().subscribe((x) => {
      this.countOfTenants = x;
      if (this.countOfTenants == 0) {
        this.router.navigate([`/profile`]);
      }
    });

    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        if (localStorage.getItem('tenantId') !== value.get('tenant?')) {
          this.id = value.get('tenant?');
          this.loadData(this.id);
        }
      },
    });
  }

  updateSort($event: SortState) {
    this.repo.setSort($event);
  }

  loadedCheck() {
    return this.isAllLoadedCount >= 6;
  }

  loadData(tenantId: string | null) {
    if (tenantId) {
      this.clubService.loadAllOptions(tenantId).subscribe((x) => {
        this.clubOptions = x;
        this.isAllLoadedCount + 1;
      });
      this.transferService.loadAllOptions(tenantId).subscribe((x) => {
        this.transferOptions = x;
        this.isAllLoadedCount += 1;
      });
      this.positionService.loadAllOptions(tenantId).subscribe((x) => {
        this.positionOptions = x;
        this.isAllLoadedCount += 1;
      });
      this.playerLevelService.loadAllOptions(tenantId).subscribe((x) => {
        this.playerLevelOptions = x;
        this.isAllLoadedCount += 1;
      });
      this.contractStatusService.loadAllOptions(tenantId).subscribe((x) => {
        this.contractStatusOptions = x;
        this.isAllLoadedCount += 1;
      });
      this.countryService.loadAllOptions(tenantId).subscribe((x) => {
        this.countryOptions = x;
        this.isAllLoadedCount += 1;
      });

      let clubs = localStorage.getItem(`${this.id}clubIds`);
      let transfer = localStorage.getItem(`${this.id}transferId`);
      let playerlevels = localStorage.getItem(`${this.id}playerLevelIds`);
      let positions = localStorage.getItem(`${this.id}positionIds`);
      let contract = localStorage.getItem(`${this.id}contractId`);
      let countries = localStorage.getItem(`${this.id}countryIds`);

      const TransferOptions = transfer ? transfer : null;
      const ClubOptions = clubs ? clubs?.split(',') : null;
      const PlayerLevelOptions = playerlevels ? playerlevels?.split(',') : null;
      const PositionOptions = positions ? positions?.split(',') : null;
      const ContractStatusOptions = contract ? contract?.split(',') : null;
      const CountryOptions = countries ? countries?.split(',') : null;

      const TenantId = tenantId;
      const Direction = this.repo.getSort().direction;
      const Sort = this.repo.getSort().parameter.property;
      const Page = 1;

      this.reqFilter = {
        transferId: TransferOptions,
        clubIds: ClubOptions,
        playerLevelIds: PlayerLevelOptions,
        positionIds: PositionOptions,
        countryIds: CountryOptions,
        contractStatusIds: ContractStatusOptions,
        tenantId: TenantId,
        sort: Sort,
        direction: Direction,
        page: Page,
        take: 10,
      };

      this.service.loadbyfilter(this.reqFilter).subscribe();
    }
  }

  loadDataOnDemand($event: Partial<RequirementsFilter>) {
    this.service.loadbyfilter($event).subscribe();
    if ($event) {
      localStorage.setItem(
        `${this.id}transferId`,
        $event?.transferId ? $event?.transferId.toString() : ''
      );
      localStorage.setItem(
        `${this.id}clubIds`,
        $event?.clubIds ? $event?.clubIds.toString() : ''
      );
      localStorage.setItem(
        `${this.id}playerLevelIds`,
        $event?.playerLevelIds ? $event?.playerLevelIds.toString() : ''
      );
      localStorage.setItem(
        `${this.id}positionIds`,
        $event?.positionIds ? $event?.positionIds.toString() : ''
      );
      localStorage.setItem(
        `${this.id}contractId`,
        $event?.contractStatusIds ? $event?.contractStatusIds.toString() : ''
      );
      localStorage.setItem(
        `${this.id}countryIds`,
        $event?.countryIds ? $event?.countryIds.toString() : ''
      );
    }
  }

  TenantRedirect() {
    this.tenantIds = this.auth.getTenantIds();
    if (this.tenantIds && this.tenantIds?.length > 0) {
      this.tenantIds.forEach((x) => {
        this.tenantService.loadOne(x).subscribe();
      });
      this.tenantRepo.tenants$.subscribe({
        next: (x) => {
          this.tenantsOrdered = x;
          this.tenants = this.filterMenu(this.tenantsOrdered);
          if (
            this.countOfTenants != undefined &&
            this.countOfTenants != 0 &&
            this.tenants.length >= this.countOfTenants
          ) {
            this.id = this.tenants[0].id.toString();
            if (
              this.id !== '00000000-0000-0000-0000-000000000000' &&
              this.flagIdChanged === '00000000-0000-0000-0000-000000000000'
            ) {
              this.router.navigate([`/requirements/${this.id}`]);
              this.ui.toggleMenuOpened(true, this.id);
            }
          }
        },
      });
    }
  }

  filterMenu(data: Tenant[] | null) {
    this.tenantIdsForFilter = this.auth.getTenantIds();
    if (data) {
      return data
        ?.filter((x) => this.tenantIdsForFilter?.includes(x.id))
        .sort((a, b) => (a.name > b.name ? 1 : -1));
    } else {
      return new Array<Tenant>();
    }
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('tenant?');
        this.flagIdChanged = this.id;
        if (value.get('tenant?') === '00000000-0000-0000-0000-000000000000') {
          this.TenantRedirect();
        }
      },
    });

    this.loadData(this.id);
  }
}
