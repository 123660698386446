import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { getStoredToken } from 'src/app/state/auth.repository';
import { AppComponent } from './app.component';
import { environment } from 'src/environments/environment';
import { NewsModule } from './modules/news/news.module';
import { SessionInterceptor } from './modules/core/interceptors/session.interceptor';
import { DateInterceptor } from './modules/core/interceptors/date.interceptor';
import { ImageInterceptor } from './modules/core/interceptors/image.interceptor';
import { BaseUrlInterceptor } from './modules/core/interceptors/base-url.interceptor';
import { CoreModule } from './modules/core/core.module';
import { CoreRoutingModule } from './routing/core-routing.module';
import { NewsRoutingModule } from './routing/news-routing.module';
import { RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './modules/core/pages/pagenotfound/pagenotfound.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { RetryInterceptor } from './modules/core/interceptors/retry.interceptor';
import { EnvState } from './modules/shared/helpers/env-state';
import { SportModule } from './modules/sport/sport.module';
import { SportRoutingModule } from './routing/sport-routing.module';


const optionalModules = [];
const optionalProviders = [];
if (environment.features.news) {
  optionalModules.push(NewsModule);
  optionalModules.push(NewsRoutingModule);
}
if (environment.features.sport) {
  optionalModules.push(SportModule);
  optionalModules.push(SportRoutingModule);
}
if (environment.features.offlineChanges) {
  optionalProviders.push({
    provide: HTTP_INTERCEPTORS,
    useClass: RetryInterceptor,
    multi: true
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 2 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:2000"
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => getStoredToken(),
        allowedDomains: [window.location.origin]
      }
    }),
    CoreModule,
    CoreRoutingModule,
    ...optionalModules,
    // 404 redirect must be the last import!
    RouterModule.forRoot([{ path: '**', component: PagenotfoundComponent }])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    {
      provide: EnvState,
      useValue: environment
    },
    ...optionalProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
