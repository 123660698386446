import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  constructor(
    private router: Router,
    private authRepo: AuthRepository
  ) { }
  
  /** CAN BE USED TO CREATE DIFFERENT HOME PAGES FOR DIFFERENT ROLES */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.url[0]?.path === 'requirements') {
      if(!this.authRepo.isInRole(UserRoles.TenantAdmin) && !this.authRepo.isInRole(UserRoles.User)) {
        this.router.navigate(['/profile']);
        return false;
      }
    }
    return true;
  }
  
}
