import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRepository, IdentityError } from 'src/app/state/auth.repository';
import { AuthService } from 'src/app/state/auth.service';

@Component({
  selector: 'app-office365auth',
  templateUrl: './office365auth.component.html',
  styleUrls: ['./office365auth.component.scss'],
})
export class Office365authComponent implements OnInit {
  submitErrors: IdentityError[] | null = null;
  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.loginMicrosoft365();
  }

  ngOnInit(): void {}

  loginMicrosoft365() {
    var code = this.route.snapshot.queryParams['code'];
    this.auth.loginOffice365Auth(code).subscribe({
      next: (x) => {
        let tenants = this.repo.getTenantIdsDirectly(x.token);
        if (tenants && tenants.length > 0 && tenants[0]) {
          this.router.navigate([`requirements/${tenants[0]}`]);
        } else {
          this.router.navigate(['profile']);
        }
      },
      error: (data) => {
        this.submitErrors = data;
        //this.router.navigate(['signin/:error']);
        this.clearCookiesAndCache();
        this.router.navigate(['signin']);
      },
    });
  }

  clearCookiesAndCache() {
    // Clear all cookies
    localStorage.clear();
    sessionStorage.clear();
    const cookies = document.cookie.split('; ');
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }

    // Clear all cache
    if ('caches' in window) {
      caches.keys().then((names) => {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }
  }
}
