import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthRepository } from 'src/app/state/auth.repository';
import { ContactsRepository, contactsSortOptions } from 'src/app/state/contacts.repository';
import { ContactsService } from 'src/app/state/contacts.service';
import { TenantsRepository } from 'src/app/state/tenants.repository';

@Component({
  selector: 'app-contacts-page',
  templateUrl: './contacts-page.component.html',
  styleUrls: ['./contacts-page.component.scss']
})
export class ContactsPageComponent implements OnInit {
  sortOptions = contactsSortOptions;
  exportFactory = () => this.service.export();
  id: string | null = null;
  filter: string | undefined;

  constructor(
    public service: ContactsService,
    public repo: ContactsRepository,
    private activatedRoute: ActivatedRoute,
    public tenantRepo: TenantsRepository
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({ 
      next: (value) => {
        this.id = value.get('tenant?');
        this.service.reloadPageWithFilter(this.id!).subscribe();
      }    
    });
  }

  searchSubmit($event: string) {
    this.filter = $event
    if ($event != null) {
      this.service.reloadPageWithFilter(`${this.id}`, undefined, $event).subscribe()
    }
  }
}
