import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvState } from 'src/app/modules/shared/helpers/env-state';
import { UrlIdResolverService } from 'src/app/modules/shared/services/url-id-resolver.service';
import { Club, ClubsRepository } from 'src/app/state/clubs.repository';
import { ClubsService } from 'src/app/state/clubs.service';
import { LeagueRepository } from 'src/app/state/leagues.repository';
import { LeaguesService } from 'src/app/state/leagues.service';
import { Team, TeamsRepository } from 'src/app/state/teams.repository';
import { TeamsService } from 'src/app/state/teams.service';

@Component({
  selector: 'app-club-page',
  templateUrl: './club-page.component.html',
  styleUrls: ['./club-page.component.scss']
})
export class ClubPageComponent implements OnInit {
  club$: Observable<Club> | null = null;
  club: Club | null = null;
  id?: string;
  teams: Team[] | null = null;
  submitErrors: string[] | null = null;
  tenantId: string | null = null;
  icon: string = 'bi-shield-shaded';
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';

  background: string | null = null;

  constructor(
    public repo: ClubsRepository,    
    private teamsService: TeamsService,
    public teamsRepo: TeamsRepository,
    private activatedRoute: ActivatedRoute,
    private service: ClubsService,
    private urlService: UrlIdResolverService,
    private router: Router,
    public env: EnvState
  ) { }

  ngOnInit(): void {
    this.club$ = this.urlService.resolve(
      this.activatedRoute.paramMap,
      id => this.service.loadOne(id),
      id => this.repo.one(id),
      id => this.repo.statusOne(id),
      id => this.id = id
    );
    this.activatedRoute.paramMap.subscribe({ 
      next: (value) => {        
        this.tenantId = value.get('tenant?');
        this.loadData(value.get('tenant?')!);
      }
    });
    this.club$.subscribe(x => this.club = x);
    
}

  loadData(id: string) {   
    let isNew = this.id === 'new' ? true : false;  
    this.service.load(id, true).subscribe();
    this.teamsService.load(id, isNew).subscribe({
      next: (value) => {        
        this.teams = value?.data;
      }
    });
      
  }  

  refreshPage() {
    window.location.reload();
  }

  updateClub(club: Partial<Club>) {
    this.submitErrors = null;
    if(this.id) {
      let id = this.id;
      this.service.updateClub(this.id, club).subscribe({
        complete: () => {
          this.service.loadOne(id).subscribe(x => this.club = x);
          this.router.navigate([`clubs/${this.tenantId}`]);
        },
        error: data => this.submitErrors = data
      });
    }
  }
}
