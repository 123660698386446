import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseReadService } from "./abstract/base-read.service";
import { Position, PositionsRepository } from "./positions.repository";


const API = '/api/positions';

@Injectable({ providedIn: 'root' })
export class PositionsService extends BaseReadService<Position> {

  constructor(
    http: HttpClient,
    repo: PositionsRepository
  ) {
    super(API, http, repo);
  }
}