<div class="row mt-1">
  <div class="col-12">
    <div class="card shadow-md mb-4">
      <ul class="list-group list-group-flush">
        <li class="list-group-item" *ngFor="let user of users">
          <div class="row align-items-center">
            <div class="col-12 col-md">
              <div class="row mb-3 mb-md-0">
                <div class="col-auto position-relative">
                  <app-entity-avatar [img]="user.imagePath" [alt]="user.name"
                    icon="bi-person" [roles]="user.roles | translateRoles"></app-entity-avatar>
                </div>
                <div class="col">
                  <p>
                    <span>{{user.name}} {{user.surname}}</span>
                    <br> 
                    <span *ngIf="user.tenantIds && user.tenantIds.length > 0 && tenants && tenants.length">                   
                      <small *ngFor="let item of user.tenantIds">
                        <span *ngIf="item && tenants && tenants.length > 0 && (tenants | findBy:'id':item )" 
                          class="me-2 badge bg-primary">
                          {{ tenants | findBy:'id':item | prop:'name' }}
                        </span>                      
                      </small>
                    </span>
                    <span class="text-muted me-2">{{user.email}}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-5">
              <div class="row">
                <div class="col d-flex align-items-center text-md-end">
                  <small class="text-muted mr-2 w-100" i18n="Added date label">Added {{user.createdAt | date:'longDate'}}</small>
                </div>
                <div class="col-auto" *ngIf="canEditUser(user.roles)">
                  <div class="vr h-100"></div>
                </div>
                <div class="col-auto pl-0 d-flex align-items-center" *ngIf="canEditUser(user.roles)">
                  <a class="btn btn-md btn-link w-100" [routerLink]="['/users', user.id, user.roles.toString()]">
                    <i class="bi bi-pencil me-1"></i> <ng-container i18n="@@edit">Edit</ng-container>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
