<div class="row mb-4">
  <div class="col-12 col-md mb-3 mb-md-0 pagination-wrapper">
    <app-pagination [page]="page"
      [total]="total"
      (pageChange)="updateValue('page', $event); emitOnDemand()"></app-pagination>
  </div>
  <div class="col-12 col-md-auto">
    <app-sort-dropdown [sortingOptions]="sortOptions"
      [sorting]="sort"
      (sortingChange)="updateSort('sort', 'direction', $event); emitOnDemand()"></app-sort-dropdown>
  </div>
</div>
<form *ngIf="filterForm" [formGroup]="filterForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data">  
    <div class="row mb-2">
      <div class="col">
        <div class="form-check form-switch">
          <label for="filterSearch" i18n="filterSearch" class="form-check-label">Filters Search</label>
          <input class="form-check-input" formControlName="isFilterSearch" type="checkbox" (change)="changeFilterSearch()">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="filterSearch">
      <div class="mt-3 col-12 col-md-6 col-lg-4">
        <app-datalist-select id="TransferSelect" [options]="TransferOptions"
                             [(activeValue)]="filterForm.value.transferId"
                             label="Transfer" i18n-label="Transfer"
                             (activeValueChange)="updateValue('transferId', $event)"></app-datalist-select>
      </div>
      <div class="mt-3 col-12 col-md-6 col-lg-4">
        <app-datalist-select id="ContractStatusOptionsSelect" [options]="ContractStatusOptions"
                             [multiple]="true"
                             [(activeValue)]="filterForm.value.contractStatusIds"
                             label="Contract Statuses" i18n-label="Contract Statuses"
                             (activeValueChange)="updateValue('contractStatusIds', $event)"></app-datalist-select>
      </div>

      <div class="mt-3 col-12 col-md-6 col-lg-4">
        <app-datalist-select id="PlayerLevelOptionsSelect" [options]="PlayerLevelOptions"
                             [multiple]="true"
                             [(activeValue)]="filterForm.value.playerLevelIds"
                             (activeValueChange)="updateValue('playerLevelIds', $event)"
                             label="Player Level" i18n-label="Player Level"></app-datalist-select>
      </div>

      <div class="mt-3 col-12 col-md-6 col-lg-4">
        <app-datalist-select [options]="ClubOptions"
                             [(activeValue)]="filterForm.value.clubIds"
                             [multiple]="true"
                             label="Club Options" i18n-label="ClubOptions"
                             (activeValueChange)="updateValue('clubIds', $event)"></app-datalist-select>
      </div>
      <div class="mt-3 col-12 col-md-6 col-lg-4">
        <app-datalist-select id="PositionOptionsSelect" [options]="PositionOptions"
                             [multiple]="true"
                             [(activeValue)]="filterForm.value.positionIds"
                             (activeValueChange)="updateValue('positionIds', $event)"
                             label="Position Options" i18n-label="PositionOptions"></app-datalist-select>
      </div>
      <div class="mt-3 col-12 col-md-6 col-lg-4">
        <app-datalist-select id="CountryOptionsSelect" [options]="CountryOptions"
                             [multiple]="true"
                             [(activeValue)]="filterForm.value.countryIds"
                             (activeValueChange)="updateValue('countryIds', $event)"
                             label="Country Options" i18n-label="CountryOptions"></app-datalist-select>
      </div>
      <div class=" mt-2 col-12  col-md-6 col-lg-6 form-group form-floating h-100 pb-3 d-grid">
        <button type="submit" class="btn btn-primary shadow-sm" i18n="@@show">Show</button>
      </div>
      <div class=" mt-2 col-12  col-md-6 col-lg-6 form-group form-floating h-100 pb-3 d-grid">
        <button  class="btn btn-primary shadow-sm" (click)="resetFilter()" i18n="@@clear">Clear Filter</button>
      </div>

    </div>

</form>

<div class="row">

  

</div>
