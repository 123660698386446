import { Component, OnInit } from '@angular/core';
import { Click, PublicPlayer, UnauthorisedRepository } from 'src/app/state/unauthorised.repository';
import { UnauthorisedService } from 'src/app/state/unauthorised.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvState } from '../../../shared/helpers/env-state';
import { VideoInfo } from '../../../../state/contacts.repository';

@Component({
  selector: 'app-icehockey-player-detail',
  templateUrl: './icehockey-player-detail.component.html',
  styleUrls: ['./icehockey-player-detail.component.scss']
})
export class IcehockeyPlayerDetailComponent implements OnInit {
  contact_id: string | null = null;
  player_id: string | null = null;
  player: PublicPlayer | null = null;
  EntityClick: Click | null = null;
  videourls: VideoInfo[] = []
  transferurls: string | undefined;
  tenant_id: string | null = null;

  constructor(
    public service: UnauthorisedService,
    private router: Router,
    public repo: UnauthorisedRepository,
    private activatedRoute: ActivatedRoute,
    public env: EnvState  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.contact_id = value.get('contact_id');
        this.player_id = value.get('player_id');
        var ClientId = `${this.contact_id}`;
        var PlayerID = `${this.player_id}`;

        this.service.new_click({ ClientId, PlayerID }).subscribe();


        this.service.loadPlayer(`${this.player_id}`).subscribe(x => { this.player = x;  this.player?.videoInfos.forEach(x => this.videourls.push(this.videofilter(x))); this.transferurls = this.transferfilter(this.player?.transferMarket); this.tenant_id = this.player?.tenantId })
      }
    });

  }

  checkPipesInStrings(attr: string | undefined | null) {
    if(attr) {
      return attr.split("||").join("");
    }
    return "-";
  }

  videofilter(router: VideoInfo) { 
    if (router.videoUrl.replace(/\s/g, '').slice(0, 4) != "http" && router.videoUrl.replace(/\s/g, '').slice(0, 2) != "//" && router.videoUrl.match(/^ *$/) == null) {
      router.videoUrl = "//" + router.videoUrl;
    }
    return router
  }

  transferfilter(router: string) {
    if (router != null && router.match(/^ *$/) == null) {
      if (router.slice(0, 4) != "http" && router.slice(0, 2) != "//") {
        router = "//" + router;
      }

    }
    return router

  }
  preview = () => this.service.preview(this.tenant_id!, this.player_id!)

}
