import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Contact } from './contacts.repository';

export const contactsSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'firstName' },
  { label: $localize `:Sort label Name:Height`, property: 'height' },
  { label: $localize `:Sort label Name:Weight`, property: 'weight' },
  { label: $localize `:Sort label Name:DateOfBirth`, property: 'dateOfBirth' }
];

@Injectable({ providedIn: 'root' })
export class PlayersRepository extends BaseRepository<Contact> {

  constructor() {
    super('contacts', contactsSortOptions);
  }
}