import { Injectable } from '@angular/core';
import { VideoInfo } from './contacts.repository';

export interface WhoClicked {
  name: string;
  club: string;
  clicks: number;
}
export interface OnWhomClicked {
  name: string;
  club: string;
  clicks: number;
}

export interface PublicPlayer {
  id: string;
  firstName: string;
  lastName: string;
  clubId: string;
  clubName: string;
  dateOfBirth: Date;
  agent1Guid: string;
  agent1: Agent;
  agent2Guid: string;
  agent2: Agent;
  nationality: string;
  playerContract: string;
  playerContractStartDate: Date;
  playerContractEndDate: Date;
  pictureUrl: string;
  playerCvLink: string;
  height: string;
  weight: string;
  description: string;
  positions: string[];
  videoInfos: VideoInfo[];
  transferMarket: string;
  rightyLefty: string;
  phoneNumber: string;
  email: string;
  tenantId: string;

  formerClub: string;
  internationalLevel: string;
  statistics: string;
  playerReferences: string;
  maritalStatus: string;
}

export interface Agent {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
}

export interface Click {
  ClientId: string;
  PlayerID: string;
}

@Injectable({ providedIn: 'root' })
export class UnauthorisedRepository {}
