import { Component, Input, OnInit } from '@angular/core';
import { Valuta } from 'src/app/state/valutas.repository';

@Component({
  selector: 'app-valutas-list',
  templateUrl: './valutas-list.component.html',
  styleUrls: ['./valutas-list.component.scss']
})
export class ValutasListComponent implements OnInit {
  @Input() entities: Valuta[] | null = null;
  @Input() tenantId: string | null= null;

  constructor() { }

  ngOnInit(): void {
  }

   round(x: number): number {
    x = Math.floor(x);
    return x;
  }
}
