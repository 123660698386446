import { Component, Input, OnInit } from '@angular/core';
import { SortOption, SortState } from 'src/app/modules/shared/pipes/sort.pipe';
import { AuthRepository, UserRoles } from 'src/app/state/auth.repository';
import { TenantsRepository } from 'src/app/state/tenants.repository';
import { TenantsService } from 'src/app/state/tenants.service';
import { userSortOptions, UsersRepository } from 'src/app/state/users.repository';
import { UsersService } from 'src/app/state/users.service';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss']
})
export class UsersListPageComponent implements OnInit {
  sortOptions = userSortOptions;
  exportFactory = () => this.service.export();
  //importFactory = (file: File) => this.service.import(file);
  nameFilter: string | null = null;
  filter: string | undefined;
  constructor(
    public repo: UsersRepository,
    public tenantRepo: TenantsRepository,
    public service: UsersService,
    private tenantService: TenantsService,
    public auth: AuthRepository) { }

  ngOnInit(): void {
    this.service.reloadPageWithFilter('').subscribe();
    if (this.auth.isInRole(UserRoles.Superadmin)) {
      this.tenantService.load().subscribe();
    }
  }

  searchSubmit($event: string) {
    this.filter = $event
    if ($event != null) {
      this.service.reloadPageWithFilter('', undefined, $event).subscribe()
    }
  }
}
