<form *ngIf="filterForm" [formGroup]="filterForm" (ngSubmit)="filterStringSubmit()" class="form-inline">
  <div class="form-group row ">
    <div class="col-md-6 col-sm-6 col-lg-6 col-4">
      <input formControlName="nameFilter" type="text" id="nameFilter" name="nameFilter" class="form-control" placeholder="Search" autocomplete="off" />
    </div>
    <div class="col-auto">
      <button type="submit" class="btn btn-md btn-primary" i18n="@@Search">Search</button>
    </div>
    <div class="col-auto">
      <button (click)="filterClear()" class="btn btn-md btn-primary" i18n="@@Search">Clear</button>
    </div>
  </div>
</form>
