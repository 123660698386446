<div class="d-flex flex-column align-items-center justify ">
  <div class="wd-50">
    <div class="row headcolor text-light ">
      
        <div class="  pt pb col-6">
          <div name="playerName">
            <h1 class="margin">{{player?.firstName ?? '-'}} {{player?.lastName ?? '-'}}</h1>
          </div>
          <div class=" margin">
            <h2 class="d-inline " name="playerPosition" *ngFor="let item of player?.positions; let i = index">
              {{item}} <span *ngIf=" i+1 < player?.positions!.length">/</span>
            </h2>
          </div>
         
        </div>
        <div name="logo" class="pt  col-6 pos-end">
          <div class="mrgr "><img class=" logoimg" src="/assets/img/white_logo1.png" alt="People in Sport"></div>
          
        </div>
        <br />
      
    </div>

    <div class="row ">
      <div class=" col-4  bg-lightgrey">
        <div name="playerPhoto" class="mrg align-items-center d-flex flex-column pt-2">
          <img class="playerimg align-items-center" *ngIf="player?.pictureUrl" src="{{env.apiUrl + '/' + player?.pictureUrl}}" alt="Player Image">
          <img class=" mt-2 iconsizing" *ngIf="!player?.pictureUrl" src="assets/img/user_default.svg">
        </div>
        <hr />
        <br />
        <div name="logo" class="mrg d-flex  pt-2">
          <img class=" slogo align-items-center" src="/assets/img/header_logo.png" alt="People in Sport">
        </div>
        <br />
        <br />
      </div>
      <div class="col-8 ">
        <div class="">
          <div class="fw-bolder pt-3">Facts</div>
          <br />
          <div class="row">
            <div class="col-4">Position:</div>
            <div class="col-8">
              <div *ngFor="let pos of player?.positions; let i = index" class="d-inline">
                {{pos}} <span *ngIf=" i+1 < player?.positions!.length">/</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">Foot:</div>
            <div class="col-8">{{player?.rightyLefty}}</div>
          </div>
          <div class="row">
            <div class="col-4">Born:</div>
            <div class="col-8">{{player?.dateOfBirth|date:'dd MM yyyy '}}</div>
          </div>
          <div class="row">
            <div class="col-4">Nationality:</div>
            <div class="col-8">{{ checkPipesInStrings(player?.nationality)}}</div>
          </div>

          <div class="row">
            <div class="col-4">Height:</div>
            <div class="col-8">{{player?.height}} cm</div>
          </div>
          <div class="row">
            <div class="col-4">Weight:</div>
            <div class="col-8">{{player?.weight}} kg</div>
          </div>
          <div class="row">
            <div class="col-4">Current Club:</div>
            <div class="col-8">{{player?.clubName}}</div>
          </div>

          <div class="row">
            <div class="col-4">Contract expires:</div>
            <div class="col-8">{{ player?.playerContractEndDate | date:'MMMM y' }}</div>
          </div>

          <div class="row">
            <div class="col-4">Link:</div>

            <a *ngIf="link" class="col-8 text-break" href="{{transferurls}}">{{transferurls}}</a>
          </div>
        </div>
        <hr />

        <div>
          <div class="fw-bolder">Contact</div>
          <br />
  
    <div>{{ checkPipesInStrings(player?.agent1?.name) }} {{ checkPipesInStrings(player?.agent1?.surname) }}</div>
          <div> Registered Players' Agent</div>

          <div class="row">
            <div class="col-4">Mobile: </div>
            <div class="col-8">{{player?.agent1?.phoneNumber}}</div>
          </div>
          <div class="row">
            <div class="col-4">Mail: </div>
            <div class="col-8">{{player?.agent1?.email}}</div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
