<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
    <div class="row mb-2">
        <div class="col">
            <h6 class="my-1" i18n="Basic info label">Basic Information</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group form-floating mb-3">
            <input formControlName="picture" type="file" class="form-control"
                    placeholder="Contact picture" i18n-placeholder="Contact picture placeholder @@picture"/>
            <label for="picture" i18n="picture">Contact picture</label>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group form-floating mb-3">
                <textarea rows="4" style="height:100%;" formControlName="description" type="text" class="form-control "
                       placeholder="Description" i18n-placeholder="Description placeholder @@description"></textarea>
                <label for="description" i18n="@@description">Description</label>
              </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <div class="form-group form-floating pb-3 d-grid mr-3">
                <button type="submit" class="btn btn-md-lg btn-primary shadow-sm" i18n="Save button @@save">Save</button>
          </div>
        </div>
    </div>
</form>
