import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { skipWhileCached } from '@ngneat/elf-requests/lib/requests-cache';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { CreatedAtMatch, CreatedAtMatchesRepository } from './dates.repository';
import { Match, MatchesRepository } from './matches.repository';

const API = '/api/matches';

@Injectable({ providedIn: 'root' })
export class MatchesService {
  constructor(
    private http: HttpClient,
    private repo: MatchesRepository,
    private dateRepo: CreatedAtMatchesRepository
  ) {}

  loadOne(id: string) {
    return this.http.get<Match>(`${API}/${id}`).pipe(
      tap((match) => this.repo.upsert(match)),
      this.repo.trackOne(id),
      this.repo.skipWhileCached(id)
    );
  }

  loadOneDirectly(matchId: string) {
    return this.http.get<Match>(`${API}/${matchId}/newMatch/distinct`).pipe(
      tap((match) => this.repo.upsert(match)),
      this.repo.trackOne(matchId)
    );
  }

  create(dto: Partial<Match>): Observable<Match> {
    return this.http.post<Match>(API, dto).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.track('create')
    );
  }

  loadDates(id?: string): Observable<CreatedAtMatch[]> {
    this.dateRepo.clear();
    return this.http
      .get<CreatedAtMatch[]>(`${API}/${id}/dates`)
      .pipe(tap((res) => this.dateRepo.set(res)));
  }

  update(id: string, model: Partial<Match>) {
    return this.http.patch<Match>(`${API}/${id}`, model).pipe(
      tap((match) => this.repo.upsert(match)),
      this.repo.trackOne(id)
    );
  }

  sendToAc(model: Partial<Match>, isForEmail?: boolean) {
    model.isForEmail = isForEmail;
    return this.http
      .put<Match>(`${API}`, model)
      .pipe(tap((match) => this.repo.upsert(match)));
  }
}
