import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { DatalistOption } from 'src/app/modules/shared/components/datalist-select/datalist-select.component';
import { Club } from 'src/app/state/clubs.repository';
import { Contact } from 'src/app/state/contacts.repository';
import { ContractStatus } from 'src/app/state/contractstatuses.repository';
import { Country } from 'src/app/state/countries.repository';
import { PlayerLevel } from 'src/app/state/playerlevels.repository';
import { Position } from 'src/app/state/positions.repository';
import { Requirement } from 'src/app/state/requirements.repository';
import { Transfer } from 'src/app/state/transfers.repository';
import { Valuta } from 'src/app/state/valutas.repository';

@Component({
  selector: 'app-requirement-info-component',
  templateUrl: './requirement-info-component.component.html',
  styleUrls: ['./requirement-info-component.component.scss'],
})
export class RequirementInfoComponentComponent implements OnInit {
  @Input() requirement: Requirement | null = null;

  ngOnInit(): void {}
}
