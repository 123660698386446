import { Injectable } from '@angular/core';
import { SortOption } from 'src/app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface ContractStatus {
    id: string;
    name: string;    
  }

export const contractStatusesSortOptions: SortOption[] = [
  { label: $localize `:Sort label Name:Name`, property: 'name' }  
];

@Injectable({ providedIn: 'root' })
export class ContractStatusesRepository extends BaseRepository<ContractStatus> {

  constructor() {
    super('contractStatuses', contractStatusesSortOptions);
  }

}