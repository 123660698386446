import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { switchMap, tap } from 'rxjs/operators';
import { BaseService } from "./abstract/base.service";
import { User, UsersRepository } from "./users.repository";

const API = '/api/users';
const MAILAPI = '/api/mailtester';
export type UserCreateUpdateDto = Partial<User> & { password?: string };

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService<User> {

  constructor(
    http: HttpClient,
    repo: UsersRepository
  ) {
    super(API, http, repo);
  }
  
  add(user: UserCreateUpdateDto) {
    const formData = new FormData();
    formData.append("file", user.image!);

    return this.http
      .post<User>(API, user)
      .pipe(
        tap((user) => this.repo.upsert(user)),
        switchMap((user) => this.http.post<User>(`${API}/${user.id}/file`, formData)),
        this.tapReloadPage(),
        this.repo.track('add')
      );
  }
  
  update(id: string, model: Partial<User>) {
    return this.http
      .patch<User>(`${API}/${id}`, model)
      .pipe(
        tap((user) => this.repo.upsert(user)),
        switchMap((user) => {
          if (model.image) {
            const formData = new FormData();
            formData.append("file", model.image);
            return this.http.post<User>(`${API}/${id}/file`, formData);
          }
          return of(user);
        }),
        this.repo.trackOne(id)
    );
  }
  search(name: string | undefined) {
    return this.http.get(API + `/search/${name}`)

  }

  testMail() {
    return this.http.get<any>(MAILAPI);
  }
}
