<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" enctype="multipart/form-data">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <div [appValidateState]="form.get('value')" class="form-group form-floating mb-3">
          <input formControlName="value" type="text" class="form-control"
                 placeholder="Value" i18n-placeholder="@@value"/>
          <label for="value" i18n="@@value">Value</label>
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div class="form-group form-floating pb-3 d-grid">
          <button type="submit" class="btn btn-primary shadow-sm" i18n="Save button @@save">Save</button>
        </div>
      </div>
    </div>
  </form>