import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findBy'
})
export class FindByPipe implements PipeTransform {

  transform(array: any[], field: string, value: any): any | null {
    return array.find(x => x[field] === value);
  }

}
