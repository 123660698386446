import { HttpClient } from '@angular/common/http';
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { BaseReadService } from './base-read.service';
import { BaseRepository } from './base.repository';

export class BaseService<T extends { id: string }> extends BaseReadService<T> {
  constructor(api: string, http: HttpClient, repo: BaseRepository<T>) {
    super(api, http, repo);
  }

  protected tapReloadPage<R>(tenantId?: string) {
    return switchMap((sourceResponse: R) =>
      combineLatest([of(sourceResponse), this.reloadPage(tenantId)]).pipe(
        map(([res, _]) => res)
      )
    );
  }

  add(model: Partial<T>, tenantId: string): Observable<T> {
    return this.http.post<T>(this.api, model).pipe(
      tap((res) => this.repo.upsert(res)),
      this.tapReloadPage(tenantId),
      this.repo.track('add')
    );
  }

  update(id: string, model: Partial<T>): Observable<T> {
    return this.http.patch<T>(`${this.api}/${id}`, model).pipe(
      tap((res) => this.repo.upsert(res)),
      this.repo.trackOne(id)
    );
  }

  delete(id: string, tenantId: string): Observable<void> {
    return this.http.delete<void>(`${this.api}/${id}`).pipe(
      tap(() => this.repo.remove(id)),
      this.tapReloadPage(tenantId),
      this.repo.trackOne(id)
    );
  }

  import(file: File, tenantId: string): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http
      .post<any>(`${this.api}/csv`, formData)
      .pipe(this.tapReloadPage(tenantId));
  }
}
