<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Requirement Info">
  Requirement Info
</h2>
<div class="row mb-4">
  <app-spinner *ngIf="!(requirement$ | async)"></app-spinner>
  <app-requirement-info-component
    *ngIf="requirement$ | async"
    [requirement]="requirement$ | async"
  ></app-requirement-info-component>
</div>
