<div class="row px-3">
  <div class="col-md-6 col-xl-4 card shadow-sm mb-3 px-3 position-relative">
    <div class="row">
      <div class="col">
        <figure class="avatar avatar-150 rounded-circle shadow overflow-hidden mb-2  mt-3">
          <img *ngIf="contact?.pictureUrl" src="{{env.apiUrl + '/' + contact?.pictureUrl}}">
          <i *ngIf="!contact?.pictureUrl" [ngClass]="['bi', icon]"></i>
        </figure>
        
      </div>
      <div class="col">
        <div class="mb-4 mt-4">
          <h4>{{ contact?.firstName + ' ' + contact?.lastName }}</h4>
        </div>
        <div class="mb-3 mt-2">
          <h6>Email: {{ contact?.email }}</h6>
        </div>
        <div class="mb-3 mt-2">
          <h6>Phone: {{ contact?.phoneNumber }}</h6>
        </div>
        <div *ngIf="contactMatch?.contact?.mandate" class="circle-min block">
          <h6 class="mt-2">M</h6>
        </div>
      </div>      
    </div>
    <div class="row">
      <div class="col">
        <div class="mb-3 mt-4">
          <h6>Club: {{ contact?.club?.name }}</h6>
        </div>
        <div class="d-flex align-items-baseline justify-content-between">
          <h6>Minimum salary netto: {{ contact?.minSalaryNetto + ' ' + (contact?.valuta?.name === null ? "" : contact?.valuta?.name)}}</h6>
           <a style="cursor: pointer;" class="btn-sm btn-link btn-light me-2 text-right" (click)="goTo('/players/' + contact?.tenantId + '/' + contact?.id)">          
                    <span>Read more</span>
            </a>
        </div>
      </div>
    </div>
    <div class="circle block mx-auto">
      <h4 *ngIf="contactMatch">{{getAvg(contactMatch)}}</h4>
    </div>
    <hr>
    <div class="row px-2 mb-3">
      <div class="col-6 col-md-3 col-lg-3 text-center"><strong>Player plan</strong><br><span>{{contactMatch?.playerPlanMark}}</span></div>
      <div class="col-6 col-md-3 col-lg-3 text-center"><strong>Finance</strong><br><span>{{contactMatch?.financeMark}}</span></div>
      <div class="col-6 col-md-3 col-lg-3 text-center"><strong>Contract</strong><br><span>{{contactMatch?.contractMark}}</span></div>
      <div class="col-6 col-md-3 col-lg-3 text-center"><strong>Personal</strong><br><span>{{contactMatch?.personalMark}}</span></div>
    </div>
    <div class="position-absolute top-0 end-0">
      <div class="form-check form-switch text-end mt-1">
        <input [disabled]="contactMatch?.sentToACMatch"
               [checked]="contactMatch?.sentToACMatch"
               (change)="contactEmitter.emit(contact?.id)"
               class="form-check-input"
               type="checkbox">
      </div>
    </div>
  </div>  
</div>

