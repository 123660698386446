import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PDFTemplate, PDFTemplateRepository } from 'src/app/state/PDFTemplate.repository';
import { PDFTemplateService } from 'src/app/state/PDFTemplate.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, HostListener, Output } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-pdf-edit-form',
  templateUrl: './pdf-edit-form.component.html',
  styleUrls: ['./pdf-edit-form.component.scss']
})
export class PdfEditFormComponent implements OnInit {
  form: FormBuilder | any;
  id: string | null = null
  file: File | null = null;
  tenantid: string | null = null;
  @Input() editedPDF: Partial<PDFTemplate> | null = null;



  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {


    if (this.form) {
      if ((event && event.item(0)) instanceof File) {
        this.file = event && event.item(0);
        this.form.value.file = this.file;

      }

    }
  }


  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private formBuilder: FormBuilder,
    public service: PDFTemplateService,
    public repository: PDFTemplateRepository,
    private activatedRoute: ActivatedRoute,
  ) {  }

  ngOnInit(): void {

    
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id?');
        this.service.loadOne(`${this.id}`).subscribe(x => {
          this.tenantid = x.tenantId;
          this.form = this.formBuilder.group({
            name: [x.name, Validators.required],
            file: [this.editedPDF?.file, Validators.required],
          });


        })

      }
    }); 
   
    

  }


  onSubmit() {
    if (this.form) {
      if (this.file != null) {
        if (this.file?.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
          this.service.saveFile(`${this.id}`, this.file)?.subscribe({
            complete: () => {
              this.router.navigate([`/pdftemplate/${this.tenantid}`]);
            }
          });
        } 
      }
    }
    if (this.form.value.name != null && this.form.value.name != "")
      this.service.edit(`${this.id}`, this.form.value).subscribe({
        complete: () => {
          this.router.navigate([`/pdftemplate/${this.tenantid}`]);
        }        
      });
    

      
}

}
